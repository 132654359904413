import { Box, styled } from '@mui/material'
import AutoImg from './AutoImg'
import Empty from './Empty'
import Loading from './Loading'
import { fixedFormat, shortAddress } from 'utils'
import { formatUTCTime } from 'utils/date'
import { useTranslation } from 'react-i18next'

const Wraper = styled(Box)`
  list-style: none;

  li {
    background-color: #191919;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 10px;
  }
`

const Text1 = styled(Box)`
  font-weight: bold;
  font-size: 0.875rem;
`
const Cancel = styled(Box)`
  font-weight: 500;
  background-color: #303030;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 2;
  padding: 7px 15px;
  font-size: 12px;
  line-height: 16px;
`
const Tip = styled(Box)`
  display: flex;
  line-height: 1;
  margin-top: 8px;
  align-items: center;

  i {
    background-image: linear-gradient(178deg, #cdfe00 0%, #fde600 100%);
    font-weight: 500;
    font-size: 12px;
    color: #000;
    width: 12px;
    height: 12px;
    font-style: normal;
    text-align: center;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 4px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
`

interface Props {
  list: OrderDetail[]
  type: string // 'purchase' | 'sell' | 'history'
  loading: boolean
  current: number
  hasMore: boolean
  onCancel?: (id: number) => void
}

export default function Card({ list, type, loading, current, hasMore, onCancel }: Props) {
  const { t } = useTranslation()
  return (
    <Box position="relative" minHeight={list.length ? '360px' : '360px'}>
      <Wraper component="ul">
        {list.length ? (
          list.map((item: any) => {
            return (
              <Box component="li" key={item.id}>
                <Box display="flex">
                  <Box width="100px" mr="14px" flexShrink="0">
                    <AutoImg src={item.imageUrl} alt={item.nftName} height="100%" style={{ borderRadius: '8px' }} />
                  </Box>
                  <Box flex="1">
                    <Box marginBottom={'8px'} display={'flex'} alignItems="center" justifyContent={'space-between'}>
                      ID {item.id}
                      {item.blockTime > 0 ? <Box>{formatUTCTime(item.blockTime * 1000)} UTC</Box> : <Box>--</Box>}
                    </Box>
                    <Box marginBottom={'8px'} display={'flex'} alignItems="center" justifyContent={'space-between'}>
                      <Text1 component="h3" sx={{ width: '140px' }}>
                        {item.nftName}
                      </Text1>
                      {type === 'purchase' && (
                        <Text1 component="p" fontSize={'16px'} fontWeight="900">
                          X 1
                        </Text1>
                      )}

                      {item.state === 1 && (
                        <Cancel
                          onClick={() => {
                            onCancel && onCancel(item)
                          }}
                        >
                          {t('CANCEl')}
                        </Cancel>
                      )}
                      {item.state !== 1 && <Cancel>{item.state === 0 ? t('Revoked') : t('Finished')}</Cancel>}
                    </Box>
                    <Box marginBottom={'8px'} display={'flex'} alignItems="center" justifyContent={'space-between'}>
                      <Text1
                        textAlign="end"
                        sx={{
                          wordBreak: 'break-word',
                          maxWidth: '100px',
                          fontSize: '16px',
                          lineHeight: '22px',
                        }}
                      >
                        {fixedFormat(item.price)} {item.priceToken?.toUpperCase()}
                      </Text1>
                      {type !== 'sale' && (
                        <Text1 component="p" fontSize="12px" lineHeight="16px" fontWeight="500">
                          {item.hash ? (
                            <a
                              href={`https://bscscan.com/tx/${item.hash}`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ wordBreak: 'break-word', color: 'rgba(255, 255, 255, 0.6)' }}
                            >
                              {shortAddress(item.hash)}
                            </a>
                          ) : (
                            '--'
                          )}
                        </Text1>
                      )}
                    </Box>
                  </Box>
                </Box>
                {item.state === 3 && (
                  <Tip>
                    <i>!</i>
                    <p>{t('NFT was not found. Please cancel this order to avoid your loss.')}</p>
                  </Tip>
                )}
              </Box>
            )
          })
        ) : (
          <>{!loading && <Empty />} </>
        )}

        {loading && !list.length && type === 'purchase' && <Loading />}
      </Wraper>
    </Box>
  )
}
