import { isPrd } from 'utils'

export const exchangeAddress = isPrd
  ? '0x481b8ed52a43e6f0b71c4a08496b3d5322f182e7'
  : '0x2b9293bbbff672edb84f69e50e8467f0707f86eb'
export const transferProxy = isPrd
  ? '0x2aaed3adbc811977c05068ae6371239ae188ff21'
  : '0x8ea1c6bf9fda27809d2222424f42eeec708072a6'
export const paymentTokenAddress = isPrd
  ? '0x55d398326f99059ff775485246999027b3197955'
  : '0x2C9463D6c8721e4b25E2d8EA339704376be538Df' //'0xaB844e9C80425AA696f800703Fb77FE8a0441d36'
export const exchangeStorageAddress = isPrd
  ? '0x00de29998377658d847c3e4640df66ad88cc4298'
  : '0xb1e37d14b983ffece4bb48740d1c8a5c4f737090'
export const targetAddress = isPrd
  ? '0x6d203abfb86350c7a92ae5c81c3973dd355aee22'
  : '0xc9d6d5f6f5ea3ed04e2491b3f2f4dc484620a053'
export const proxyRegistryAddress = isPrd
  ? '0x988da590752ae5090d855afbf9898624d8526983'
  : '0x7369fe8cc1f87bf7d8731faceb3b2e50ea52ba74'
export const openBoxAddress = isPrd
  ? '0x8B9e04cE50700eC18D5C158f0D5ef75EEACfb3f9'
  : '0xF1739d1b036be6811F5CD560C803513AFE2b49aE'
