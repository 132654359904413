import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(relativeTime)

/**
 * 格式化为utc展示时间
 *
 * @param {number|string} time 时间戳
 * @param {string} format 格式化方式
 * @return {string} utc时间展示
 */
export function formatUTCTime(time: number, format = 'YYYY-MM-DD HH:mm:ss') {
  return `${dayjs.utc(Number(time)).format(format)}`
}

/**
 *
 * 格式化为本地展示时间
 *
 * @param {number|string} time 时间戳
 * @param {string} format 格式化方式
 * @return {string} 本地时间展示
 */
export function formatLocalTime(time: number, format = 'YYYY-MM-DD HH:mm:ss') {
  return `${dayjs.utc(Number(time)).local().format(format)}`
}

/**
 *
 * 前补0
 *
 * @param {number|string} num 需要补0的数字
 * @param {number} n 总位数，不够在前面补0
 * @return {string} 补0后的字符串
 */
export const prefixZero = (num: string | number, n: number) => (Array(n).join('0') + num).slice(-n)

export const fromNow = (date: string) => dayjs(date).fromNow()

export default dayjs
