import { useTranslation } from 'react-i18next'
import { useFreeMint } from 'hooks/useGames'
import styled from '@emotion/styled'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import LoadingIcon from 'components/LoadingIcon'
import MintModal from 'pages/FreeMint/components/MintModal'
import MintCountdown from 'pages/FreeMint/components/MintCountdown'
import { formatUTCTime } from 'utils/date'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import more from 'assets/images/games/more.png'
import { Link } from 'react-router-dom'

import { useState } from 'react'

import { useWeb3React } from '@web3-react/core'
import { mintApprove } from 'constants/mint'
import { useWalletModalToggle } from 'state/application/hooks'
import theme from 'theme'
import { useLang } from 'state/language/hooks'
dayjs.extend(utc)

const MintBox = styled(Box)`
  width: 100%;
  .mint {
    display: flex;
    margin-bottom: 40px;
    background: #191919;
    border-radius: 16px;
    padding: 20px;
    .picName {
      width: 300px;
      height: 300px;
      margin-right: 20px;
      border-radius: 16px;
    }
    .rightMint {
      flex: 1;
      .name {
        font-weight: 800;
        font-size: 30px;
        line-height: 41px;
        margin-bottom: 2px;
      }
      .desc {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #c0c0c0;
        margin-bottom: 74px;
        min-height: 66px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .mintPA {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 10px;
        .name {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          color: #c0c0c0;
        }
        .value {
          font-weight: 800;
          font-size: 24px;
          line-height: 33px;
          display: flex;
          align-items: center;
          img {
            width: 21px;
            height: 21px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .mintH5 {
    /* display: flex; */
    margin-bottom: 20px;
    background: #191919;
    border-radius: 16px;
    padding: 20px 10px;
    .hander {
      display: flex;
      align-items: center;
      /* justify-content: space-between; */
      .leftImg {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        border-radius: 16px;
      }
      .mintRight {
        .name {
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
        }
        .desc {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #c0c0c0;
          min-height: 64px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
      }
    }
    .mintPA {
      display: flex;
      /* align-items: flex-end; */
      flex-direction: column;
      /* justify-content: space-between; */
      margin-bottom: 15px;
      margin-top: 10px;
      .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #c0c0c0;
      }
      .value {
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }
  .upcoming {
    width: 400px;
    height: 44px;
    background: rgba(42, 100, 251, 0.2);
    border-radius: 10px;
    font-weight: 800;
    font-size: 24px;
    line-height: 44px;
    text-align: center;
    margin-top: 16px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .Participated {
    background: #363636;
  }
`
const More = styled(Link)`
  background: #191919 url(${more}) 62px center/16px no-repeat;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #cecece;
  height: 30px;
  border-radius: 19px;
  padding: 0px 39px 0 20px;
  &:hover {
    background-color: #2a64fb;
    box-shadow: 0px 0px 20px #2a64fb;
    color: #ffffff;
  }
`
interface ModalAttr {
  state: number
  desc: string
  open: boolean
}
export default function BasicTable() {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [modalAttr, setModalAttr] = useState<ModalAttr>({ state: 1, desc: t('Mint Success！'), open: false })
  const lang = useLang()

  const [loadingMint, setLoadingMint] = useState<Boolean>(false)
  const toggleWalletModal = useWalletModalToggle()

  const { account, library } = useWeb3React()
  const { list: mintList } = useFreeMint(1, 1)
  const { endlist } = useFreeMint(1, 1, 1)
  const approves = async (item: FreeMint) => {
    try {
      if (loadingMint) return
      setLoadingMint(true)
      const info = await mintApprove(item, library, account)

      if (info) {
        if (info === 1) {
          setModalAttr({
            state: 2,
            desc: t('Sorry, your wallet address is not on the whitelist.'),
            open: true,
          })
        } else {
          // window.location.reload()
          setModalAttr({ state: 1, desc: t('Mint Success！'), open: true })
        }
      }
      setLoadingMint(false)
    } catch (error) {
      setLoadingMint(false)
      console.log(error)
    }
  }
  const clonse = () => {
    setModalAttr((res) => {
      return { ...res, open: false }
    })
    if (modalAttr.state === 1) {
      window.location.href = `/${lang}/record`
    }
  }
  const onComplete = () => {
    window.location.reload()
  }
  return !isMobile ? (
    <MintBox>
      <Box
        sx={{
          fontWeight: 900,
          fontSize: '32px',
          lineHeight: '44px',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          margin: isMobile ? '30px 0 18px' : '80px 0 36px',
        }}
      >
        <Box sx={{ width: 'calc(50% + 50px)', textAlign: 'right' }}>
          {mintList?.record && mintList?.record.length > 0 ? t('Mints') : t('End Mint')}
        </Box>
        <More to={`/${lang}/mint/`}>{t('More')}</More>
      </Box>
      {mintList?.record && endlist?.record ? (
        <>
          {mintList?.record.length > 0 &&
            mintList?.record.map((item) => {
              return (
                <Box className="mint" key={item.id}>
                  <img className="picName" src={item.img} alt="" />
                  <Box className="rightMint">
                    <Typography className="name">{item.name}</Typography>
                    <Typography className="desc">{item.desc}</Typography>
                    <Box className="mintPA">
                      <Box>
                        <Typography className="name">{t('Cost')}</Typography>
                        <Typography className="value">
                          <img src={item.priceToken.icon} alt="" /> {item.price}{' '}
                          {item.priceToken.token.toLocaleUpperCase()}
                        </Typography>
                      </Box>
                      <Box>
                        {dayjs(Date.now()).add(1, 'month').valueOf() < item.endTime && Date.now() > item.startTime ? (
                          <Box sx={{ fontWeight: 800, fontSize: '24px', lineHeight: '33px' }}>{t('In Progress')}</Box>
                        ) : (
                          <>
                            <Typography className="name">
                              {Date.now() < item.startTime ? t('Coming Soon') : t('Time Remaining')}
                            </Typography>
                            <Typography className="value">
                              <MintCountdown
                                data={{ id: item.id, startTime: item.startTime, endTime: item.endTime, state: 0 }}
                                page="index"
                                onComplete={onComplete}
                              />
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                    {!account && (
                      <Button
                        variant="contained"
                        sx={{ width: '220px' }}
                        onClick={() => {
                          toggleWalletModal()
                        }}
                      >
                        {loadingMint && <LoadingIcon />}
                        {t('CONNECT WALLET')}
                      </Button>
                    )}
                    {Date.now() > item.startTime &&
                      Date.now() < item.endTime &&
                      !item.mint &&
                      item.remainCount &&
                      item.remainCount > 0 &&
                      account && (
                        <Button
                          variant="contained"
                          sx={{ width: '400px' }}
                          disabled={item.mint}
                          onClick={() => {
                            approves(item)
                          }}
                        >
                          {loadingMint && <LoadingIcon />}
                          {t('Mint')}
                        </Button>
                      )}
                    {item.mint && <Box className="upcoming Participated">{t('Participated')}</Box>}
                    {item.remainCount === 0 && !item.mint && (
                      <Box className="upcoming Participated">{t('Sold Out')}</Box>
                    )}
                    {Date.now() < item.startTime && <Box className="upcoming">{t('Upcoming')}</Box>}
                  </Box>
                </Box>
              )
            })}
          {endlist?.record.length > 0 &&
            mintList?.record &&
            mintList?.record.length === 0 &&
            endlist?.record.map((item) => {
              return (
                <Box className="mint" key={item.id}>
                  <img className="picName" src={item.img} alt="" />
                  <Box className="rightMint">
                    <Typography className="name">{item.name}</Typography>
                    <Typography className="desc">{item.desc}</Typography>
                    <Box className="mintPA">
                      <Box>
                        <Typography className="name">{t('Cost')}</Typography>
                        <Typography className="value">
                          <img src={item.priceToken.icon} alt="" /> {item.price}{' '}
                          {item.priceToken.token.toLocaleUpperCase()}
                        </Typography>
                      </Box>
                      <Box>
                        <Box sx={{ fontWeight: 800, fontSize: '24px', lineHeight: '33px' }}>
                          {formatUTCTime(item.endTime, 'MM/DD/YYYY HH:mm:ss')}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="upcoming Participated">{t('End')}</Box>
                  </Box>
                </Box>
              )
            })}
        </>
      ) : (
        <Box sx={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingIcon width={'4rem'} height={'4rem'} />
        </Box>
      )}
      <MintModal {...modalAttr} clonse={clonse}></MintModal>
    </MintBox>
  ) : (
    <MintBox>
      <Box
        sx={{
          fontWeight: 900,
          fontSize: '32px',
          lineHeight: '44px',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          margin: isMobile ? '30px 0 18px' : '80px 0 36px',
        }}
      >
        <Box sx={{ width: 'calc(50% + 50px)', textAlign: 'right' }}>
          {mintList?.record && mintList?.record.length > 0 ? t('Mints') : t('End Mint')}
        </Box>
        <More to={`/${lang}/mint/`}>{t('More')}</More>
      </Box>
      {mintList?.record && endlist?.record ? (
        <>
          {mintList?.record.length > 0 &&
            mintList?.record.map((item) => {
              return (
                <Box className="mintH5" key={item.id}>
                  <Box className="hander">
                    <img className="leftImg" src={item.img} alt="" />
                    <Box className="mintRight">
                      <Typography className="name">{item.name}</Typography>
                      <Typography className="desc">{item.desc}</Typography>
                    </Box>
                  </Box>
                  <Box className="mintPA">
                    <Box>
                      {dayjs(Date.now()).add(1, 'month').valueOf() < item.endTime && Date.now() > item.startTime ? (
                        <Box sx={{ fontWeight: 800, fontSize: '24px', lineHeight: '33px' }}>{t('In Progress')}</Box>
                      ) : (
                        <>
                          <Typography className="name">
                            {Date.now() < item.startTime ? t('Coming Soon') : t('Time Remaining')}
                          </Typography>
                          <Typography className="value">
                            <MintCountdown
                              data={{ id: item.id, startTime: item.startTime, endTime: item.endTime, state: 0 }}
                              page="index"
                              onComplete={onComplete}
                            />
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box sx={{ marginTop: '15px' }}>
                      <Typography className="name">{t('Cost')}</Typography>
                      <Typography className="value">
                        <img src={item.priceToken.icon} alt="" /> {item.price}{' '}
                        {item.priceToken.token.toLocaleUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  {!account && (
                    <Button
                      variant="contained"
                      sx={{ width: '100%' }}
                      onClick={() => {
                        toggleWalletModal()
                      }}
                    >
                      {loadingMint && <LoadingIcon />}
                      {t('CONNECT WALLET')}
                    </Button>
                  )}
                  {Date.now() > item.startTime &&
                    Date.now() < item.endTime &&
                    !item.mint &&
                    item.remainCount &&
                    item.remainCount > 0 &&
                    account && (
                      <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        disabled={item.mint}
                        onClick={() => {
                          approves(item)
                        }}
                      >
                        {loadingMint && <LoadingIcon />}
                        {t('Mint')}
                      </Button>
                    )}
                  {item.mint && <Box className="upcoming Participated">{t('Participated')}</Box>}
                  {item.remainCount === 0 && !item.mint && <Box className="upcoming Participated">{t('Sold Out')}</Box>}
                  {Date.now() < item.startTime && <Box className="upcoming">{t('Upcoming')}</Box>}
                </Box>
              )
            })}
          {endlist?.record.length > 0 &&
            mintList?.record &&
            mintList?.record.length === 0 &&
            endlist?.record.map((item) => {
              return (
                <Box className="mintH5" key={item.id}>
                  <Box className="hander">
                    <img className="leftImg" src={item.img} alt="" />
                    <Box className="mintRight">
                      <Typography className="name">{item.name}</Typography>
                      <Typography className="desc">{item.desc}</Typography>
                    </Box>
                  </Box>
                  <Box className="mintPA">
                    <Box sx={{ marginTop: '15px' }}>
                      <Typography className="name">{t('Cost')}</Typography>
                      <Typography className="value">
                        <img src={item.priceToken.icon} alt="" /> {item.price}{' '}
                        {item.priceToken.token.toLocaleUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="upcoming Participated">{t('End')}</Box>
                </Box>
              )
            })}
        </>
      ) : (
        <Box sx={{ height: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingIcon width={'4rem'} height={'4rem'} />
        </Box>
      )}

      <MintModal {...modalAttr} clonse={clonse}></MintModal>
    </MintBox>
  )
}
