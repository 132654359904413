/**
 * @file
 * ino 首页和详情页banner
 */
import { styled, Box } from '@mui/material'
import theme from 'theme'
import bannerImg from 'assets/images/ino/banner-5-17.jpg'
import bannerImgDetails from 'assets/images/ino/banner-detial.jpg'

import InoCountdown from './InoCountdown'
import { useTranslation } from 'react-i18next'

const Wraper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  > img {
    width: 100%;
    max-height: 360px;
    min-height: 360px;
    object-fit: cover;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 39px;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    ${theme.breakpoints.down('md')} {
      font-size: 24px;
      line-height: 24px;
    }
  }

  h2 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 900;
    ${theme.breakpoints.down('md')} {
      font-size: 16px;
      line-height: 19px;
    }
  }

  ${theme.breakpoints.down('md')} {
    > img {
      border-radius: 16px;
      min-height: 150px;
    }

    &.is-detail > img {
      min-height: 200px;
    }

    h1 {
      margin-bottom: 16px;
    }
  }
`
interface Props {
  page: 'index' | 'detail' // 当前页面
  isM?: boolean
  data?: {
    id: number
    inoTitle: string
    startTime: number
    endTime: number
    state: number
    imageDetailUrl: string
  }
  onComplete?: () => void
}

export default function Banner(props: Props) {
  const { t } = useTranslation()
  let imgDetail: string | undefined
  if (props.isM) {
    const index = props.data?.imageDetailUrl.lastIndexOf('.')
    imgDetail = `${props.data?.imageDetailUrl.slice(0, index)}-h5${props.data?.imageDetailUrl.slice(index)}`
  } else {
    imgDetail = props.data?.imageDetailUrl || bannerImgDetails
  }

  return (
    <Wraper className={`is-${props.page}`}>
      {props.page === 'index' ? <img src={bannerImg} alt="ino" /> : <img src={imgDetail} alt="ino" />}
      {props.page === 'index' ? (
        <Box position="absolute">
          <h1>{t('POPFUN GAME LAB')}</h1>
          <h2>{t('LAUNCH GAMES WITH US.')}</h2>
        </Box>
      ) : (
        <Box position="absolute">
          <h1 style={{ marginBottom: props.isM ? '25px' : '50px' }}>{props.data?.inoTitle}</h1>
          <InoCountdown page={props.page} data={props.data} onComplete={props.onComplete} />
        </Box>
      )}
    </Wraper>
  )
}
