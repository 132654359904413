import { useEffect } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { styled } from '@mui/material/styles'
import { Button, Box, useMediaQuery } from '@mui/material'
import { Activity } from 'react-feather'
import { Link } from 'react-router-dom'
// import { useWalletModalToggle } from 'state/application/hooks'
import WalletModal from '../WalletModal'
import { NetworkContextName } from 'constants/misc'
import { shortenAddress } from 'utils'
import { useLang } from 'state/language/hooks'

import { SUPPORTED_WALLETS } from 'constants/wallet'
import { isMobile } from 'utils/userAgent'

import AddressIcon from 'assets/images/svg/address-icon.svg'
import { NoEthereumProviderError, UserRejectedRequestError } from '@web3-react/injected-connector'
import { useWalletModalToggle } from 'state/application/hooks'
import { useTranslation } from 'react-i18next'
import theme from 'theme'

const Web3StatusError = styled(Button)`
  background-color: #ff1744;
  color: #111;
`
const Web3StatusConnect = styled(Button)`
  padding: 6px 14px !important;
  height: 34px;
  margin-left: 9px;
  min-width: 144px;
  min-height: 34px;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  background-color: #2a64fb;

  &:hover {
    background-color: #2a64fb;
    opacity: 0.8;
  }
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`
const Avatar = styled(Box)`
  background-color: rgba(42, 100, 251, 0.3);
  border: 1px solid #2a64fb;
  box-sizing: border-box;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  padding: 8px 14px;
  ${theme.breakpoints.down('md')} {
    padding: 5.5px 14px;
  }
  text-transform: lowercase;
  font-weight: 600;

  img {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    margin-left: 0;
  }
`

const RecordLink = styled(Link)`
  width: 32px;
  height: 32px;
  color: #fff;
  margin-right: 9px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #2a64fb;
  }
`

function Web3StatusInner() {
  const lang = useLang()
  const { t } = useTranslation()
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'))

  const { account, error, activate, library } = useWeb3React()
  console.log(library)
  const toggleWalletModal = useWalletModalToggle()

  const connectWallet = async () => {
    // if (isMobile) {
    if (isMobile) {
      const connector = SUPPORTED_WALLETS['METAMASK'].connector
      connector &&
        activate(connector, undefined, true)
          .then(async () => {
            const walletAddress = await connector.getAccount()
            console.log(walletAddress)
            // logMonitoringEvent({ walletAddress })
          })
          .catch(async (error) => {
            console.log(error, '-----------')
            if (error instanceof UnsupportedChainIdError) {
              // await tabConnect()
            }
            if (error instanceof NoEthereumProviderError) {
              console.log('不是钱包环境')
            }
            if (error instanceof UserRejectedRequestError) {
              console.log('用户拒绝链接钱包')
            } else {
              console.log(true, '-----')
            }
          })
    } else {
      toggleWalletModal()
    }
  }

  // 歪招解决 在Dapp里 window.ethereum注入慢导致拿不到钱包信息
  useEffect(() => {
    if (isMobile && !account && !window.ethereum?.selectedAddress) {
      setTimeout(() => {
        connectWallet()
      }, 100)
    }
  }, [account]) // eslint-disable-line

  if (account) {
    window.localStorage.setItem('account', account)
    return (
      <Box display="flex" alignItems="center">
        {matches && (
          <RecordLink to={`/${lang}/record/`}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M26.6666 11V24C26.6666 28 24.2799 29.3333 21.3333 29.3333H10.6666C7.71992 29.3333 5.33325 28 5.33325 24V11C5.33325 6.66663 7.71992 5.66663 10.6666 5.66663C10.6666 6.49329 10.9999 7.23995 11.5465 7.78662C12.0932 8.33329 12.8399 8.66663 13.6666 8.66663H18.3333C19.9866 8.66663 21.3333 7.31996 21.3333 5.66663C24.2799 5.66663 26.6666 6.66663 26.6666 11Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21.3334 5.66663C21.3334 7.31996 19.9867 8.66663 18.3334 8.66663H13.6667C12.8401 8.66663 12.0934 8.33329 11.5467 7.78662C11 7.23995 10.6667 6.49329 10.6667 5.66663C10.6667 4.01329 12.0134 2.66663 13.6667 2.66663H18.3334C19.1601 2.66663 19.9068 2.99996 20.4535 3.54663C21.0001 4.0933 21.3334 4.83996 21.3334 5.66663Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.6667 17.3334H16.0001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.6667 22.6666H21.3334"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </RecordLink>
        )}

        <Avatar>
          <img src={AddressIcon} alt="" />
          {shortenAddress(account)}
        </Avatar>
      </Box>
    )
  } else if (error) {
    window.localStorage.removeItem('account')
    return (
      <Web3StatusError color="error" onClick={toggleWalletModal}>
        <NetworkIcon />
        <p>{error instanceof UnsupportedChainIdError ? <b>Wrong Network</b> : <b>Error</b>}</p>
      </Web3StatusError>
    )
    // return (
    //   <Web3StatusConnect variant="contained" size="medium" id="connect-wallet" onClick={connectWallet}>
    //     {t('Connect Wallet')}
    //   </Web3StatusConnect>
    // )
  } else {
    window.localStorage.removeItem('account')

    return (
      <Web3StatusConnect variant="contained" size="medium" id="connect-wallet" onClick={connectWallet}>
        {t('Connect Wallet')}
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  return (
    <>
      <Web3StatusInner />
      {(contextNetwork.active || active) && <WalletModal />}
    </>
  )
}
