import { Web3Provider } from '@ethersproject/providers'
// import { BigNumber } from '@ethersproject/bignumber'

import INO_ABI from 'abis/INO.json'
import { AccountType } from './type'

import { getContract } from 'utils'

export async function subscribe(
  provider: Web3Provider,
  contractAddress: string | null | undefined,
  amount: string,
  account: AccountType
) {
  const contract = account && contractAddress && getContract(contractAddress, INO_ABI, provider, account)
  return contract && contract.subscribe(amount)
}

export async function getUserInfo(
  provider: Web3Provider,
  contractAddress: string | null | undefined,
  account: AccountType
) {
  const contract = account && contractAddress && getContract(contractAddress, INO_ABI, provider, account)
  return contract && contract.getUserInfo(account)
}
