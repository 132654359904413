import { Box, Typography, useMediaQuery, Popover } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'
import Arrow from 'assets/images/modal/arrow.png'
import { useEffect, useState } from 'react'
import { tockenList } from 'hooks/useNFT'
import { paymentTokenAddress as PTA } from 'constants/nft'

const Wrapper = styled(Box)(({ fullWidth, width }: { fullWidth?: Boolean; width?: string }) => ({
  background: 'transparent',
  width: fullWidth ? '100%' : width,
  height: '64px',
  padding: '11px 16px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  border: '1px solid #FAE700',
  [theme.breakpoints.down('md')]: {
    height: '50px',
  },
  '> img': {
    borderRadius: '8px',
    marginRight: '12px',
  },
  '> input': {
    background: 'transparent',
    border: 'none',
    fontWeight: 500,
    color: theme.palette.common.white,
    flex: 1,
    height: '100%',
    fontSize: '1.5rem',
    outline: 'none',
    textAlign: 'right',
    width: 'inherit',
    paddingRight: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.166667rem',
    },
  },
}))
const PopoverBpx = styled(Popover)`
  /* width: 400px; */
  .MuiPaper-root {
    background: rgba(25, 25, 25, 0.8);
    .MuiTypography-root {
      font-weight: 900;
      font-size: 28px;
      line-height: 38px;
      color: #ffffff;
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        color: #fae700;
      }
      img {
        margin-right: 20px;
      }
      ${theme.breakpoints.down('md')} {
        width: 280px;
      }
    }
  }
`
interface token {
  precision: number
  icon: string
  token: string
  id: number
  address: string
}
export default function CustomizeInput({
  icon,
  name,
  value,
  fullWidth,
  width,
  readonly,
  change,
  select,
}: {
  icon: string | undefined
  name: string | undefined
  value: string | undefined
  fullWidth?: boolean
  width?: string
  readonly?: boolean
  change?: (e: any) => void
  select: (ads: string, name: string, icon: string) => void
}) {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const DomPrice = document.querySelector('#wrapp')
  const [open, setOpen] = useState(false)
  const [tockens, setTockens] = useState<[token]>([
    {
      precision: 18,
      icon: 'https://ino-image.s3.ap-southeast-1.amazonaws.com/popfun1/tokens/usdt.svg',
      token: 'usdt',
      id: 1,
      address: PTA,
    },
  ])
  useEffect(() => {
    tockenList().then((res) => {
      setTockens(res)
    })
  }, [])
  const handleClick = (ev: React.MouseEvent<HTMLDivElement>) => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Wrapper fullWidth={fullWidth} width={width} id="wrapp">
      <img width={matches ? '24px' : '40px'} height={matches ? '24px' : '40px'} src={icon} alt="" />
      <img
        width="20"
        height="20px"
        src={Arrow}
        alt=""
        style={{ transform: open ? 'rotateZ(90deg)' : 'rotateZ(0)' }}
        onClick={(ev) => {
          handleClick(ev)
        }}
      />
      <PopoverBpx
        id={'open'}
        open={open}
        anchorEl={DomPrice}
        onClose={handleClose}
        sx={{ width: '500px' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {tockens.map((item) => {
          return (
            <Typography
              key={item.id}
              onClick={(ev) => {
                select(item.address, item.token, item.icon)
                handleClose()
              }}
              sx={{ width: '370px' }}
            >
              <img alt="" width={'28px'} height={'28px'} src={item.icon} />
              {item.token.toLocaleUpperCase()}
            </Typography>
          )
        })}
      </PopoverBpx>
      <input
        aria-describedby="open"
        id="price"
        readOnly={readonly ? readonly : false}
        value={value}
        type="text"
        onChange={change}
      />
      <Typography fontSize={matches ? '1.1666667' : '1.5rem'} fontWeight={'500'} color={colors.fontColor}>
        {name}
      </Typography>
    </Wrapper>
  )
}
