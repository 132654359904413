import { Box, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'
import { useTranslation } from 'react-i18next'

import TwitterLogo from 'assets/images/svg/twitter.svg'
import TelegramLogo from 'assets/images/svg/telegram.svg'
import DiscordLogo from 'assets/images/svg/discord.svg'
import LanguageLogo from 'assets/images/svg/language.svg'

const Card = styled(Box)`
  text-align: center;
  ${theme.breakpoints.down('md')} {
    background-color: ${theme.palette.common.white};
  }
  > img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
    ${theme.breakpoints.down('md')} {
      border-radius: 8px 8px 0 0;
    }
  }
  > span {
    line-height: 1;
  }
  > div {
    margin: 10px 0 7px;
    > a {
      margin-right: 10px;
    }
  }
`

interface NextIgoCardsProps {
  img: string
  title: string
  name: string
  tr: string
  contact: {
    twitter: string
    telegram: string
    discord: string
    vector: string
  }
  time: string
}

export default function NextIgoCards(props: NextIgoCardsProps) {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { t } = useTranslation()
  return (
    <Card>
      <img src={props.img} alt="" />
      <Typography fontSize={matches ? '1rem' : '0.875rem'} color={colors.fontColor2} variant="overline">
        {props.title}
      </Typography>
      <Typography fontSize={matches ? '1.33333rem' : '1.75rem'} lineHeight={1.5} variant="h4">
        {props.name}
      </Typography>
      <Typography
        justifyContent={'center'}
        fontSize={matches ? '1.16666667rem' : '1.25rem'}
        color={colors.fontColor2}
        display={'flex'}
        alignItems={'center'}
        variant="body2"
        fontWeight={500}
      >
        {t('Total Raise')}: &nbsp;
        <Typography fontSize={'1.25rem'} fontWeight={600}>
          {props.tr}
        </Typography>
      </Typography>
      {!matches && (
        <Box justifyContent={'center'} display={'flex'} alignItems={'center'}>
          <a href="http://">
            <img src={TwitterLogo} alt="" />
          </a>
          <a href="http://">
            <img src={TelegramLogo} alt="" />
          </a>
          <a href="http://">
            <img src={LanguageLogo} alt="" />
          </a>
          <a href="http://">
            <img src={DiscordLogo} alt="" />
          </a>
        </Box>
      )}
      <Typography
        color={colors.fontColor2}
        fontWeight={500}
        variant="body2"
        fontSize={matches ? '1.16666667rem' : '1.25rem'}
      >
        {t('Time')}: {props.time}
      </Typography>
    </Card>
  )
}
