import { Container, styled, Box, useMediaQuery } from '@mui/material'
import theme from 'theme'
import { isPrd } from 'utils'

import InoBanner from './components/Banner'
import InoList from './components/InoList'
// import Vconsole from 'vconsole'
console.log('isPrd' + isPrd)
// if (!isPrd) {
//   new Vconsole()
// }

const Layout = styled(Box)`
  min-height: calc(100vh - 305px);

  ${theme.breakpoints.down('md')} {
    min-height: calc(100vh - 80px);
    padding: 15px;
  }
`

export default function Ino() {
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  return (
    <Layout>
      <InoBanner page="index" />
      <Container
        maxWidth="lg"
        sx={{
          paddingLeft: '0',
          paddingRight: '0',
          position: 'relative',
          minHeight: 'calc(100vh - 400px)',
        }}
      >
        <InoList isMobile={isM} type={1} />
        <InoList isMobile={isM} type={0} />
        <InoList isMobile={isM} type={2} />
      </Container>
    </Layout>
  )
}
