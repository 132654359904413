import { Container } from '@mui/material'
import Upcomings from './Upcomings'
import Next from './Next'
import EndIgos from './EndIgos'
import { Placeholder } from 'styleds/index'

export default function Home() {
  return (
    <Container maxWidth="lg">
      <Upcomings />
      <Next />
      <Placeholder />
      <EndIgos />
    </Container>
  )
}
