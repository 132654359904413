import { useState, useEffect } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import theme from 'theme'
import AutoImg from './AutoImg'
import Tag from './Tag'
import { formatUTCTime } from 'utils/date'
import { api } from '../api'
import Empty from './Empty'
import Loading from './Loading'
import ButtonMore from './ButtonMore'
import Message from 'components/Message'
import { useLang } from 'state/language/hooks'

const CardStyle = styled(Link)`
  background: #191919;
  border-radius: 16px;
  padding: 20px;
  display: flex;

  & + & {
    margin-top: 20px;
  }

  ${theme.breakpoints.down('md')} {
    display: block;
  }
`
interface CardProps {
  data: InoDetail
  isM: boolean
}

const Card = ({ data, isM }: CardProps) => {
  const lang = useLang()
  return (
    <CardStyle to={`/${lang}/ino/${data.inoId}`}>
      {isM && (
        <Typography component="h3" fontWeight="500" fontSize="1.125rem" mb="20px">
          {data.inoTitle}
        </Typography>
      )}
      <Box width={isM ? '100%' : '211px'} mr="30px" flexShrink="0" position="relative">
        <AutoImg src={data.inoImageUrl} alt={data.inoTitle} style={{ borderRadius: '16px' }} height="71.1%" />
        {isM && (
          <Tag
            sx={{
              position: 'absolute',
              right: '15px',
              top: '15px',
            }}
          >
            MYSTERY BOX
          </Tag>
        )}
      </Box>
      <Box>
        {!isM && (
          <Typography component="h3" fontWeight="500" fontSize="1.125rem">
            {data.inoTitle}
          </Typography>
        )}
        <Box display="flex" alignItems="center" mt="16px">
          <Typography component="h4" fontWeight="bold" fontSize="1.5rem" lineHeight="1" mr="20px">
            {data.productName}
          </Typography>
          {!isM && <Tag>MYSTERY BOX</Tag>}
        </Box>
        <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '6px',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            padding: '4px 18px',
            display: 'inline-block',
            marginTop: '16px',
          }}
        >
          X {data.total}
        </Box>
        <Box
          sx={{
            marginTop: '20px',
            color: 'rgba(255, 255, 255, 0.8)',
            fontSize: '1rem',
            lineHeight: '1',
            fontWeight: 'bold',
          }}
        >
          {' '}
          TIME {formatUTCTime(data.txTime, 'YYYY-MM-DD HH:mm')} UTC
        </Box>
      </Box>
    </CardStyle>
  )
}

interface Props {
  isM: boolean
  account: string | undefined | null
}

const pageSize = 11 // 请求每次请求条数
const defaultData = {
  current: 0,
  totalPage: 1,
  record: [],
}

export default function Ino({ isM, account }: Props) {
  const [data, setData] = useState<InoData>(defaultData)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [message, setMessage] = useState<{ show: boolean; text: string }>({ show: false, text: '' })

  const getList = (): void => {
    if (!account) return

    setLoading(true)

    const params: Params = {
      userAddress: account,
      pageSize,
    }

    if (data.current) params.page = data.current + 1

    api.getInoList(params).then((res: any): void => {
      setLoading(false)

      if (res.success) {
        if (res.data.record) {
          setHasMore(res.data.record.length >= pageSize)
          res.data.record = [...data.record, ...res.data.record.slice(0, pageSize - 1)]
          setData(res.data)
        }
      } else {
        setMessage({ show: true, text: res.message })
      }
    })
  }

  useEffect(() => {
    setData(defaultData)
    setHasMore(false)
    setLoading(false)

    if (account) {
      getList()
    }
  }, [account]) // eslint-disable-line

  return (
    <Box minHeight="360px" position="relative">
      {data.record.length ? (
        data.record.map((item: any) => <Card data={item} key={item.id} isM={isM} />)
      ) : (
        <>{!loading && <Empty />}</>
      )}

      {loading && !data.record.length && <Loading />}

      {hasMore && (
        <ButtonMore
          loading={loading}
          sx={{ padding: '6px 60px', margin: '40px auto 0', display: 'block' }}
          onClick={getList}
        />
      )}

      <Message
        show={message.show}
        message={message.text}
        onClose={() => {
          setMessage({ show: false, text: '' })
        }}
      />
    </Box>
  )
}
