import { Box, Container, Typography, useMediaQuery, styled, Button } from '@mui/material'
import Carousel from './components/Carousel'
import Trending from './components/Trending'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'
import SlgImg from 'assets/images/home/slg.jpg'
import TapImg from 'assets/images/home/game2.jpg'
import Contract from 'assets/images/home/contract-us.jpg'
import more from 'assets/images/games/more.png'
import { Link } from 'react-router-dom'
import { useGamesList } from 'hooks/useGames'
import FreeMint from './components/FreeMint'

import theme from 'theme'
import Games from 'components/games'

const Footer = styled(Box)`
  width: 100%;
  height: 400px;
  background: url(${Contract}) no-repeat center/100% 100%;
  margin: 88px 0 150px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 48px;
  ${theme.breakpoints.down('md')} {
    width: auto;
    padding-left: 10px;
    background: url(${Contract}) no-repeat right;
    margin: 20px 0 20px;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  p {
    width: 650px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`
const More = styled(Link)`
  background: #191919 url(${more}) 62px center/16px no-repeat;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #cecece;
  height: 30px;
  border-radius: 19px;
  padding: 0px 39px 0 20px;
  &:hover {
    background-color: #2a64fb;
    box-shadow: 0px 0px 20px #2a64fb;
    color: #ffffff;
  }
`

interface List {
  link: string
  img: string
  title: string
  subTitle: string
  raise: string
  time: string
}

export default function Home() {
  const { t } = useTranslation()
  const lang = useLang()
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  const { list: gameList } = useGamesList(1, 3)

  const list: List[] = [
    {
      link: `/${lang}/mint`,
      img: 'https://ino-image.s3.ap-southeast-1.amazonaws.com/popfun/prod/nft/leapcard/Trending.jpg',
      title: 'Land of Conquest NFT MINT Event',
      subTitle: 'This NFT set is for LOC Closed Beta Test participants only with limited offer.',
      raise: '100K',
      time: 'March',
    },
    {
      link: `/${lang}/igo`,
      img: SlgImg,
      title: 'SLG Token Offering',
      subTitle: 'LOC GENESIS HONORS METAL NFT Holders Only',
      raise: '100K',
      time: 'March',
    },
    {
      link: `/${lang}/igo/2/`,
      img: TapImg,
      title: 'TAP FANTASY METAVERSE',
      subTitle: 'TAP ＆ NFTs Available',
      raise: '200K',
      time: 'February',
    },
  ]
  return (
    <Container maxWidth="lg">
      <Carousel />
      <FreeMint></FreeMint>
      <Box
        sx={{
          fontWeight: 900,
          fontSize: '32px',
          lineHeight: '44px',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          margin: isM ? '30px 0 18px' : '80px 0 36px',
        }}
      >
        <Box sx={{ width: 'calc(50% + 50px)', textAlign: 'right' }}> {t('Games')}</Box>
        <More to={`/${lang}/games/`}>{t('More')}</More>
      </Box>
      {gameList && <Games list={gameList} source={isM ? 'home' : ''} />}
      <Box sx={{ marginTop: isM ? '20px' : '60px' }}>
        <a href={`/${lang}/mint`} style={{ display: 'block' }}>
          <img
            width={'100%'}
            style={{ borderRadius: '8px' }}
            src={'https://ino-image.s3.ap-southeast-1.amazonaws.com/popfun/prod/nft/leapcard/MiddleBanner.jpg'}
            alt=""
          />
        </a>
      </Box>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '32px',
          lineHeight: '44px',
          textAlign: 'center',
          margin: isM ? '30px 0 18px' : '80px 0 36px',
        }}
      >
        {t('Trending')}
      </Typography>
      <Trending list={list} />
      <Footer>
        <Typography sx={{ fontSize: '30px', fontWeight: 900, marginBottom: '25px', lineHeight: '45px' }}>
          {t('Join us to build the future of GameFi together.')}
        </Typography>
        <Typography sx={{ fontSize: '16px', marginBottom: '25px', color: '#f9fbff', lineHeight: '28px' }}>
          {t(
            "PopFun is now officially inviting people to push the GameFi industry forward together. Whether you're a developer, an investor, a marketer, or a gamer, contact us now for business inquiries, questions, or to chat!"
          )}
        </Typography>
        <Button
          sx={{ width: '150px' }}
          href="https://docs.google.com/forms/d/1rzYf5RjFoWR8Z9Yc75igdCAFoQJssVxL_k-XvXyd44E/edit"
          variant="contained"
          target={'_blank'}
        >
          {t('Contact Us')}
        </Button>
      </Footer>
    </Container>
  )
}
