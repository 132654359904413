import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { useWalletModalToggle } from 'state/application/hooks'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'
import { useGamesNftDetail } from 'hooks/useGames'

import {
  Container,
  styled,
  Box,
  Typography,
  Grid,
  useMediaQuery,
  Alert,
  AlertTitle,
  Stack,
  Snackbar,
} from '@mui/material'
import theme from 'theme'
import { shortAddress } from 'utils'
import { signEip712OpenBox } from 'utils/eip712'

import { useBuyNFTModalToggle } from 'state/application/hooks'
import { useInoBonusList } from 'hooks/useIno'
import { useNFTDetail, openBox } from 'hooks/useNFT'
import RevokeNft from 'components/Modal/RevokeNft'
import BASereumPic from 'assets/images/svg/bsc.svg'
import Spinner from 'components/Spinner'
import LoadingIcon from 'components/LoadingIcon'

import { getContract } from 'utils'
import { approve } from './hooks'
import * as abis from 'abis/NFTAbis'

import { BuleButton, YellowButton, BuleLineButton } from 'styleds'

import Modals from './Modals'

const Wrapper = styled(Container)`
  margin-top: 20px;
  ${theme.breakpoints.down('md')} {
    padding-bottom: 100px;
    padding-left: 1.33rem;
    padding-right: 1.33rem;
  }
`
const Content = styled(Box)`
  display: flex;
  ${theme.breakpoints.down('md')} {
    display: block;
  }
`
const YellowLine = styled(Box)`
  background: linear-gradient(178.29deg, #cdfe00 -12%, #fde600 98.23%);
  backdrop-filter: blur(27.1828px);
  border-radius: 10px;
  display: inline-block;
  width: 280px;
  height: 48px;
  color: #000000;
  font-weight: 800;
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  margin-left: 10px;
  ${theme.breakpoints.down('md')} {
    width: 45%;
    margin-left: 0;
  }
`

const NftLeft = styled(Box)`
  margin-right: 100px;
  ${theme.breakpoints.down('md')} {
    margin-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 25px;
  }
  > img {
    width: 400px;
    height: 400px;
    margin-bottom: 5px;
    border-radius: 12px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
      height: inherit;
    }
  }
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    ${theme.breakpoints.down('md')} {
      margin-top: 0;
    }
    p {
      font-size: 0.875rem;
      font-weight: 800;
    }
    p:first-of-type {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  a:hover {
    color: #2a64fb;
  }
`
const NftRight = styled(Box)`
  flex: 1;
  ${theme.breakpoints.down('md')} {
    margin-top: 5px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .mDetialBttn {
    width: 100%;
    display: flex;
    > div {
      width: 45%;
    }
    button {
      width: 45%;
    }
    ${theme.breakpoints.down('md')} {
      display: flex;
      justify-content: space-around;
      width: 100%;
      button {
        width: 45%;
      }
      .selfButton {
        width: 45%;
      }
    }
  }
  .current {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`
const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  > div {
    > p {
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: bold;
      ${theme.breakpoints.down('md')} {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        margin-top: 20px;
      }
    }
    > div {
      display: inline-flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
      }
      p {
        margin-left: 10px;
        color: rgba(255, 255, 255, 0.6);
        /* font-size: 14px; */
        &:first-of-type {
          font-weight: 500;
          ${theme.breakpoints.down('md')} {
            font-weight: 800;
            font-size: 14px;
            line-height: 19px;
            color: #ffffff;
          }
        }
      }
    }
    ${theme.breakpoints.down('md')} {
      &:last-child {
        /* display: none; */
      }
    }
  }
`

const Price = styled(Box)`
  display: flex;
  align-items: center;
  margin: 20px 0;
  > p {
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
    > p {
      line-height: 16px;
      margin-left: 5px;
    }
  }
`

const Cards = styled(Box)`
  display: flex;
  background: #191919;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  padding: 20px;
  ${theme.breakpoints.down('md')} {
    padding: 16px;
  }
  > img {
    margin-right: 20px;
    border-radius: 14px;
    width: 120px;
    height: 120px;
    ${theme.breakpoints.down('md')} {
      width: 88px;
      height: 88px;
      margin-right: 16px;
    }
  }
  > div {
    > p {
      &:nth-of-type(1) {
        ${theme.breakpoints.down('md')} {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }
      &:nth-of-type(2) {
        ${theme.breakpoints.down('md')} {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .rarity {
      font-size: 24px;
      font-weight: 800;
      color: #26c48b;
      ${theme.breakpoints.down('md')} {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }
`
interface Attr {
  traitType: string
  trait_type: string
  value: string
}

export default function NftDetails() {
  const lang = useLang()
  const { t } = useTranslation()
  const toggleWalletModal = useWalletModalToggle()
  const { account, library } = useWeb3React()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const toggleBuyNftModal = useBuyNFTModalToggle()
  const [modalType, setModalType] = useState<string>()
  const { id } = useParams()
  const { detail, reFetch } = useNFTDetail(id)
  const bonusList = useInoBonusList(detail?.infoId)
  const { list } = useGamesNftDetail(id)

  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [openAlertMsg, setOpenAlertMsg] = useState<string>('')
  const [openAlertType, setOpenAlertType] = useState<'success' | 'info' | 'warning' | 'error'>()
  const [showRevoke, setShowRevoke] = useState(false)

  const [openBoxLoading, setOpenBoxLoading] = useState<boolean>(false)

  const attrList: Attr[] = detail && JSON.parse(detail?.attributes || '')

  const openModal = (type: string) => {
    setModalType(type)
  }

  const open = async () => {
    if (detail && account) {
      setOpenBoxLoading(true)
      const signer = await library.getSigner()
      try {
        const sign = await signEip712OpenBox(
          signer,
          {
            tokenId: detail?.tokenId,
            receiveAddress: detail.ownerAddress.toLowerCase(),
          },
          detail.openBoxContract
        )
        const erc721Contract = getContract(detail.contractAddress, abis.erc721Abi, library)
        await approve(library, erc721Contract, detail.openBoxContract, detail.tokenId)
        const res = await openBox(detail.id, account.toLowerCase(), sign)
        if (res.code === 200) {
          setModalType('buy-finish')
          toggleBuyNftModal()
        } else {
          openError(res.message, 'error')
        }
      } catch (err) {
        openError(err.message, 'error')
      }
      setOpenBoxLoading(false)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const openError = (msg: string, type: 'success' | 'info' | 'warning' | 'error') => {
    setOpenAlertMsg(msg)
    setOpenAlert(true)
    setOpenAlertType(type)
  }

  // 取消订单
  const handleCancel = () => {
    setShowRevoke(true)
    reFetch()
  }

  return detail ? (
    <Wrapper maxWidth="lg">
      <RevokeNft
        data={detail}
        open={showRevoke}
        onSuccess={() => {
          setShowRevoke(false)
          window.location.href = `/${lang}/record`
          // reFetch()
        }}
        onClose={() => {
          console.log(11)
          setShowRevoke(false)
          reFetch()
        }}
      />
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={openAlertType} sx={{ width: '100%' }}>
            <AlertTitle>
              <Typography fontWeight={'bold'} color={openAlertType === 'success' ? '#4caf50' : 'rgb(255, 69, 105)'}>
                {openAlertType?.toUpperCase()}
              </Typography>
            </AlertTitle>
            <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '-15px' }}>{openAlertMsg}</p>
          </Alert>
        </Snackbar>
      </Stack>
      <Content>
        <NftLeft>
          <img src={detail.imageUrl} alt="" />
          {/* <Box>
            <Typography>{t('Deposited')}</Typography>
            <Typography>{detail.creator}</Typography>
          </Box> */}
          <Box>
            <Typography>{t('Creator')}</Typography>
            {list?.game && (
              <Typography
                sx={{
                  borderBottom: '1px solid #FFFFFF;',
                  ':hover': {
                    borderColor: '#2a64fb;',
                  },
                }}
              >
                <a href={`/${lang}/games/${list?.game.id}`}>{list?.game.name}</a>
              </Typography>
            )}
          </Box>
          <Box>
            <Typography>{t('Owner')}</Typography>
            <Typography>{shortAddress(detail?.ownerAddress)}</Typography>
          </Box>
        </NftLeft>
        <NftRight>
          <Header>
            <Box>
              <Typography>{detail.nftName}</Typography>
              <Box>
                <img width="28px" height="28px" src={BASereumPic} alt="" />
                <Typography>{t('BSC')}</Typography>
                <Typography>{isMobile ? shortAddress(detail?.contractAddress) : detail.contractAddress}</Typography>
              </Box>
            </Box>
          </Header>
          <Typography
            height={isMobile ? 'inherit' : '110px'}
            m={isMobile ? '20px 0 20px 0' : '20px 0 58px 0'}
            fontWeight={500}
            whiteSpace="pre-line"
            color={'rgba(255, 255, 255, 0.8)'}
          >
            {detail.nftComment}
          </Typography>
          {!isMobile && <Typography color={'rgba(255, 255, 255, 0.8)'}>{t('Current Bid')}</Typography>}
          {detail.priceToken?.token && detail.price ? (
            <Box display="flex" alignItems={'center'} justifyContent="space-between">
              <Price>
                <img width="28px" height="28px" src={detail.priceToken.icon} alt="" />
                <Typography fontSize="28px" fontWeight="500">
                  {detail.price}
                  <Typography fontSize="16px" fontWeight="500">
                    {detail.priceToken?.token.toLocaleUpperCase() || 'USDT'}
                  </Typography>
                </Typography>
              </Price>
              {isMobile && (
                <Typography className="current" color={'rgba(255, 255, 255, 0.8)'}>
                  {t('Current Bid')}
                </Typography>
              )}
            </Box>
          ) : (
            '--'
          )}
          {/* <Typography color={'rgba(255, 255, 255, 0.8)'}>≈$1000.00</Typography> */}
          <Box
            width={isMobile ? '100%' : '90%'}
            mt={isMobile ? '5px' : '30px'}
            display={'flex'}
            justifyContent={isMobile ? 'center' : 'space-between'}
          >
            {!account && (
              <BuleButton
                sx={{ width: '280px' }}
                onClick={() => {
                  toggleWalletModal()
                }}
              >
                {t('CONNECT WALLET')}
              </BuleButton>
            )}
            {account && (
              <Box className="mDetialBttn">
                {detail.ownerAddress.toLowerCase() === account?.toLowerCase() &&
                  detail.state === 1 &&
                  detail.nftType === 1 && (
                    <YellowButton className="selfButton" sx={{ width: '280px' }} onClick={() => open()}>
                      {openBoxLoading && <LoadingIcon color="#000" />}&nbsp;{t('OPEN')}
                    </YellowButton>
                  )}
                {detail.ownerAddress.toLowerCase() !== account?.toLowerCase() && detail.state === 5 && (
                  <BuleButton
                    sx={{ width: '280px', marginLeft: '10px' }}
                    onClick={() => {
                      setModalType('buy')
                      toggleBuyNftModal()
                    }}
                  >
                    {t('BUY')}
                  </BuleButton>
                )}
                {detail.ownerAddress.toLowerCase() === account?.toLowerCase() &&
                  (detail.state === 1 || detail.state === 4) && (
                    <BuleButton
                      className="selfButton"
                      sx={{ width: '280px', marginLeft: isMobile ? '' : '10px' }}
                      onClick={() => {
                        if (!openBoxLoading) {
                          setModalType('sell')
                          toggleBuyNftModal()
                        }
                      }}
                    >
                      {t('SELL')}
                    </BuleButton>
                  )}

                {detail.ownerAddress.toLowerCase() === account?.toLowerCase() && detail.state === 5 && (
                  <YellowLine>{t('ON SELL')}</YellowLine>
                )}
                {detail.ownerAddress.toLowerCase() === account?.toLowerCase() && detail.state === 5 && (
                  <BuleLineButton
                    sx={{ width: '280px', marginLeft: '10px' }}
                    onClick={() => {
                      handleCancel()
                    }}
                  >
                    {t('CANCEL')}
                  </BuleLineButton>
                )}
              </Box>
            )}
          </Box>
        </NftRight>
      </Content>
      <Box height="30px"></Box>
      {/* <Box>
        <img
          width="100%"
          onError={(e) => {
            ;(e.target as HTMLImageElement).src = BannerPic
          }}
          height={isMobile ? '150px' : '300px'}
          src={detail.bonusImage || BannerPic}
          alt=""
        />
      </Box>
      <Box height="30px"></Box> */}
      {detail.nftType === 2 && (
        <Box>
          <Typography fontSize="20px" lineHeight={'27px'} fontWeight="800" mb={'15px'}>
            {t('Property').toLocaleUpperCase()}
          </Typography>
          <Grid container spacing={3.75} columns={{ md: 12, xs: 4 }}>
            {attrList.map((i, k) => {
              return (
                <Grid key={`nft-attr-${k}`} item xs={4}>
                  <Cards>
                    <Box>
                      <Typography fontSize="14px" lineHeight={'24px'} fontWeight="600">
                        {(i.traitType && i.traitType.toLocaleUpperCase()) ||
                          (i.trait_type && i.trait_type.toLocaleUpperCase())}
                      </Typography>
                      <Typography m={'8px 0 13px 0'} fontSize="14px" overflow="hidden" fontWeight="500">
                        {i.value}
                      </Typography>
                    </Box>
                  </Cards>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      )}
      {detail.nftType === 1 && (
        <Grid container spacing={3.75} columns={{ md: 12, xs: 4 }}>
          {bonusList?.record.map((i, k) => {
            return (
              <Grid key={`nft-market-${k}`} item xs={4}>
                <Cards>
                  <img src={i.bonusImage} alt="" />
                  <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography m={'8px 0 13px 0'} fontSize="14px" fontWeight="500">
                      {i.comment}
                    </Typography>
                    <Typography display="flex" fontSize="14px" fontWeight="500">
                      {t('Probability')}&nbsp;&nbsp;&nbsp;
                      <Typography className="rarity">{Number(i.rarity) / 100}%</Typography>
                    </Typography>
                  </Box>
                </Cards>
              </Grid>
            )
          })}
        </Grid>
      )}
      <Modals
        info={detail}
        type={modalType}
        openModal={(type) => openModal(type)}
        openError={(msg, type) => openError(msg, type)}
        reFetch={reFetch}
      />
    </Wrapper>
  ) : (
    <Box minHeight={'calc(100vh - 305px)'} textAlign="center" pt="100px">
      <Spinner />
    </Box>
  )
}
