import { useState, useEffect } from 'react'
import { Dialog, DialogTitle, styled, Box, useMediaQuery, Button } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
// import { parseUnits } from '@ethersproject/units'
import { useWalletModalToggle } from 'state/application/hooks'
import { useTokenBalance, useAllowance } from 'hooks/useErc20'
import { approveMax } from 'constants/erc20'
import { fixedFormat } from 'utils'
import { InoDetail } from 'hooks/useIno'
import { subscribe, getUserInfo } from 'constants/ino'
import { useTranslation } from 'react-i18next'

import theme from 'theme'

import InoButton from './InoButton'
import LoadingIcon from 'components/LoadingIcon'

const Tag = styled('span')`
  background-image: linear-gradient(180deg, #fde600 0%, #cdfe00 100%);
  border-radius: 6px;
  padding: 7px 6px;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
  margin-left: 90px;
  flex-shrink: 0;
  white-space: nowrap;
  margin-top: 10px;
  align-self: flex-start;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    margin-top: 0;
  }
`

const InputDom = styled(Box)`
  display: flex;
  margin-top: 1.875rem;

  input {
    border: 1px solid #0098ff;
    border-radius: 10px;
    flex: 1;
    height: 3.75rem;
    background-color: transparent;
    outline: none;
    font-weight: 500;
    font-size: 1.75rem;
    color: #fff;
    padding: 11px 10px;
  }

  ${theme.breakpoints.down('md')} {
    flex-flow: column;
  }
`
// 错误提示
const ErrorTip = styled(Box)`
  line-height: 1;
  font-weight: bold;
  font-size: 14px;
  color: #ff2222;
  letter-spacing: 0.2px;

  ${theme.breakpoints.down('md')} {
    font-size: 12px;
  }
`

const List = styled(Box)`
  margin-top: 2.5rem;
  ${theme.breakpoints.down('md')} {
    margin-top: 15px;
  }

  li {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;

    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }

    .label {
      margin-right: 18px;
    }

    .num {
      font-size: 28px;
      margin-right: 10px;
      display: flex;
      align-items: center;

      i {
        line-height: 1;
        font-size: 14px;
        align-self: flex-end;
        font-style: normal;
        margin-left: 10px;
      }
    }

    &.payment {
      color: #fff;
    }

    &.balance {
      color: rgba(255, 255, 255, 0.5);

      .num {
        font-size: 20px;
      }
    }
  }

  ${theme.breakpoints.down('md')} {
    li {
      .label {
        margin-right: 10px;
      }

      img {
        width: 20px;
        height: 20px;
      }

      &.payment,
      &.balance {
        .num {
          font-size: 14px;
          font-weight: 500;

          i {
            align-self: center;
          }
        }
      }
    }
  }
`

interface Props {
  data: InoDetail
  open: boolean
  onClose: (success: boolean) => void
  callback: (msg: string, type: 'success' | 'info' | 'warning' | 'error') => void
}

export default function CustomDialog(props: Props) {
  const { t } = useTranslation()
  const { account, library } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  const [value, setValue] = useState<string>('1')
  const [valueError, setValueError] = useState<boolean>(false)
  const [valueErrorType, setValueErrorType] = useState<string>('')
  const { allowance, doReFetch } = useAllowance(props.data.token.address, props.data.contractAddress)
  const [approveLoading, setApproveLoading] = useState<boolean>(false)
  const [buyLoading, setBuyLoading] = useState<boolean>(false)
  const balance = useTokenBalance(props.data.token.address)
  const payment = Number(props.data.price) * Number(value)
  const [maxNum, setMaxNum] = useState<number>(0)

  const approve = async () => {
    setApproveLoading(true)
    try {
      const tx = await approveMax(library, props.data.contractAddress, props.data.token.address, account)
      await tx.wait()
      setApproveLoading(false)
      doReFetch()
    } catch (error) {
      setApproveLoading(false)
      props.callback(error.message, 'error')
      console.error(error)
    }
  }

  useEffect(() => {
    if (
      Number(value) > props.data.maxNum ||
      props.data.userBuyNum === props.data.maxNum ||
      maxNum === props.data.maxNum
    ) {
      setValueError(true)
      setValueErrorType('max')
    } else if (Number(value) < props.data.minNum) {
      setValueError(true)
      setValueErrorType('min')
    } else {
      setValueError(false)
    }
  }, [value, props.data.maxNum, props.data.minNum, props.data.userBuyNum, maxNum])
  useEffect(() => {
    getUserInfo(library, props.data.contractAddress, account).then((res) => {
      setMaxNum((res && res[1].length) || props.data.userBuyNum)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const buy = async () => {
    if (props.data.userBuyNum === props.data.maxNum || maxNum === props.data.maxNum) return
    if (Number(value) > props.data.maxNum) return
    setBuyLoading(true)
    try {
      const tx = await subscribe(library, props.data.contractAddress, value.toString(), account)
      await tx.wait()
      // props.callback('Purchase succeeded', 'success')
      setBuyLoading(false)
      props.onClose(true)
    } catch (error) {
      console.log(error)
      setBuyLoading(false)
      props.callback(error.data?.message, 'error')
    }
  }
  const close = () => {
    setValue('1')
    props.onClose(false)
  }
  return (
    <Dialog open={props.open} onClose={close}>
      <Box padding={isM ? '15px 20px' : '30px 40px'}>
        {isM && props.data.type === 1 ? (
          <Box display="flex" justifyContent="flex-end" mb="10px">
            <Tag>{t('MYSTERY BOX')}</Tag>
          </Box>
        ) : null}
        <DialogTitle
          sx={{
            fontSize: '1.75rem',
            lineHeight: '2.75rem',
            display: 'flex',
            padding: '0',
            wordBreak: 'break-word',
          }}
        >
          {props.data.inoTitle}
          {isM ? null : props.data.type === 1 && <Tag>{t('MYSTERY BOX')}</Tag>}
        </DialogTitle>
        <InputDom>
          <input
            value={value}
            maxLength={String(props.data.maxNum).length || String(props.data.destNftNum).length}
            type="text"
            onChange={(e) => {
              setValue(e.target.value.replace(/^(0+)|[^\d]+/g, ''))
            }}
          />
          <Box display="flex" marginTop={isM ? '15px' : ''}>
            <Box margin={isM ? '0 10px 0 0' : '0 10px'} flex={isM ? 1 : ''}>
              <InoButton
                text="+"
                width={isM ? '100%' : '3.75rem'}
                height="3.75rem"
                fontSize="1.75rem"
                type="primary"
                onCilck={() => {
                  setValue(String(Number(value) + 1))
                }}
              />
            </Box>
            <InoButton
              text="-"
              width={isM ? '50%' : '3.75rem'}
              height="3.75rem"
              fontSize="1.75rem"
              onCilck={() => {
                if (Number(value) > 1) {
                  setValue(String(Number(value) - 1))
                }
              }}
            />
          </Box>
        </InputDom>
        {valueError && (
          <ErrorTip mt="1.25rem">
            {t('Your %s1 purchase amount is %s2')
              .replace('%s1', valueErrorType === 'max' ? 'maximum' : 'minimum')
              .replace('%s2', valueErrorType === 'max' ? props.data.maxNum.toString() : props.data.minNum.toString())}
            {/* Your {valueErrorType === 'max' ? 'maximum' : 'minimum'} purchase amount is{' '}
            {valueErrorType === 'max' ? props.data.maxNum : props.data.minNum} */}
          </ErrorTip>
        )}

        <List component="ul">
          <li className="payment">
            <span className="label">{t('PAYMENT')}</span>
            <img src={props.data.token.icon} alt="" />
            <span className="num">
              {fixedFormat(payment) || '--'} <i>{props.data.token.token.toLocaleUpperCase()}</i>
            </span>
          </li>
          <li className="balance">
            <span className="label">{t('BALANCE')}</span>
            <img src={props.data.token.icon} alt="" />
            <div className="num">
              {fixedFormat(balance) || '--'} <i>{props.data.token.token.toLocaleUpperCase()}</i>
              {isM
                ? null
                : payment > Number(balance) && (
                    <ErrorTip alignSelf="flex-end" component="p" ml="20px">
                      {t('Insufficient available balance')}
                    </ErrorTip>
                  )}
            </div>
          </li>
        </List>

        {isM
          ? payment > Number(balance) && (
              <ErrorTip alignSelf="flex-end" component="p" ml="60px">
                {t('Insufficient available balance')}
              </ErrorTip>
            )
          : null}
        <Box marginTop={isM ? '15px' : '2.75rem'} textAlign="center">
          {!account && (
            <InoButton
              text={t('CONNECT WALLET')}
              width={isM ? '224px' : '23.125rem'}
              type="primary"
              onCilck={toggleWalletModal}
            />
          )}
          {account && Number(allowance) > 0 && (
            <InoButton
              loading={buyLoading}
              text={t('BUY')}
              width={isM ? '224px' : '23.125rem'}
              type={props.data.userBuyNum === props.data.maxNum || maxNum === props.data.maxNum ? 'info' : 'primary'}
              onCilck={buy}
            />
          )}
          {account && Number(allowance) <= 0 && (
            <Button
              startIcon={approveLoading ? <LoadingIcon /> : ''}
              onClick={approve}
              variant="contained"
              color="warning"
              fullWidth
            >
              {t('Approve')}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
