import { useState, useEffect, useRef } from 'react'
import { Box, Grid } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import Buttons from './Buttons'
import ButtonMore from './ButtonMore'
import NftCard from './NftCard'
import Empty from './Empty'
import Loading from './Loading'
import Message from 'components/Message'
import { api } from '../api'
import { useTranslation } from 'react-i18next'

// const Footer = () => {
//   return (
//     <Box
//       sx={{
//         fontWeight: 600,
//         fontSize: '2rem',
//         lineHeight: 1,
//         marginTop: '50px',
//       }}
//     >
//       POPFUN SELECT ITEMS
//     </Box>
//   )
// }

interface Props {
  isM: boolean
  account: string | undefined | null
}

const pageSize = 21 // 请求每次请求条数
// 请求类型map
const reqTypeMap: Object = {
  all: 0,
  'Mystery Box': 1,
  nft: 2,
}
const defaultData = {
  current: 0,
  totalPage: 1,
  record: [],
}

export default function Nft({ isM, account }: Props) {
  const { t } = useTranslation()
  // const typeList = [t('all'), t('Mystery Box'), t('nft')]
  const typeList = [
    { lable: 'all', name: t('all') },
    { lable: 'Mystery Box', name: t('Mystery Box') },
    { lable: 'nft', name: t('nft') },
  ]
  const [searchParams, setSearchParams] = useSearchParams()
  const queryType = searchParams.get('type') || ''
  const [type, setType] = useState('all')
  const [data, setData] = useState<NftData>(defaultData)
  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [message, setMessage] = useState<{ show: boolean; text: string }>({ show: false, text: '' })
  const hasChange = useRef('all')

  const handleChangeType = (value: string) => {
    if (value === type) return
    // if (!typeList.includes(value)) value = 'all'

    setData(defaultData)
    setHasMore(false)
    setType(value)
    setSearchParams({ type: value })
  }

  // 获取nft列表
  const getNftList = () => {
    if (!account) return

    setLoading(true)
    hasChange.current = type

    const params: NftReqParams = {
      userAddress: account,
      nftType: reqTypeMap[type],
      pageSize,
    }

    if (data.current) params.page = data.current + 1

    api.getNftList(params).then((res: any) => {
      if (hasChange.current === type) setLoading(false)

      if (res.success) {
        if (res.data.record && hasChange.current === type) {
          setHasMore(res.data.record.length >= pageSize)
          res.data.record = [...data.record, ...res.data.record.slice(0, pageSize - 1)]
          setData(res.data)
        }
      } else {
        setMessage({ show: true, text: res.message })
      }
    })
  }

  useEffect(() => {
    if (queryType) {
      handleChangeType(queryType)
    }
  }, [type]) // eslint-disable-line

  useEffect(() => {
    setData(defaultData)
    setHasMore(false)
    setLoading(false)

    if (account) {
      getNftList()
    }
  }, [type, account]) // eslint-disable-line

  return (
    <Box>
      <Buttons
        type={type}
        list={typeList}
        width={isM ? '30%' : ''}
        click={(value) => {
          handleChangeType(value)
        }}
      />

      <Message
        show={message.show}
        message={message.text}
        onClose={() => {
          setMessage({ show: false, text: '' })
        }}
      />

      <Grid
        container
        rowSpacing={2}
        columnSpacing={isM ? 2 : 3}
        minHeight="360px"
        mt={isM ? '4px' : '30px'}
        sx={{ position: 'relative' }}
      >
        {data.record.length ? (
          data.record.map((item: any) => (
            <Grid key={item.id} item xs={isM ? 6 : 3}>
              <NftCard data={item} isM={isM} />
            </Grid>
          ))
        ) : (
          <>{!loading && <Empty />}</>
        )}
        {loading && !data.record.length && <Loading />}
      </Grid>

      {hasMore && (
        <ButtonMore
          loading={loading}
          sx={{ padding: '6px 60px', margin: '40px auto 0', display: 'block' }}
          onClick={getNftList}
        />
      )}
      {/* {!!data.record.length && <Footer />} */}
    </Box>
  )
}
