import { useCallback, useEffect, useState } from 'react'
import cookie from 'react-cookies'

import { useWeb3React } from '@web3-react/core'
import { getTokenIdCount, getUserInfo, remainCount } from 'constants/mint'

export function useGamesList(page = 1, pageSize = 10) {
  const [list, setList] = useState<GameList>()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    async function fetchApi() {
      setLoading(true)
      await fetch(`${process.env.REACT_APP_INO_API_URL}/game/list?page=${page}&pageSize=${pageSize}`, {
        headers: {
          'Accept-Language': cookie.load('i18next'),
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setList((res) => {
            if (res?.current) {
              res.record = [...res.record, ...result.data.record]
              return res
            }
            return result.data
          })
          setLoading(() => {
            return false
          })
        })
    }
    fetchApi()
  }, [page, pageSize])
  return { list, loading }
}

export function useGamesDetail({ id }: { id: number }) {
  const [detail, setDetail] = useState<GameDetail>()
  const [loading, setLoading] = useState<boolean>(true)
  const fetchApi = useCallback(async () => {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_INO_API_URL}/game/detail?id=${id}`, {
      headers: {
        'Accept-Language': cookie.load('i18next'),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDetail(result.data)
        setLoading(false)
      })
  }, [id])
  useEffect(() => {
    fetchApi()
  }, [fetchApi])
  return { detail, loading }
}

export function useGameNftList(props: GameNftListPar) {
  const [list, setList] = useState<GameNftList>()
  const [loading, setLoading] = useState<boolean>(true)

  const fetchApi = useCallback(async () => {
    setLoading(true)
    await fetch(
      `${process.env.REACT_APP_INO_API_URL}/game/nft/list?category=${props.category}&maxPrice=${props.maxPrice}&minPrice=${props.minPrice}&paymentTokenId=${props.priceToken}&sortName=${props.sortName}&sort=${props.sort}&nftType=${props.nftType}&nftName=${props.nftName}&gameId=${props.gameId}&page=${props.page}&pageSize=${props.pageSize}`,
      {
        headers: {
          'Accept-Language': cookie.load('i18next'),
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (props.page === 1) {
          setList(result.data)
        } else {
          setList((res) => {
            if (res?.record) {
              res.record = [...res.record, ...result.data.record]
              return res
            }
            return result.data
          })
        }
        setLoading(false)
      })
  }, [
    props.gameId,
    props.page,
    props.pageSize,
    props.category,
    props.maxPrice,
    props.minPrice,
    props.priceToken,
    props.sortName,
    props.sort,
    props.nftType,
    props.nftName,
  ])
  useEffect(() => {
    fetchApi()
  }, [fetchApi])
  return { list, loading }
}
interface GameNFTDetail {
  id: number
  contractAddress: string
  openBoxContract: string
  tokenId: string
  nftName: string
  nftComment: string
  contractType: number
  nftType: number
  imageUrl: string
  ownerAddress: string
  creator: string
  attributes: string
  infoId: string
  price: string
  fee: string
  bonusImage: string
  // priceToken: string
  state: number
  priceToken: {
    id: number
    precision: number
    token: string
    address: string
    icon: string
  }
  game: {
    id: number
    banner: string
    name: string
    icon: string
    badge: string
    desc: string
  }
}
export function useGamesNftDetail(id: string | number | undefined) {
  const [list, setList] = useState<GameNFTDetail>()
  const [loading, setLoading] = useState<boolean>(true)
  const fetchApi = useCallback(async () => {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_INO_API_URL}/nft/detail?id=${id}`, {
      headers: {
        'Accept-Language': cookie.load('i18next'),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setList(result.data)
        setLoading(false)
      })
  }, [id])
  useEffect(() => {
    fetchApi()
  }, [fetchApi])
  return { list, loading }
}

export function useFreeMint(page = 1, pageSize = 100, finish = 0) {
  const [list, setList] = useState<FreeMintList>()
  const [endlist, setEndList] = useState<FreeMintList>()
  const { account, library } = useWeb3React()

  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    async function fetchApi() {
      setLoading(true)
      await fetch(
        `${process.env.REACT_APP_INO_API_URL}/freemint/list?page=${page}&pageSize=${pageSize}&finish=${finish}`,
        {
          headers: {
            'Accept-Language': cookie.load('i18next'),
          },
        }
      )
        .then((res) => res.json())
        .then(async (result) => {
          if (result.data.record.length > 0) {
            try {
              for (let index = 0; index < result.data.record.length; index++) {
                if (result.data.record[index].contractAddress !== '0x') {
                  const _ammount = await getTokenIdCount(library, result.data.record[index].contractAddress)
                  const _remainCount = await remainCount(library, result.data.record[index].contractAddress)
                  const selfNft = await getUserInfo(library, result.data.record[index].contractAddress, account)
                  if (selfNft && selfNft[1].length > 0) {
                    result.data.record[index].mint = true
                  }
                  result.data.record[index].amount = _ammount
                  result.data.record[index].remainCount = _remainCount
                }
              }
            } catch (error) {
              console.log(error)
            }
          }
          setList(result.data)
          if (finish === 1) {
            setEndList(result.data)
          }
          setLoading(() => {
            return false
          })
        })
    }
    fetchApi()
  }, [page, pageSize, finish, library, account])
  return { list, loading, endlist }
}
