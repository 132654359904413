import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import theme from 'theme'
import { fixedFormat } from 'utils'
import AutoImg from './AutoImg'
import Tag from './Tag'
import { useLang } from 'state/language/hooks'

const CardWrap = styled(Link)`
  border-radius: 16px;
  background-color: #191919;
  position: relative;
  display: block;
  /* height: 100%; */
`

const CardInfo = styled(Box)`
  /* min-height: 84px; */
  padding: 20px;
  border-radius: 0 0 16px 16px;

  .coin-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .price {
    flex: 1;
    word-break: break-word;
    font-size: 1.25rem;
    font-weight: bold;
  }

  ${theme.breakpoints.down('md')} {
    padding: 10px;
  }
`

interface Props {
  data: NftDetail
  isM: boolean
}

export default function NftCard({ data, isM }: Props) {
  const lang = useLang()
  return (
    <CardWrap to={`/${lang}/nft/${data.id}`}>
      <AutoImg src={data.imageUrl} alt={data.nftName} style={{ borderRadius: '16px' }} />
      {data.nftType === 1 && (
        <Tag
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          MYSTERY BOX
        </Tag>
      )}

      <CardInfo>
        <Box>
          <Typography
            variant="body1"
            sx={{
              flexShrink: 0,
              fontWeight: '600',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {data.nftName}
          </Typography>
          <Box display="flex" alignItems="center" mt="10px" height="20px">
            {Number(data.price) > 0 && (
              <Box display="flex" alignItems="center" mt="10px" height="20px">
                <img src={data.tokenIcon} alt={data.priceToken} className="coin-img" loading="lazy" />
                <span className="price">
                  {fixedFormat(data.price)} {data.priceToken?.toUpperCase()}
                </span>
              </Box>
            )}
          </Box>
        </Box>
      </CardInfo>
    </CardWrap>
  )
}
