import { Snackbar, Alert } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'

interface Props {
  show: boolean
  message: string
  onClose: () => void
  type?: 'error' | 'warning' | 'info' | 'success'
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />
}

export default function Message({ show, message, onClose, type = 'error' }: Props) {
  return (
    <Snackbar
      open={show}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={(_, reason) => {
        if (reason === 'clickaway') {
          return
        }

        onClose()
      }}
      autoHideDuration={3000}
      TransitionComponent={SlideTransition}
    >
      {!!message ? (
        <Alert
          severity={type}
          sx={{
            width: '100%',
            lineHeight: '20px',
            fontSize: '1rem',
          }}
        >
          {message}
        </Alert>
      ) : (
        <div />
      )}
    </Snackbar>
  )
}
