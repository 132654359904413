import { Box, styled } from '@mui/material'

const ImgWrap = styled(Box)`
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    > img {
      transform: scale(1.1);
    }
  }
`

interface Props {
  src: string
  alt?: string
  width?: string
  height?: string
  style?: Object
  maxHeight?: string
}

export default function AutoImg(props: Props) {
  const { src, alt, width, height = '100%', style, maxHeight } = props

  return (
    <ImgWrap
      sx={{
        paddingBottom: height,
        width,
        maxHeight,
      }}
      {...style}
    >
      <img src={src} alt={alt || ''} loading="lazy" />
    </ImgWrap>
  )
}
