import { Grid, styled, Box } from '@mui/material'
import { fixedFormat } from 'utils'
import AutoImg from './AutoImg'
import Empty from './Empty'
import Loading from './Loading'
import { formatUTCTime } from 'utils/date'
import { useTranslation } from 'react-i18next'

const TitleStyle = styled(Grid)`
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
  padding: 0 30px 10px;

  .MuiGrid-item:not(:first-of-type) {
    text-align: center;
  }
`

const ItemStyle = styled(Grid)`
  display: flex;
  align-items: center;
  &:not(:first-of-type) {
    justify-content: center;
  }

  a:hover {
    text-decoration: underline;
  }
`

const ButtonStyle = styled(Box)`
  font-weight: 500;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 12px 10px;
  color: rgba(255, 255, 255, 0.6);
  opacity: 0.6;
  line-height: 1;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

const Tip = styled(Box)`
  display: flex;
  line-height: 1;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10.5px;
  padding: 4px;
  margin-left: 100px;

  i {
    background-image: linear-gradient(178deg, #cdfe00 0%, #fde600 100%);
    font-weight: 500;
    font-size: 12px;
    color: #000;
    width: 12px;
    height: 12px;
    font-style: normal;
    text-align: center;
    border-radius: 50%;
    flex-shrink: 0;
    margin-right: 4px;
  }

  p {
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
  }
`

interface Props {
  list: OrderDetail[]
  type: string // 'purchase' | 'sell' | 'history'
  loading: boolean
  onCancel?: (detail: any) => void
}

const columnsWidth = [1.7, 2.8, 2.4, 2, 2, 1.1]

export default function OrderTable(props: Props) {
  const { t } = useTranslation()
  const titles = [t('Order ID'), t('NFT'), t('Blocks'), t('Price'), t('Time'), '']

  const { list, type, loading = true, onCancel } = props

  return (
    <Box position="relative" minHeight="360px">
      {!!list.length && (
        <>
          <TitleStyle container columnSpacing={1}>
            {titles.map((item: string, index) => (
              <Grid key={item} item xs={columnsWidth[index]}>
                {item}
              </Grid>
            ))}
          </TitleStyle>
        </>
      )}
      {list.length ? (
        list.map((item) => {
          return (
            <Grid
              key={item.id}
              container
              columnSpacing={1}
              sx={{
                padding: '10px 30px',
                fontWeight: 500,
                fontSize: '0.875rem',
                backgroundColor: '#191919',
                borderRadius: '12px',
                overflow: 'hidden',
                marginBottom: '20px',
              }}
            >
              <ItemStyle item xs={columnsWidth[0]}>
                ID {item.id}
              </ItemStyle>
              <ItemStyle item xs={columnsWidth[1]} style={{ justifyContent: 'flex-start' }}>
                <Box width="80px" mr="20px" flexShrink="0">
                  <AutoImg src={item.imageUrl} alt={item.nftName} height="100%" style={{ borderRadius: '8px' }} />
                </Box>
                <Box>
                  <p>{item.nftName}</p>
                  <p>X 1</p>
                </Box>
              </ItemStyle>
              <ItemStyle item xs={columnsWidth[2]}>
                {item.hash ? (
                  <a
                    href={`https://bscscan.com/tx/${item.hash}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {item.hash}
                  </a>
                ) : (
                  '--'
                )}
              </ItemStyle>
              <ItemStyle item xs={columnsWidth[3]}>
                {fixedFormat(item.price)} {item.priceToken?.toUpperCase()}
              </ItemStyle>
              <ItemStyle item xs={columnsWidth[4]}>
                {item.blockTime > 0 ? <Box>{formatUTCTime(item.blockTime)} UTC</Box> : '--'}
              </ItemStyle>
              <ItemStyle item xs={columnsWidth[5]}>
                {/* 卖 */}
                {item.state === 1 && (
                  <ButtonStyle
                    onClick={() => {
                      onCancel && onCancel(item)
                    }}
                  >
                    {t('CANCEl')}
                  </ButtonStyle>
                )}
                {/* 历史 */}
                {item.state !== 1 && (item.state === 0 ? t('Revoked') : t('Finished'))}
              </ItemStyle>

              {item.state === 3 && (
                <>
                  <ItemStyle item xs={columnsWidth[0]} />
                  <ItemStyle item xs={8} sx={{ justifyContent: 'flex-start !important' }}>
                    <Tip>
                      <i>!</i>
                      <p>{t('NFT was not found. Please cancel this order to avoid your loss.')}</p>
                    </Tip>
                  </ItemStyle>
                </>
              )}
            </Grid>
          )
        })
      ) : (
        <>{!loading && <Empty />} </>
      )}
      {loading && type === 'purchase' && !list.length && <Loading />}
    </Box>
  )
}
