import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from 'assets/images/modal/close.png'
import Mohander from 'assets/images/modal/modal.png'
import theme from 'theme'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))
const ModalImag = styled('img')`
  width: 168px;
  height: 103px;
  margin-top: 50px;
  ${theme.breakpoints.down('md')} {
    margin-top: 25px;
    width: 112px;
    height: 69px;
  }
`
const ModalButton = styled(DialogActions)`
  justify-content: center;
  button {
    width: 516px;
    height: 56px;
    background: linear-gradient(90deg, #2a64fb 0%, #00a1ff 100%);
    border-radius: 10px;
    margin-bottom: 38px;
    ${theme.breakpoints.down('md')} {
      width: 90%;
      height: 51px;
      margin-bottom: 19px;
    }
  }
`
export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props
  const isM = !useMediaQuery((theme: any) => theme.breakpoints.up('md'))

  return (
    <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'center' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src={CloseIcon} width={isM ? '22' : '44'} alt=""></img>
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface props {
  open: boolean
  close: () => void
}

export default function IpNotice(props: props) {
  const { t } = useTranslation()
  return (
    <div>
      <BootstrapDialog onClose={props.close} aria-labelledby="customized-dialog-title" open={props.open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.close}>
          <ModalImag src={Mohander} alt=""></ModalImag>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom sx={{ fontWeight: 700, fontSize: '28px', lineHeight: '40px', textAlign: 'center' }}>
            {t('本站暂不支持您所在的地区，请您确保遵守当地的相关法律法规')}
          </Typography>
          <Typography
            gutterBottom
            sx={{ fontWeight: 800, fontSize: '16px', lineHeight: '22px', textAlign: 'center', wordBreak: 'break-word' }}
          >
            {t(
              'We are temporarily unable to provide services in your region due to the requirements of relevant laws and regulations.Thanks for your understanding.'
            )}
          </Typography>
        </DialogContent>
        <ModalButton>
          <Button onClick={props.close}>{t('我已知晓')}</Button>
        </ModalButton>
      </BootstrapDialog>
    </div>
  )
}
