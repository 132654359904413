import { Interface } from '@ethersproject/abi'
import TARGET_ABI from 'abis/target.json'
// const abi = require('./abi')

const replacementAbi = [
  'function erc721Transfer(uint256 from, uint256 to, address token, uint256 tokenId) external returns (bool)',
  'function erc721SafeTransfer(uint256 from, uint256 to, address token, uint256 tokenId) external returns (bool)',
  'function erc1155SafeTransfer(uint256 from, uint256 to, address token, uint256 tokenId, uint256 amount) external returns (bool)',
  'function matchERC721UsingCriteria(uint256 from,uint256 to,address token,uint256 tokenId,bytes32 root,bytes32[] calldata proof) external returns (bool)',
  'function matchERC721WithSafeTransferUsingCriteria(uint256 from, uint256 to, address token, uint256 tokenId, bytes32 root, bytes32[] calldata proof) external returns (bool)',
  'function matchERC1155UsingCriteria(uint256 from,uint256 to,address token,uint256 tokenId,uint256 amount,bytes32 root,bytes32[] calldata proof) external returns (bool)',
]

const contractType = {
  ERC721_TRANSFER: 0,
  ERC721_SAFE_TRANSFER: 1,
  ERC1155_SAFE_TRANSFER: 2,
  ERC721_TRANSFER_CHECK: 3,
  ERC721_SAFE_TRANSFER_CHECK: 4,
  ERC1155_SAFE_TRANSFER_CHECK: 5,
}
const side = {
  BUY: 0,
  SELL: 1,
}

const saleKind = {
  FixedPrice: 0,
  DutchAuction: 1,
}

const howtoCall = {
  Call: 0,
  DelegateCall: 1,
}
const targetIfs = new Interface(TARGET_ABI)
const replaceIfs = new Interface(replacementAbi)
const BIG_UINT256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
const ZERO_BYTES32 = '0x0000000000000000000000000000000000000000000000000000000000000000'
const NULL_SIG = { v: 27, r: ZERO_BYTES32, s: ZERO_BYTES32 }

// get function encode and replacement pattern
async function encode(
  type: number,
  from: string,
  to: string,
  token: string,
  tokenId: string,
  amount: string | number,
  root?: any,
  proof?: any
) {
  let functionEncoded
  if (type === contractType.ERC721_TRANSFER) {
    functionEncoded = targetIfs.encodeFunctionData('erc721Transfer', [from, to, token, tokenId])
  } else if (type === contractType.ERC721_SAFE_TRANSFER) {
    functionEncoded = targetIfs.encodeFunctionData('erc721SafeTransfer', [from, to, token, tokenId])
  } else if (type === contractType.ERC1155_SAFE_TRANSFER) {
    functionEncoded = targetIfs.encodeFunctionData('erc1155SafeTransfer', [from, to, token, tokenId, amount])
  } else if (type === contractType.ERC721_TRANSFER_CHECK) {
    functionEncoded = targetIfs.encodeFunctionData('matchERC721UsingCriteria', [from, to, token, tokenId, root, proof])
  } else if (type === contractType.ERC721_SAFE_TRANSFER_CHECK) {
    functionEncoded = targetIfs.encodeFunctionData('matchERC721WithSafeTransferUsingCriteria', [
      from,
      to,
      token,
      tokenId,
      root,
      proof,
    ])
  } else if (type === contractType.ERC1155_SAFE_TRANSFER_CHECK) {
    functionEncoded = targetIfs.encodeFunctionData('matchERC1155UsingCriteria', [
      from,
      to,
      token,
      tokenId,
      amount,
      root,
      proof,
    ])
  }

  return functionEncoded
}

async function getReplacement(sidetype: any, type: any, proofLen?: any) {
  const proofArray = []
  if (null != proofLen && proofLen !== 0) {
    for (let i = 0; i < proofLen; i++) {
      proofArray.push(ZERO_BYTES32)
    }
  }
  let rep
  if (sidetype === side.BUY) {
    if (type === contractType.ERC721_TRANSFER || type === contractType.ERC721_SAFE_TRANSFER) {
      rep = replaceIfs.encodeFunctionData('erc721Transfer', [BIG_UINT256, 0, ZERO_ADDRESS, 0])
    } else if (type === contractType.ERC1155_SAFE_TRANSFER) {
      rep = replaceIfs.encodeFunctionData('erc1155SafeTransfer', [BIG_UINT256, 0, ZERO_ADDRESS, 0, 0])
    } else if (type === contractType.ERC721_TRANSFER_CHECK || type === contractType.ERC721_SAFE_TRANSFER_CHECK) {
      rep = replaceIfs.encodeFunctionData('matchERC721UsingCriteria', [
        BIG_UINT256,
        0,
        ZERO_ADDRESS,
        0,
        ZERO_BYTES32,
        proofArray,
      ])
    } else if (type === contractType.ERC1155_SAFE_TRANSFER_CHECK) {
      rep = replaceIfs.encodeFunctionData('matchERC1155UsingCriteria', [
        BIG_UINT256,
        0,
        ZERO_ADDRESS,
        0,
        ZERO_BYTES32,
        proofArray,
      ])
    }
  } else if (sidetype === side.SELL) {
    if (type === contractType.ERC721_TRANSFER || type === contractType.ERC721_SAFE_TRANSFER) {
      rep = replaceIfs.encodeFunctionData('erc721Transfer', [0, BIG_UINT256, ZERO_ADDRESS, 0])
    } else if (type === contractType.ERC1155_SAFE_TRANSFER) {
      rep = replaceIfs.encodeFunctionData('erc1155SafeTransfer', [0, BIG_UINT256, ZERO_ADDRESS, 0, 0])
    } else if (type === contractType.ERC721_TRANSFER_CHECK || type === contractType.ERC721_SAFE_TRANSFER_CHECK) {
      rep = replaceIfs.encodeFunctionData('matchERC721UsingCriteria', [
        0,
        BIG_UINT256,
        ZERO_ADDRESS,
        0,
        ZERO_BYTES32,
        proofArray,
      ])
    } else if (type === contractType.ERC1155_SAFE_TRANSFER_CHECK) {
      rep = replaceIfs.encodeFunctionData('matchERC1155UsingCriteria', [
        0,
        BIG_UINT256,
        ZERO_ADDRESS,
        0,
        ZERO_BYTES32,
        proofArray,
      ])
    }
  }
  return '0x00000000' + rep?.substr(10, rep.length)
}

export { contractType, side, saleKind, howtoCall, NULL_SIG, ZERO_ADDRESS, ZERO_BYTES32, encode, getReplacement }
