import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { colors } from 'theme'

interface TitleProps {
  text: string
  subTitle?: string
}

const SubTitle = styled(Typography)`
  font-size: 0.875rem;
  color: ${colors.fontColor2};
  line-height: 40px;
`

export default function Title(props: TitleProps) {
  return (
    <Box
      mb={(theme) => (theme.breakpoints.down('md') ? '10px' : '26px')}
      display={'flex'}
      justifyContent={'space-between'}
    >
      <Typography variant="h1" component="div">
        {props.text}
      </Typography>
      {props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
    </Box>
  )
}
