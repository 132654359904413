import { Box, Container } from '@mui/material'
import InfoMenu from './components/InfoMenu'
import Card from './components/Card'

export default function Strategy() {
  return (
    <Container maxWidth="lg">
      <Box>
        <InfoMenu />
        <Card type={'strategy'} />
      </Box>
    </Container>
  )
}
