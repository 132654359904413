import { ReactChild } from 'react'
import { Box } from '@mui/material'
import LoadingIcon from 'components/LoadingIcon'

interface Props {
  children?: ReactChild
  width?: string
  height?: string
  button?: boolean
}

export default function Spinner(props: Props) {
  const { children, width, height, button = false } = props

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: ' rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
      }}
    >
      <LoadingIcon width={width ? width : '4rem'} height={height ? height : '4rem'} />

      {!button && (
        <Box
          sx={{
            fontSize: '2rem',
            color: 'rgba(255, 255, 255, 0.5)',
            fontWeight: '500',
            marginTop: '20px',
          }}
        >
          {children || 'Loading...'}
        </Box>
      )}
    </Box>
  )
}
