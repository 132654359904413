import { ReactNode } from 'react'
import { Box } from '@mui/material'
import emptyImg from 'assets/images/png/empty.png'
import { useTranslation } from 'react-i18next'
interface Props {
  children?: ReactNode
}

export default function Empty({ children }: Props) {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <img src={emptyImg} alt="empty" loading="lazy" width="120px" height="109px" />

      <Box
        sx={{
          fontSize: '1rem',
          color: 'rgba(255, 255, 255, 0.6)',
          fontWeight: '500',
          marginTop: '12px',
        }}
      >
        {children || t('NO ITEMS')}
      </Box>
    </Box>
  )
}
