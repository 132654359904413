import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'
import { igoInfoType } from 'hooks/useIGO'
import { canClaim, claim } from 'constants/igo'
import { formatAmount } from 'utils'
import { formatUnits } from '@ethersproject/units'
import { useWalletModalToggle } from 'state/application/hooks'

import LoadingIcon from 'components/LoadingIcon'
import { useTranslation } from 'react-i18next'

const PayWapper = styled(Box)`
  width: 380px;
  margin-top: 34px;
  padding: 36px 24px;
  ${theme.breakpoints.down('md')} {
    padding: 0;
    width: 100%;
  }
  div {
    padding: 0 40px;
  }
`
const ClaimButtonMobileStyl = styled(Button)`
  ${theme.breakpoints.down('md')} {
    position: fixed;
    bottom: 10px;
    width: 96%;
    left: 0;
    margin-left: 2%;
  }
`

export default function Pay({ info }: { info: igoInfoType | undefined }) {
  const { t } = useTranslation()
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const toggleWalletModal = useWalletModalToggle()
  const { account, library } = useWeb3React()
  const [claimLoading, setClaimLoading] = useState<boolean>(false)
  const [claimStatus, setClaimStatus] = useState<boolean>(false)
  const [isSubscribe, setIsSubscribe] = useState<boolean>(false)
  const [isClaimed, setIsClaimed] = useState<boolean>(false)
  const [allocAmount, setAllocAmount] = useState<number>(0)

  const claimHandler = async () => {
    setClaimLoading(true)
    if (info && account) {
      try {
        const tx = await claim(library, info.contract, account)
        await tx.wait()
        setClaimLoading(false)
        setIsClaimed(true)
      } catch (error) {
        setClaimLoading(false)
        console.error(error)
      }
    }
  }

  useEffect(() => {
    async function fetchCanCalim() {
      if (info && account) {
        const _canClaim = await canClaim(library, info?.contract, account)
        if (Number(formatUnits(_canClaim.allocAmount_)) <= 0) {
          setIsSubscribe(false)
        }

        if (Number(formatUnits(_canClaim.allocAmount_)) > 0) {
          setIsSubscribe(true)
          setClaimStatus(true)
          setAllocAmount(Number(formatUnits(_canClaim.allocAmount_)))
        }
        if (_canClaim.isClaimed_) {
          setIsSubscribe(true)
          setIsClaimed(true)
        } else {
          setIsClaimed(false)
        }
      }
    }
    if (info?.status === 'over') {
      fetchCanCalim()
    }
  }, [info, account, library])

  return isSubscribe ? (
    <PayWapper>
      <Typography
        textAlign={'center'}
        mb={matches ? '12px' : '24px'}
        color={colors.fontColor}
        lineHeight={1.2}
        fontWeight={500}
        fontSize={'1.5rem'}
      >
        {claimStatus &&
          Number(info?.totalPurchasedSourceCurrency) > Number(info?.totalSourceCurrencyAmount) &&
          `It has been over-raised, after calculation you will get ${formatAmount(allocAmount)} ${
            info?.destCurrency.name
          }. Please click Claim here. `}
        {claimStatus &&
          Number(info?.totalPurchasedSourceCurrency) <= Number(info?.totalSourceCurrencyAmount) &&
          `You will get ${formatAmount(allocAmount)} ${info?.destCurrency.name} please click Claim here.`}
        {!claimStatus && 'System calculation, please wait'}
      </Typography>
      <Box>
        {!account && (
          <Button variant="contained" color="warning" fullWidth onClick={toggleWalletModal}>
            {t('Connect Wallet')}
          </Button>
        )}
        {account &&
          (isClaimed ? (
            <ClaimButtonMobileStyl disabled variant="contained" color="primary" fullWidth>
              Claimed
            </ClaimButtonMobileStyl>
          ) : (
            <ClaimButtonMobileStyl
              startIcon={claimLoading ? <LoadingIcon /> : ''}
              disabled={!claimStatus}
              variant="contained"
              color="primary"
              fullWidth
              onClick={claimHandler}
            >
              Claim
            </ClaimButtonMobileStyl>
          ))}
      </Box>
    </PayWapper>
  ) : (
    <Box mt={'20px'}></Box>
  )
}
