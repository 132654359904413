import { Box, Container, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fromNow } from 'utils/date'
import Spinner from 'components/Spinner'
import { useTranslation } from 'react-i18next'
import info from './components/data'

const Content = styled(Box)`
  font-family: 'IBM', sans-serif !important;
  margin-bottom: 50px;
  h1,
  h2 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 32px;
    font-size: 32px;
  }

  h2 {
    margin-top: 20px;
  }

  h3 {
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 24px;
    font-size: 24px;
  }

  p,
  div {
    line-height: 22px;
    color: #999;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
  }

  ul {
    list-style: revert;
    padding-left: revert;
    margin-bottom: 20px;

    li {
      list-style: revert;
      line-height: 22px;
      color: #999;
    }
  }

  table {
    width: 100%;
    border: 1px solid #c7cbd0;
    border-top: 0;
    border-left: 0;
    margin-bottom: 10px;

    th,
    td {
      font-size: 14px;
      line-height: 1.5;
      text-align: center;
      padding: 10px;
      color: #111;
      border: 1px solid #c7cbd0;
      border-right: 0;
      border-bottom: 0;
      font-weight: bold;
    }

    th {
      background-color: #f3f3f3;
    }
  }
`

interface DetailPro {
  html: string
  title: string
  fetch_at: string
  created_at: string
  updated_at: string
  summary: string
  type: string
}
export default function Detail() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [detail, setDetail] = useState<DetailPro>()
  useEffect(() => {
    setDetail(info.details.find((e) => e._key === id))
  }, [id])
  return detail ? (
    <Container maxWidth="lg">
      <Typography sx={{ fontWeight: 700, fontSize: '32px', marginBottom: '32px', lineHeight: ' 42px', mt: '30px' }}>
        {detail?.title}
      </Typography>
      <Typography sx={{ color: '#999', fontWeight: 700, lineHeight: '22px', marginBottom: '12px' }}>
        {detail?.fetch_at ? fromNow(detail.fetch_at) : ''}
      </Typography>
      <Typography sx={{ color: '#999', fontWeight: 700, lineHeight: '22px', marginBottom: '16px' }}>
        {t('已创建')}
        {detail.created_at ? fromNow(detail.created_at) : ''},{t('最新版本')}
        {detail.updated_at ? fromNow(detail.updated_at) : ''}
      </Typography>
      {detail.summary && detail.type === 'learn' && <summary>{detail.summary}</summary>}
      {detail?.html && <Content dangerouslySetInnerHTML={{ __html: detail?.html }}></Content>}
    </Container>
  ) : (
    <Box minHeight={'calc(100vh - 305px)'} textAlign="center" pt="100px">
      <Spinner />
    </Box>
  )
}
