import { parseUnits, parseEther } from '@ethersproject/units'
import { Web3Provider } from '@ethersproject/providers'
import { splitSignature } from '@ethersproject/bytes'
import { getContract } from 'utils'
import * as targetTool from 'utils/targetTool'
import * as eip712t from 'utils/eip712'

import { exchangeAddress, exchangeStorageAddress, targetAddress, proxyRegistryAddress } from 'constants/nft'
import * as abis from 'abis/NFTAbis'
import { NFTDetail, getSalt, nftSell, nftBuy } from 'hooks/useNFT'

export async function getBLockTime(provider: Web3Provider) {
  const info = await provider.getBlock('latest')
  return info.timestamp
}

export async function makerBuyOrder(
  provider: Web3Provider,
  paymentToken: string,
  order: any,
  tokenId: string,
  signature: any,
  account: any,
  erc721Address: string,
  price: string
) {
  const blockTime = await getBLockTime(provider)

  const currenctTime = blockTime - 100
  const expireTime = currenctTime + 720 * 86400

  const exchangeStorageContract = getContract(exchangeStorageAddress, abis.exchangeStorageAbi, provider)
  const erc721 = getContract(erc721Address, abis.erc721Abi, provider)

  //buy nft
  const buyNonce = await exchangeStorageContract.nonces(account)
  const buyCalldata = await targetTool.encode(
    targetTool.contractType.ERC721_TRANSFER,
    targetTool.ZERO_ADDRESS,
    account,
    erc721.address,
    tokenId,
    0
  )

  const buyReplace = await targetTool.getReplacement(targetTool.side.BUY, targetTool.contractType.ERC721_TRANSFER)

  const buyOrder = {
    exchange: exchangeAddress,
    maker: account,
    side: targetTool.side.BUY,
    saleKind: targetTool.saleKind.FixedPrice,
    howToCall: targetTool.howtoCall.DelegateCall,
    target: targetAddress,
    calldatas: buyCalldata,
    replacementPattern: buyReplace,
    paymentToken,
    basePrice: parseEther(price),
    extra: '0',
    listingTime: parseUnits(currenctTime.toString(), 0),
    expirationTime: parseUnits(expireTime.toString(), 0),
    salt: parseUnits(order.salt.toString(), 0),
    nonce: buyNonce,
    makerFee: order.makerFee,
  }

  const exchangeContract = getContract(exchangeAddress, abis.exchangeAbi, provider)

  if (buyCalldata) {
    const signer = await provider.getSigner()
    const buyerSign = await eip712t.signEip712(signer, buyOrder)

    const splitBuyerSign = splitSignature(buyerSign)
    const info = await exchangeContract
      .connect(signer)
      .atomicMatch(
        [
          buyOrder.exchange,
          buyOrder.maker,
          buyOrder.side,
          buyOrder.saleKind,
          buyOrder.howToCall,
          buyOrder.target,
          buyOrder.calldatas,
          buyOrder.replacementPattern,
          buyOrder.paymentToken,
          buyOrder.basePrice,
          buyOrder.extra,
          buyOrder.listingTime,
          buyOrder.expirationTime,
          buyOrder.salt,
          buyOrder.makerFee,
        ],
        [splitBuyerSign.v, splitBuyerSign.r, splitBuyerSign.s],
        [
          order.exchange,
          order.maker,
          order.side,
          order.saleKind,
          order.howToCall,
          order.target,
          order.calldatas,
          order.replacementPattern,
          order.paymentToken,
          order.basePrice,
          order.extra,
          order.listingTime,
          order.expirationTime,
          order.salt,
          order.makerFee,
        ],
        [signature.v, signature.r, signature.s],
        '0x0000000000000000000000000000000000000000000000000000000000000000',
        { gasLimit: parseUnits('500000', 0) }
      )
    console.log(info)
    return info
  }
}

async function registryProxy(proxyRegistry: any, account: string, signer: any) {
  const address = await proxyRegistry.proxies(account)
  if (address === targetTool.ZERO_ADDRESS) {
    const info = await proxyRegistry.connect(signer).registerProxy()
    console.log(info)
    return info
  } else {
    return 'isRegistry'
  }
}

export async function approve(provider: Web3Provider, contract: any, address: string, tokenId: string) {
  const approvedAddress = await contract.getApproved(tokenId)
  if (approvedAddress.toLowerCase() === address.toLowerCase()) {
    return true
  }
  const signer = await provider.getSigner()
  const info = await contract.connect(signer).approve(address, tokenId)
  await info.wait()
  return info
}

export async function makeSellOrder(
  provider: Web3Provider,
  nftDetail: NFTDetail,
  account: any,
  erc721Address: string,
  price: string,
  paymentTokenAddress: string
) {
  const signer = await provider.getSigner()
  const proxyRegistry = getContract(proxyRegistryAddress, abis.proxyRegistryAbi, provider)
  const exchangeStorageContract = getContract(exchangeStorageAddress, abis.exchangeStorageAbi, provider)
  const erc721 = getContract(erc721Address, abis.erc721Abi, provider)

  const registryProxyRes = await registryProxy(proxyRegistry, account, signer)

  console.log('registryProxyRes: ', registryProxyRes)

  const sellProxy = await proxyRegistry.proxies(account)
  await approve(provider, erc721, sellProxy, nftDetail.tokenId)

  const salt = await getSalt()
  const nonce = await exchangeStorageContract.nonces(account)
  const makerFee = nftDetail.fee

  const blockTime = await getBLockTime(provider)
  const currenctTime = blockTime - 100
  const expireTime = currenctTime + 720 * 86400

  const calldatas = await targetTool.encode(
    targetTool.contractType.ERC721_TRANSFER,
    account,
    targetTool.ZERO_ADDRESS,
    erc721Address,
    nftDetail.tokenId,
    0
  )
  const replace = await targetTool.getReplacement(targetTool.side.SELL, targetTool.contractType.ERC721_TRANSFER)

  const sellOrder = {
    exchange: exchangeAddress,
    maker: account,
    side: targetTool.side.SELL,
    saleKind: targetTool.saleKind.FixedPrice,
    howToCall: targetTool.howtoCall.DelegateCall,
    target: targetAddress,
    calldatas,
    replacementPattern: replace,
    paymentToken: paymentTokenAddress,
    basePrice: parseEther(price),
    extra: '0',
    listingTime: parseUnits(currenctTime.toString(), 0),
    expirationTime: parseUnits(expireTime.toString(), 0),
    salt: parseUnits(salt.toString(), 0),
    nonce,
    makerFee,
  }

  const signature = await eip712t.signEip712(signer, sellOrder)
  console.log(signature)

  const sellRes = await nftSell(nftDetail.id, sellOrder, signature)

  return sellRes
}

// 撤销挂单
export async function cancelOrder(nftId: number, provider: Web3Provider) {
  return new Promise(async (resolve, reject) => {
    const res = await nftBuy(nftId)

    if (res.code !== 200) {
      resolve({
        success: false,
        message: res.message || res.data?.message,
        code: res.code,
        data: {},
      })
      return
    }

    // 签名信息
    const signInfo = {
      exchange: res.data.exchangeAddress,
      maker: res.data.makerAddress,
      side: res.data.side,
      saleKind: res.data.saleKind,
      howToCall: res.data.howToCall,
      target: res.data.targetAddress,
      calldatas: res.data.calldatas,
      replacementPattern: res.data.replacementPatterns,
      paymentToken: res.data.paymentToken,
      basePrice: parseUnits(res.data.basePrice, 0),
      extra: '0',
      listingTime: parseUnits(res.data.listingTime.toString(), 0),
      expirationTime: parseUnits(res.data.expirationTime.toString(), 0),
      salt: parseUnits(res.data.salt, 0),
      makerFee: res.data.fee,
    }

    const signature = splitSignature(res.data.signature)
    const exchange = getContract(exchangeAddress, abis.exchangeAbi, provider)
    const signer = await provider.getSigner()
    let info
    try {
      info = await exchange
        .connect(signer)
        .cancelOrder(Object.values(signInfo), [signature.v, signature.r, signature.s], res.data.nonce)
    } catch (error) {
      reject(error)
    }

    resolve({
      success: true,
      message: '',
      data: info,
    })
  })
}
