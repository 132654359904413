import { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { useModalOpen, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import usePrevious from 'hooks/usePrevious'
import { useTranslation } from 'react-i18next'

import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

import { injected } from 'connectors'
import { SUPPORTED_WALLETS, tabConnect } from 'constants/wallet'

import MetamaskIcon from 'assets/images/png/metamask.png'

import Modal from '../Modal'
import Option from './Option'
import theme from 'theme'

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  LEGAL: 'legal',
}

export default function WalletModal() {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const { account, connector, activate, error } = useWeb3React()
  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)
  const toggleWalletModal = useWalletModalToggle()
  const previousAccount = usePrevious(account)

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)

  const previousWalletView = usePrevious(walletView)

  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      toggleWalletModal()
    }
  }, [account, previousAccount, toggleWalletModal, walletModalOpen])
  useEffect(() => {
    tabConnect()
  }, [error])
  const tryActivation = async (connector: AbstractConnector | undefined) => {
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (connector === SUPPORTED_WALLETS[key].connector) {
        return SUPPORTED_WALLETS[key].name
      }
      return true
    })

    if (connector instanceof WalletConnectConnector) {
      connector.walletConnectProvider = undefined
    }

    console.log(connector)

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    connector &&
      activate(connector, undefined, true)
        .then(async () => {
          const walletAddress = await connector.getAccount()
          console.log(walletAddress)
          // logMonitoringEvent({ walletAddress })
        })
        .catch((error) => {
          console.log(error, '-----------')
          if (error instanceof UnsupportedChainIdError) {
            activate(connector) // a little janky...can't use setError because the connector isn't set
          } else {
            console.log(true, '-----')
          }
        })
  }
  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key]
      // check for mobile options
      // if (isMobile) {
      //   if (!window.web3 && !window.ethereum && option.mobile) {
      //     return (
      //       <Option
      //         onClickEvt={() => {
      //           option.connector !== connector && !option.href && tryActivation(option.connector)
      //         }}
      //         key={key}
      //         link={option.href}
      //         header={option.name}
      //         icon={option.iconURL}
      //       />
      //     )
      //   }
      //   return null
      // }

      // console.log(option.name)

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <Option key={key} header={<p>Install Metamask</p>} link={'https://metamask.io/'} icon={MetamaskIcon} />
            )
          } else {
            return null //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null
        }
      }

      // return rest of options
      return (
        !option.mobileOnly && (
          <Option
            onClickEvt={() => {
              option.connector === connector
                ? setWalletView(WALLET_VIEWS.ACCOUNT)
                : !option.href && tryActivation(option.connector)
            }}
            key={key}
            link={option.href}
            header={option.name}
            icon={option.iconURL}
          />
        )
      )
    })
  }
  function getModalContent() {
    if (error) {
      return (
        <Box p="40px 56px">
          <Typography color={theme.palette.error.main} flex="1" variant="h4">
            {error instanceof UnsupportedChainIdError ? <Box>{t('Wrong Network')}</Box> : <Box>Error connecting</Box>}
          </Typography>
          <Box>
            {error instanceof UnsupportedChainIdError ? (
              <Typography color={theme.palette.error.main} flex="1" variant="h6">
                {t('Please connect to the appropriate Ethereum network.')}
              </Typography>
            ) : (
              <Typography color={theme.palette.error.main} flex="1" variant="h6">
                {t('Error connecting. Try refreshing the page.')}
              </Typography>
            )}
          </Box>
        </Box>
      )
    }
    if (walletView === WALLET_VIEWS.LEGAL) {
      return (
        <Box>
          <Box>
            <Box
              onClick={() => {
                setWalletView(
                  (previousWalletView === WALLET_VIEWS.LEGAL ? WALLET_VIEWS.ACCOUNT : previousWalletView) ??
                    WALLET_VIEWS.ACCOUNT
                )
              }}
            >
              <Box />
            </Box>
            <Box>
              <Box>
                <Box>Legal & Privacy</Box>
              </Box>
            </Box>
          </Box>
          <Box />
        </Box>
      )
    }
    return <Box width={isMobile ? '100%' : '640px'}>{getOptions()}</Box>
  }
  return (
    <Modal isOpen={walletModalOpen} onClose={toggleWalletModal} isTitle={!error} title={t('CONNECT WALLET')}>
      <Box>{getModalContent()}</Box>
    </Modal>
  )
}
