import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import ja from './i18n/json/ja-jp.json'
import en from './i18n/json/en-us.json'
// import ko from './i18n/json/ko-kr.json'
import zh from './i18n/json/zh-cn.json'
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
  // 'ja-jp': { translation: ja },
  'en-us': { translation: en },
  // 'ko-kr': { translation: ko },
  'zh-cn': { translation: zh },
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['zh-cn', 'en-us', 'ja-jp', 'ko-kr'],
    resources,
    debug: false,
    lowerCaseLng: true,
    lng: 'en-us',
    // fallbackLng: {
    //   'zh-cn': ['en-us'],
    //   default: ['en-us'],
    // },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie'],
    },
  })
export default i18n
