import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import Modal from 'components/Modal'
import theme from 'theme'
import Sorry from 'assets/images/mint/sorry.png'
import { BuleButton } from 'styleds'
import { useTranslation } from 'react-i18next'
import BuyNftPic from 'assets/images/mint/successNft.png'

const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px 40px;
  width: 760px;
  > img {
    width: 160px;
    height: 160px;
  }
  p {
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    margin-top: 20px;
  }
  ${theme.breakpoints.down('md')} {
    width: 98%;
    P {
      text-align: center;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      margin-top: 10px;
    }
  }
`
interface ModalAttr {
  state: number
  desc: string
  open: boolean
  clonse: () => void
}

export default function MintModal({ state, desc, open, clonse }: ModalAttr) {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  return (
    <Modal
      isOpen={open}
      onClose={(e, reason) => {
        clonse()
      }}
    >
      <ModalContent>
        {state === 2 && (
          <>
            <img src={Sorry} alt="" />
            <Typography>{desc}</Typography>
          </>
        )}
        {state === 1 && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={BuyNftPic} alt="" width={isMobile ? '100px' : ''} />
            <Typography fontSize="32px" lineHeight="32px" fontWeight={800}>
              {t('Succeed')}
            </Typography>
            <Typography fontSize="16px" sx={{ marginBottom: '20px' }}>
              {t('*A delay might be caused by the traffic congestion on chain.')}
            </Typography>
            <BuleButton sx={{ width: '280px' }} onClick={() => clonse()}>
              {t('CHECK YOUR NFT')}
            </BuleButton>
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}
