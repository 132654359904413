import { Typography, Button, styled } from '@mui/material'
import theme from 'theme'

export const IItems = styled(Typography)`
  display: flex;
  align-items: center;
  height: 24px;
  justify-content: space-between;
`
export const Placeholder = styled('div')`
  height: 64px;
  ${theme.breakpoints.down('md')} {
    height: 20px;
  }
`

export const BuleButton = styled(Button)`
  background: linear-gradient(90deg, #3a63f2 0%, #3ca5ff 100%);
`

export const YellowButton = styled(Button)`
  background: linear-gradient(178deg, #cdfe00 0%, #fde600 100%);
  color: #000000;
`

export const GreyButton = styled(Button)`
  background: #474747;
  color: rgba(255, 255, 255, 0.6);
`

export const YellowLineButton = styled(Button)`
  background: rgba(212, 250, 0, 0.15);
  color: #d4fa00;
  &:hover {
    background: rgba(212, 250, 0, 0.15);
    opacity: 0.8;
  }
`

export const BuleLineButton = styled(Button)`
  background: transparent;
  color: #3a63f2;
  border: 1px solid #2a64fb;
  padding: 5px 16px;
`
