import { exchangeAddress } from 'constants/nft'
import { isPrd } from 'utils'

const exchangeDomain = {
  name: 'PopFun Exchange Contract',
  version: '2.4',
  chainId: isPrd ? 56 : 97,
  verifyingContract: exchangeAddress,
}

const types = {
  Order: [
    { name: 'exchange', type: 'address' },
    { name: 'maker', type: 'address' },
    { name: 'side', type: 'uint8' },
    { name: 'saleKind', type: 'uint8' },
    { name: 'howToCall', type: 'uint8' },
    { name: 'target', type: 'address' },
    { name: 'calldatas', type: 'bytes' },
    { name: 'replacementPattern', type: 'bytes' },
    { name: 'paymentToken', type: 'address' },
    { name: 'basePrice', type: 'uint256' },
    { name: 'extra', type: 'uint256' },
    { name: 'listingTime', type: 'uint256' },
    { name: 'expirationTime', type: 'uint256' },
    { name: 'salt', type: 'uint256' },
    { name: 'nonce', type: 'uint256' },
    { name: 'makerFee', type: 'uint256' },
  ],
}
async function signEip712(signer, order) {
  return await signer._signTypedData(exchangeDomain, types, order)
}

async function parseSig(bytes) {
  bytes = bytes.substr(2)
  const r = '0x' + bytes.slice(0, 64)
  const s = '0x' + bytes.slice(64, 128)
  const v = parseInt('0x' + bytes.slice(128, 130), 16)
  return { v, r, s }
}

const openBoxtypes = {
  Open: [
    { name: 'tokenId', type: 'uint256' },
    { name: 'receiveAddress', type: 'address' },
  ],
}
async function signEip712OpenBox(signer, order, contract) {
  const openDomain = {
    name: 'open box',
    version: '1.0',
    chainId: isPrd ? 56 : 97,
    verifyingContract: contract,
  }

  return await signer._signTypedData(openDomain, openBoxtypes, order)
}

export { types, signEip712, parseSig, signEip712OpenBox }
