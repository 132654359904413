import { useParams } from 'react-router-dom'
import { Box, Typography, LinearProgress, linearProgressClasses, Divider, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'
import { igoInfoType, useIgoBaseInfo, useIgoInfo } from 'hooks/useIGO'
import { formatAmount } from 'utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Countdown from 'react-countdown'

// import { product } from './product'
import Pay from './Pay'
import About from './About'
import Claim from './Claim'

dayjs.extend(utc)

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 11,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `${theme.palette.grey[200]}`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: `${colors.barFourthBackground}`,
  },
}))

const Left = styled(Box)`
  width: 452px;
  box-shadow: 8px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 33px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  ${theme.breakpoints.down('md')} {
    box-shadow: none;
    width: 100%;
    padding: 14px 1rem 0;
    align-items: flex-start;
    /* background-color: ${theme.palette.common.white}; */
    border-radius: 0 0 12px 12px;
  }
`
const Right = styled(Box)`
  flex: 1;
  margin-left: 60px;
  ${theme.breakpoints.down('md')} {
    /* background-color: ${theme.palette.common.white}; */
    border-radius: 12px 12px 0 0;
    margin-left: 0;
    margin-top: 16px;
    padding: 18px 1rem;
  }
`
const ProductHead = styled(Box)`
  text-align: center;
  ${theme.breakpoints.down('md')} {
    display: flex;
    line-height: 42px;
    align-items: center;
    h1 {
      margin: 0 10px;
    }
  }
  img {
    width: 375px;
    height: 375px;
    border-radius: 12px;
    ${theme.breakpoints.down('md')} {
      width: 42px;
      height: 42px;
      border-radius: 6px;
    }
  }
`
const RulesTitle = styled(Typography)`
  margin-bottom: 20px;
  color: ${colors.fontColor};
  font-weight: 500;
  line-height: 1.5;
  font-size: 1.25rem;
`
const Rules = styled(Typography)`
  margin-bottom: 18px;
  color: ${colors.fontColor3};
  font-weight: normal;
  line-height: 1.2;
  font-size: 0.875rem;
`
const TimerWrapper = styled('div')`
  display: flex;
  margin-left: 10px;
`

const TimerItems = styled('div')`
  width: 49px;
  height: 54px;
  background: ${theme.palette.primary.main};
  border-radius: 4px;
  text-align: center;
  margin-right: 5px;
  display: flex;
  &:last-child {
    margin-right: 0;
  }
  h2 {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 54px;
    flex: 1;
    p {
      font-size: 14px;
      display: inline;
    }
  }
`

const Product = ({ info }: { info: igoInfoType | undefined }) => {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return (
    <ProductHead>
      <img src={info?.headFigure} alt="" />
      <Typography color={'#fff'} mt={matches ? 0 : '16px'} lineHeight={1} fontSize={'2.5rem'} variant="h1">
        {/* {info?.name} */}
        {info?.title}
      </Typography>
      <Typography
        fontWeight={'400'}
        mt={matches ? 0 : '12px'}
        lineHeight={1}
        color={colors.fontColor3}
        fontSize={matches ? '1rem' : '1.5rem'}
        variant="body2"
      >
        {info?.destCurrency.name} / {info?.sourceCurrency.name}
      </Typography>
    </ProductHead>
  )
}

const renderer = ({ days, hours, minutes, seconds }: any) => {
  return (
    <TimerWrapper>
      <TimerItems>
        <h2>
          {days}
          <p>d</p>
        </h2>
      </TimerItems>
      <TimerItems>
        <h2>
          {hours}
          <p>h</p>
        </h2>
      </TimerItems>
      <TimerItems>
        <h2>
          {minutes}
          <p>m</p>
        </h2>
      </TimerItems>
      <TimerItems>
        <h2>
          {seconds}
          <p>s</p>
        </h2>
      </TimerItems>
    </TimerWrapper>
  )
}

export default function IgoDetails() {
  const { id } = useParams()
  const igoInfo = useIgoInfo(id)
  const { info, doFetchBaseInfo } = useIgoBaseInfo(igoInfo)
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return (
    // <Container maxWidth="lg">
    <Box maxWidth={'lg'} m={'0 auto'} display={matches ? 'block' : 'flex'} justifyContent={'space-between'}>
      <Left>
        <Product info={info} />
        {info?.status !== 'over' ? <Pay info={info} reFetch={doFetchBaseInfo} /> : <Claim info={info} />}
        {id !== '1' && (
          <>
            {' '}
            <Typography
              width={'100%'}
              textAlign={'center'}
              mt={matches ? '14px' : '32px'}
              lineHeight={1}
              fontSize={'1.5rem'}
              variant="h1"
            >
              1 {info?.sourceCurrency.name} =&nbsp;
              {(Number(info?.totalDestCurrencyAmount) / Number(info?.totalSourceCurrencyAmount)).toFixed(4)}
              &nbsp;{info?.destCurrency.name}
            </Typography>
            <Typography
              width={'100%'}
              textAlign={'center'}
              mt={'10px'}
              color={colors.fontColor3}
              fontWeight={500}
              lineHeight={1}
              fontSize={'1rem'}
              variant="body2"
            >
              1 {info?.destCurrency.name} =&nbsp;
              {(Number(info?.totalSourceCurrencyAmount) / Number(info?.totalDestCurrencyAmount)).toFixed(4)}
              &nbsp;{info?.sourceCurrency.name}
            </Typography>
            <Box textAlign={'left'} width={matches ? '100%' : '380px'} mt={matches ? '28px' : '36px'}>
              <Typography
                mt={'10px'}
                mb={'10px'}
                color={colors.fontColor2}
                fontWeight={500}
                lineHeight={1}
                fontSize={matches ? '1rem' : '0.875rem'}
                variant="body2"
              >
                Subscription progress:&nbsp;
                {((Number(info?.totalPurchasedSourceCurrency) / Number(info?.totalSourceCurrencyAmount)) * 100).toFixed(
                  3
                )}
                %
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={
                  info
                    ? (Number(info?.totalPurchasedSourceCurrency) / Number(info?.totalSourceCurrencyAmount)) * 100 > 100
                      ? 100
                      : (Number(info?.totalPurchasedSourceCurrency) / Number(info?.totalSourceCurrencyAmount)) * 100
                    : 0
                }
              />
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography
                  mt={'10px'}
                  mb={'10px'}
                  color={colors.fontColor2}
                  fontWeight={500}
                  lineHeight={1}
                  fontSize={matches ? '1rem' : '0.875rem'}
                  variant="body2"
                >
                  {formatAmount(Number(info?.totalPurchasedSourceCurrency))} {info?.sourceCurrency.name}
                </Typography>
                <Typography
                  mt={'10px'}
                  mb={'10px'}
                  color={colors.fontColor2}
                  fontWeight={500}
                  lineHeight={1}
                  fontSize={matches ? '1rem' : '0.875rem'}
                  variant="body2"
                >
                  {formatAmount(
                    (Number(info?.totalPurchasedSourceCurrency) / Number(info?.totalSourceCurrencyAmount)) *
                      Number(info?.totalDestCurrencyAmount)
                  )}
                  &nbsp;/ {formatAmount(Number(info?.totalDestCurrencyAmount))} {info?.destCurrency.name}
                </Typography>
              </Box>
            </Box>
            <Box mt={'30px'} textAlign={'left'} width={matches ? '100%' : '380px'}>
              <Typography
                color={theme.palette.common.white}
                fontWeight={500}
                lineHeight={1}
                fontSize={'1.5rem'}
                variant="body2"
              >
                {info?.status === 'notStarted' && (
                  <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                    Will open in
                    <Countdown
                      date={Date.now() + (Number(info.startTime) - dayjs().unix()) * 1000}
                      renderer={renderer}
                    />
                  </Box>
                )}
                {info?.status === 'inprogress' && (
                  <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                    Will close in
                    <Countdown date={Date.now() + (Number(info.endTime) - dayjs().unix()) * 1000} renderer={renderer} />
                  </Box>
                )}
                {info?.status === 'over' && (
                  <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                    Was finished at {dayjs.utc(Number(info.endTime) * 1000).format('MMM D, YYYY, HH:mm')} UTC. Thank you
                    for participation!
                  </Box>
                )}
              </Typography>
              <Box mt={'30px'} mb={'28px'}>
                <Divider />
              </Box>
              <RulesTitle>Rules:</RulesTitle>
              <Rules variant="body2">
                1. Subscription time: from {dayjs.utc(Number(info?.startTime) * 1000).format('MMM D, YYYY, HH:mm')}
                &nbsp;to {dayjs.utc(Number(info?.endTime) * 1000).format('MMM D, YYYY, HH:mm')} UTC
              </Rules>
              <Rules variant="body2">
                2. Subscribe limit: {Number(info?.minSubAmount)}-{Number(info?.maxSubAmount)} USDT for each qualified
                address, the exceeded part will be returned after subscription
              </Rules>
              <Rules variant="body2">
                3. Coins will be distributed proportionally if the subscription amount exceeds the total supply Final
                {info?.id === '3' && (
                  <p>
                    Token Amount = Individual Subscription Value(USDT) / Total Subscription Value(USDT) * Total Token
                    Amount
                  </p>
                )}
              </Rules>
              <Rules variant="body2">4. Claim will open after subscription</Rules>
            </Box>
          </>
        )}
      </Left>
      <Right>{id && igoInfo && <About data={igoInfo} />}</Right>
    </Box>
    // </Container>
  )
}
