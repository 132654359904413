import { Box, styled, Typography } from '@mui/material'
import AutoImg from 'pages/Record/components/AutoImg'
import theme from 'theme'
import { useLang } from 'state/language/hooks'
const TrendBox = styled(Box)`
  display: flex;
  border-radius: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  &::after {
    content: '';
    width: 31.5%;
  }
  a {
    width: 31.5%;
    border-radius: 16px;
    cursor: pointer;
    background: #191919;
    position: relative;
    margin-bottom: 40px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .boximg {
    margin-top: -40px;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.breakpoints.down('md')} {
      margin-top: -36px;
    }
    .img {
      border-radius: 65px;
      border: 4px solid #191919;
      width: 80px;
      height: 80px;

      ${theme.breakpoints.down('md')} {
        width: 72px;
        height: 72px;
      }
    }
  }

  .badge {
    position: absolute;
    width: 60px;
    height: 24px;
    top: 10px;
    right: 10px;
    border-radius: 4px;
    ${theme.breakpoints.down('md')} {
      width: 54px;
      height: 22px;
    }
  }
  .title {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    margin-top: 7px;
    color: #fff;
    text-align: center;
    ${theme.breakpoints.down('md')} {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      margin-top: 10px;
    }
  }
  .subTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #cecece;
    margin: 10px 15px 20px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    ${theme.breakpoints.down('md')} {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      margin: 10px 15px 25px;
    }
  }
`
export default function Games({ list, source = 'index' }: { list: GameList; source?: string | undefined }) {
  const lang = useLang()
  return (
    <TrendBox>
      {list &&
        list.record &&
        list.record.map((item) => {
          return (
            <a key={item.id} href={`/${lang}/games/${item.id}`}>
              <AutoImg src={item.banner} height={'55%'} style={{ borderRadius: '16px 16px 0 0' }} />
              {item.icon && (
                <Box className="boximg">
                  <img className="img" src={item.icon} alt="" />
                </Box>
              )}
              {item.badge && <img className="badge" src={item.badge} alt="" />}
              <Typography className="title">{item.name}</Typography>
              <Typography className="subTitle">{item.desc}</Typography>
            </a>
          )
        })}
    </TrendBox>
  )
}
