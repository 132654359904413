import { Container } from '@mui/material'
import InfoMenu from './components/InfoMenu'
import Card from './components/Card'

export default function Information() {
  return (
    <Container maxWidth="lg">
      <InfoMenu />
      <Card type={'news'} />
    </Container>
  )
}
