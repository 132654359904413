import { get } from 'utils/fetch'

// const mock = (data: any, success?: boolean, message?: string, time?: number) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve({
//         success: true,
//         message: '',
//         code: 200,
//         data,
//       })
//     }, time || 500)
//   })
// }

export const api = {
  // 获取nft列表
  getNftList(params: NftReqParams) {
    // return mock({
    //   current: 1,
    //   totalPage: 21,
    //   record: Array.from(Array(21)).map((_, index) => {
    //     return {
    //       id: String(Math.random()).slice(-7),
    //       imageUrl: 'https://popfun.s3.ap-southeast-1.amazonaws.com/51647834595_.pic.jpg',
    //       nftName: '名称名称名称名称名称名称名称名名称名称名称名称名称名称名称名',
    //       nftType: params.nftType === 0 ? (index % 2 ? 1 : 2) : params.nftType,
    //       ownerAddress: '0xsdlfjsdlkfjsdalfjsadlfjsdlfjds',
    //       price: index % 2 ? '23223.3243423423421' : '0',
    //       priceToken: 'bit',
    //       tokenIcon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    //     }
    //   }),
    // })

    return get('/user/nft/list', { params })
  },
  // 获取ino列表
  getInoList(params: Params) {
    // return mock({
    //   current: 1,
    //   totalPage: 20,
    //   record: Array.from(Array(11)).map((_, index) => {
    //     return {
    //       id: String(Math.random()).slice(-7),
    //       inoTitle: ' title',
    //       inoImageUrl: 'https://popfun.s3.ap-southeast-1.amazonaws.com/51647834595_.pic.jpg',
    //       productName: '名称名称名称名称名称名称名称名',
    //       total: 10,
    //       txTime: Date.now(),
    //     }
    //   }),
    // })

    return get('/user/ino/list', { params })
  },
  // 获取购买列表
  getBuyList(params: Params) {
    // return mock({
    //   current: params.page || 1,
    //   totalPage: 20,
    //   record: Array.from(Array(11)).map((_, index) => {
    //     return {
    //       id: String(Math.random()).slice(-7),
    //       imageUrl: 'https://popfun.s3.ap-southeast-1.amazonaws.com/51647834595_.pic.jpg',
    //       nftName: '名称名称名称名称名称名称名称名',
    //       nftType: index % 2 ? 1 : 2,
    //       hash: '0xsdlfjsdlkfjsdalfjsadlfjsdlfjds',
    //       blockTime: Date.now(),
    //       price: '2342.322222241',
    //       priceToken: 'bit',
    //     }
    //   }),
    // })

    return get('/user/nft/buy/list', { params })
  },
  // 获取购买列表
  getSellList(params: Params) {
    // return mock({
    //   current: 1,
    //   totalPage: 20,
    //   record: Array.from(Array(6)).map((_, index) => {
    //     return {
    //       id: String(Math.random()).slice(-7),
    //       imageUrl: 'https://popfun.s3.ap-southeast-1.amazonaws.com/51647834595_.pic.jpg',
    //       nftName: '名称名称名称名称名称名称名称名',
    //       nftType: index % 2 ? 1 : 2,
    //       hash: params.state === 2 ? '0xsdlfjsdlkfjsdalfjsadlfjsdlfjds' : '',
    //       state: index % 4,
    //       blockTime: Date.now(),
    //       price: '234231.3241',
    //       priceToken: 'bit',
    //     }
    //   }),
    // })

    return get('/user/nft/sell/list', { params })
  },
}
