import Modal from 'components/Modal'
import BuyNftPic from 'assets/images/png/buy_nft_over.png'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BuleButton } from 'styleds'

export default function Modals({ open, onClose }: { open: boolean; onClose: (e: object, reason: string) => void }) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { t } = useTranslation()

  return (
    <Modal isTitle={true} onClose={onClose} isOpen={open}>
      <Box p={isMobile ? '0 20px 40px' : '0 100px 40px'} display="flex" flexDirection="column" alignItems="center">
        <img width="150px" src={BuyNftPic} alt="" />
        <Typography fontSize="32px" lineHeight="32px" fontWeight={800}>
          {t('Succeed')}
        </Typography>
        <Typography fontSize="16px" lineHeight={isMobile ? '20px' : '50px'}>
          {t('*A delay might be caused by the traffic congestion on chain.')}
        </Typography>
        <BuleButton sx={{ width: '280px' }} onClick={() => onClose({}, 'escapeKeyDown')}>
          {t('CHECK YOUR NFT')}
        </BuleButton>
      </Box>
    </Modal>
  )
}
