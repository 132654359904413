import { Box, useMediaQuery } from '@mui/material'
interface Props {
  type: string
  list: { lable: string; name: string }[]
  click: (value: string) => void
  width?: string
  height?: string
}

export default function Buttons({ list, type, click, width, height }: Props) {
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  return (
    <Box
      component="ul"
      sx={{
        listStyle: 'none',
        display: 'flex',
        justifyContent: type === 'purchase' || type === 'sale' ? '' : isM ? 'space-around' : '',
      }}
    >
      {list.map((item) => {
        return (
          <Box
            onClick={() => {
              click(item.lable)
            }}
            className={type === item.lable ? 'active' : ''}
            component="li"
            key={item.lable}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid rgba(255, 255, 255, 0.4)',
              borderRadius: '6px',
              fontWeight: 500,
              fontSize: '14px',
              height: height || '40px',
              // width: width || '60px',
              padding: '0 20px',
              textTransform: 'uppercase',
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
              userSelect: 'none',

              '&.active, &:hover': {
                backgroundColor: '#061235',
                borderColor: '#3A63F2',
                color: '#3A63F2',
              },

              '& + &': {
                marginLeft: '20px',
              },
            }}
          >
            {item.name}
          </Box>
        )
      })}
    </Box>
  )
}
