/**
 * @file
 * ino 按钮样式
 */

import { Box, styled } from '@mui/material'
import LoadingIcon from 'components/LoadingIcon'

const ButtonWrap = styled(Box)`
  font-weight: 600;
  border-radius: 10px;
  border: 1px solid #3a63f2;
  background: #000;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #3a63f2;
  line-height: 1;
  &.info {
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    color: rgba(255, 255, 255, 0.45);
  }

  &.primary {
    border: none;
    background-image: linear-gradient(90deg, #3a63f2 0%, #00a1ff 100%);
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }
  svg {
    position: relative;
    margin-right: 5px;
    top: 2px;
  }
`

interface ButtonProps {
  text: string
  type?: 'primary' | 'info' | ''
  width?: string
  height?: string
  fontSize?: string
  padding?: string
  loading?: boolean
  onCilck?: React.MouseEventHandler
}

export default function InoButton(props: ButtonProps) {
  return (
    <ButtonWrap
      onClick={props.onCilck}
      component="button"
      className={props.type}
      sx={{
        padding: props.padding,
        width: props.width || '280px',
        height: props.height || '48px',
        fontSize: props.fontSize || '20px',
      }}
    >
      {props.loading && <LoadingIcon />}
      {props.text}
    </ButtonWrap>
  )
}
