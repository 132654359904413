import React from 'react'
import { Dialog, DialogTitle } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isMobile } from '../../utils/userAgent'
import theme from 'theme'

import ClosePic from 'assets/images/svg/close.svg'

// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CloseIcon = styled('span')`
  display: block;
  background-image: url(${ClosePic});
  background-repeat: no-repeat;
  background-size: cover;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  ${theme.breakpoints.down('md')} {
    right: 10px;
    top: 10px;
  }
`
const DialogBox = styled(Dialog)`
  .MuiPaper-root {
    ${theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`
interface ModalProps {
  isOpen: boolean
  isTitle?: boolean
  title?: string
  initialFocusRef?: React.RefObject<any>
  children?: React.ReactNode
  isIcon?: boolean
  onClose: (e: object, reason: string) => void
}

export default function Modal({
  isTitle = true,
  isOpen,
  title,
  initialFocusRef,
  children,
  isIcon = true,
  onClose,
}: ModalProps) {
  const cancelModal = () => {
    onClose({}, 'escapeKeyDown')
  }

  return (
    <>
      <DialogBox open={isOpen} onClose={onClose}>
        {isTitle && <DialogTitle>{title}</DialogTitle>}
        {isIcon && <CloseIcon onClick={cancelModal} />}
        {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
        {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
        {children}
      </DialogBox>
    </>
  )
}
