import { useState, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { styled, Box, Container, Tabs, Tab, useMediaQuery } from '@mui/material'
import theme from 'theme'
import { useActiveWeb3React } from 'hooks/web3'
import NftPanel from './components/NftPanel'
import InoPanel from './components/InoPanel'
import OrderPanel from './components/OrderPanel'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'
const Layout = styled(Container)`
  min-height: calc(100vh - 305px);
  ${theme.breakpoints.down('md')} {
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - 80px);
  }
`
interface TabsDomProps {
  value: string
  isM: boolean
  tabs: {
    lable: string
    name: string
  }[]

  change: (event: React.SyntheticEvent<Element, Event>, value: any) => void | undefined
}

// const tabs = ['nft', 'ino', 'orders']

const TabsDom = (props: TabsDomProps) => {
  return (
    <Tabs
      className="asdasd"
      value={props.value}
      onChange={props.change}
      sx={{
        margin: props.isM ? '0 0 16px 0 ' : '50px 0 30px',
        '& .MuiTabs-flexContainer': {
          borderBottom: props.isM ? 0 : 1,
          borderColor: 'rgba(255, 255, 255, 0.4)',
        },
      }}
    >
      {props.tabs.map((item) => {
        return (
          <Tab
            label={item.name}
            value={item.lable}
            key={item.lable}
            sx={{
              backgroundColor: 'transparent',
              color: 'rgba(255, 255, 255, 0.6)',
              fontSize: props.isM ? '1.5rem' : '1rem',
              maxWidth: '220px',
              transform: props.isM ? '' : 'translateY(1px)',
              transition: 'all 0.3s ease-in-out',
              textTransform: 'uppercase',
              borderBottom: props.isM ? '1px solid transparent' : '0',
              '&:hover': {
                color: '#fff',
              },

              '&[aria-selected=true]': {
                backgroundImage: props.isM ? '' : 'linear-gradient(90deg, #2A64FB 0%, #00A1FF 100%)',
                borderRadius: '6px 6px 0 0',
                color: '#fff',
                borderColor: '#FDE600',
              },
            }}
          />
        )
      })}
    </Tabs>
  )
}

interface TabPanelProps {
  value: string
  index: string
  children?: React.ReactNode
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props

  return <>{value === index && <>{children}</>}</>
}

export default function Record() {
  const { t } = useTranslation()
  // const tabs = [t('nft'), t('ino'), t('orders')]

  const tabs = [
    { lable: 'nft', name: t('nft') },
    { lable: 'ino', name: t('ino') },
    { lable: 'orders', name: t('orders') },
  ]

  const lang = useLang()
  const [currentTab, setTab] = useState('nft')
  const [searchParams] = useSearchParams()
  const queryType = searchParams.get('type') || ''
  const navigate = useNavigate()
  const { tab } = useParams()
  const { account } = useActiveWeb3React()

  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })

  // 切换tab
  const handleChangeTab = (e: any, value: string): void => {
    // if (!tabs.includes(value)) value = 'nft'
    if (value === currentTab) return

    const path = value === 'orders' ? `/${lang}/record/${value}?type=${queryType}` : `/${lang}/record/${value}`
    setTab(value)
    navigate(path, { replace: true })
  }

  useEffect(() => {
    if (tab) {
      handleChangeTab(null, tab)
    }
  }, [tab]) // eslint-disable-line

  return (
    <Layout>
      <Box
        sx={{
          backgroundColor: isM ? 'rgba(37, 37, 37, 0.5)' : '',
        }}
      >
        {/* <Title component="h2">{t('RECORDS')}</Title> */}

        <TabsDom value={currentTab} tabs={tabs} change={handleChangeTab} isM={isM} />
      </Box>
      <Box style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <TabPanel value={currentTab} index="nft">
          <NftPanel isM={isM} account={account} />
        </TabPanel>
        <TabPanel value={currentTab} index="ino">
          <InoPanel isM={isM} account={account} />
        </TabPanel>
        <TabPanel value={currentTab} index="orders">
          <OrderPanel isM={isM} account={account} />
        </TabPanel>
      </Box>
    </Layout>
  )
}
