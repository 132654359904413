import { Link } from 'react-router-dom'
import { Box, Typography, Button, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme from 'theme'
import { formatAmount } from 'utils'
import Countdown from 'react-countdown'
import { useIgoBaseInfo, useUpcomingIgos } from 'hooks/useIGO'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import UpcomingBg from 'assets/images/png/upcoming.jpeg'
// import UpcomingMobileBg from 'assets/images/png/upcoming_mobile.png'
import UpcomingMobileBg from 'assets/images/png/idle_mobile.png'

import Title from './Title'
import FundedProgress from 'components/FundedProgress'

import { Placeholder } from 'styleds/index'
import { useTranslation, Translation } from 'react-i18next'
import { useLang } from 'state/language/hooks'

dayjs.extend(utc)

const Card = styled(Box)`
  background-image: url(${UpcomingBg});
  padding: 50px 65px 58px;
  min-height: 437px;
  background-size: 100% 100%;
  ${theme.breakpoints.down('md')} {
    padding: 28px 25px;
    background-image: url(${UpcomingMobileBg});
    width: 100%;
    min-height: auto;
  }
`

const TimerWrapper = styled('div')`
  display: flex;
`

const TimerItems = styled('div')`
  padding: 8px 0;
  width: 63px;
  height: 52px;
  background: ${theme.palette.primary.main};
  border-radius: 4px;
  text-align: center;
  margin: 0px 7px;
  ${theme.breakpoints.down('md')} {
    width: 23px;
    height: 32px;
    padding: 5px 0;
    margin: 0px 3px;
  }
  h2 {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    ${theme.breakpoints.down('md')} {
      font-size: 12px;
    }
  }
  p {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    font-size: 12px;
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  }
`

const Completionist = () => <Translation>{(t) => <span>{t('You are good to go!')}</span>}</Translation>

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    // Render a completed state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <Translation>
        {(t) => {
          return (
            <TimerWrapper>
              <TimerItems>
                <h2>{days}</h2>
                <p>{t('Days')}</p>
              </TimerItems>
              <TimerItems>
                <h2>{hours}</h2>
                <p>{t('Hours')}</p>
              </TimerItems>
              <TimerItems>
                <h2>{minutes}</h2>
                <p>{t('Minutes')}</p>
              </TimerItems>
              <TimerItems>
                <h2>{seconds}</h2>
                <p>{t('Seconds')}</p>
              </TimerItems>
            </TimerWrapper>
          )
        }}
      </Translation>
    )
  }
}
export default function Upcomings() {
  const { t } = useTranslation()
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const upcomingIgos = useUpcomingIgos()
  const igo = upcomingIgos && upcomingIgos[0]
  const { info } = useIgoBaseInfo(igo)
  const lang = useLang()
  return info ? (
    <div>
      <Title text={t('On going')} />
      <Card>
        <Typography
          lineHeight={matches ? '32.5px' : '73px'}
          color={theme.palette.common.white}
          fontSize={matches ? '2.083rem' : '3.5rem'}
          variant="subtitle1"
        >
          {info?.name}
        </Typography>
        <Typography
          color={theme.palette.common.white}
          fontWeight={500}
          fontSize={matches ? '1rem' : '1.25rem'}
          mt={matches ? '2px' : '21px'}
          variant="body2"
        >
          {t('Total raise')}: {formatAmount(Number(info?.totalSourceCurrencyAmount))} {info?.sourceCurrency.name}
        </Typography>
        <Box maxWidth={'615px'} justifyContent={'flex-start'} mt={'15px'} display={'flex'}>
          <Typography mr={'20px'} fontWeight={500} color={theme.palette.common.white} variant="body1">
            {t('Initial valuation')}: {formatAmount(Number(info?.totalDestCurrencyAmount))}
          </Typography>
          <Typography fontWeight={500} color={theme.palette.common.white} variant="body1">
            {t('Initial token price')}: $
            {Number(info?.totalSourceCurrencyAmount) / Number(info?.totalDestCurrencyAmount)}
          </Typography>
        </Box>
        <Box maxWidth={'615px'} mt={'33px'} mb={'8px'}>
          <FundedProgress
            value={
              info ? (Number(info.totalPurchasedSourceCurrency) / Number(info.totalSourceCurrencyAmount)) * 100 : 0
            }
            height={19}
          />
        </Box>
        <Box maxWidth={'615px'} display={'flex'} justifyContent={'space-between'}>
          <Typography fontWeight={500} color={theme.palette.common.white} variant="body1">
            {formatAmount(Number(info?.totalPurchasedSourceCurrency))} {info?.sourceCurrency.name}
          </Typography>
          <Typography fontWeight={500} color={theme.palette.common.white} variant="body1">
            {formatAmount(
              (Number(info?.totalPurchasedSourceCurrency) / Number(info?.totalSourceCurrencyAmount)) *
                Number(info?.totalDestCurrencyAmount)
            )}{' '}
            / {formatAmount(Number(info?.totalDestCurrencyAmount))} {info?.destCurrency.name}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          alignItems={'center'}
          mt={'40px'}
          justifyContent={matches ? 'space-between' : 'flex-start'}
        >
          <Box>
            <Link to={`/${lang}/igo/3`}>
              <Button variant="contained">{t('Participate')}</Button>
            </Link>
            {/* <ResearchBtn variant="contained">Research page</ResearchBtn> */}
          </Box>
          <Box alignItems={'center'} display={'flex'} ml={matches ? '0' : '400px'}>
            <Typography color={theme.palette.common.white} fontWeight={500} fontSize={'1.25rem'} variant="body2">
              {info?.status === 'notStarted' && t('Starts in:')}
              {info?.status === 'inprogress' && t('Closes in:')}
            </Typography>
            {info?.status === 'notStarted' && (
              <Countdown date={Date.now() + (Number(info.startTime) - dayjs().unix()) * 1000} renderer={renderer} />
            )}
            {info?.status === 'inprogress' && (
              <Countdown date={Date.now() + (Number(info.endTime) - dayjs().unix()) * 1000} renderer={renderer} />
            )}
          </Box>
        </Box>
      </Card>
      <Placeholder />
    </div>
  ) : (
    <></>
  )
}
