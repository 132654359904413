// import { useNavigate } from 'react-router-dom'
import Title from './Title'
import { Grid, useMediaQuery } from '@mui/material'
import NextIgoCards from './NextIgoCards'
import NextIgoSingleCard from './NextIgoSingleCard'
import { useNextIgos } from 'hooks/useIGO'
import { useTranslation } from 'react-i18next'

export default function Next() {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const nextIgos = useNextIgos()
  return (
    <div>
      {nextIgos && nextIgos?.length > 0 && <Title text={t('Next IGOs')} />}
      <Grid container spacing={matches ? 1.1 : 4.5} columns={{ md: 12, xs: 6 }}>
        {nextIgos &&
          nextIgos.map((i, k) => {
            return nextIgos.length <= 1 ? (
              <Grid key={i.id} item xs={12}>
                <NextIgoSingleCard
                  key={`${k}nextigo`}
                  img={i.img}
                  title={i.title}
                  name={i.name}
                  tr={i.tr}
                  contact={i.contact}
                  time={i.time}
                />
              </Grid>
            ) : (
              <Grid key={i.id} item xs={3}>
                <NextIgoCards
                  key={`${k}nextigo`}
                  img={i.img}
                  title={i.title}
                  name={i.name}
                  tr={i.tr}
                  contact={i.contact}
                  time={i.time}
                />
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}
