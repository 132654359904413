import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import application from './application/reducer'
import price from './price/reducer'
import language from './language/reduce'

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    price,
    application,
    language,
  },
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
