import { useRef } from 'react'
import { Typography, Box, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'

import TwitterLogo from 'assets/images/svg/twitter.svg'
import TelegramLogo from 'assets/images/svg/telegram.svg'
import DiscordLogo from 'assets/images/svg/discord.svg'
import LanguageLogo from 'assets/images/svg/language.svg'

import Video from 'components/Video'

const Contact = styled(Box)`
  display: flex;
  align-items: center;
  a {
    margin-right: 20px;
  }
`

export default function About({ data }: any) {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const playerRef = useRef(null)
  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: data.videoUrl,
        type: 'video/mp4',
      },
    ],
  }

  const handlePlayerReady = (player: any) => {
    playerRef.current = player

    // you can handle player events here
    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('dispose', () => {
      console.log('player will dispose')
    })
  }
  return (
    <>
      <Typography mb={'10px'} lineHeight={1.5} fontSize={matches ? '1.66667rem' : '2.25rem'} fontWeight={600}>
        About Project
      </Typography>
      <Typography mb={'10px'} lineHeight={1.5} fontSize={'1rem'} variant="body2">
        {data.desc}
      </Typography>
      <Contact mb={'48px'}>
        <a href={data.twitterUrl}>
          <img src={TwitterLogo} alt="" />
        </a>
        <a href={data.telegramUrl}>
          <img src={TelegramLogo} alt="" />
        </a>
        <a href={data.discordUrl}>
          <img src={DiscordLogo} alt="" />
        </a>
        <a href={data.languageUrl}>
          <img src={LanguageLogo} alt="" />
        </a>
      </Contact>
      <Video options={videoJsOptions} onReady={handlePlayerReady} />
      {data.item.map((i: any, k: number) => {
        return (
          <Box key={`about-${k}`}>
            <Typography mt={'32px'} lineHeight={1} fontSize={'1.5rem'} variant="h1">
              {i.title}
            </Typography>
            <Typography mt={'10px'} lineHeight={1.7} fontSize={'1rem'} variant="body2">
              {i.desc}
            </Typography>
            {i.img && (
              <Box mt={'40px'}>
                <img width={'100%'} src={i.img} alt="" />
              </Box>
            )}
            {i.subTitle && (
              <Typography mt={'20px'} lineHeight={1.7} fontWeight={600} fontSize={'1.5rem'} variant="body2">
                {i.subTitle}
              </Typography>
            )}
            {i.quarter && (
              <Box>
                <Typography
                  mt={'20px'}
                  mb={'20px'}
                  lineHeight={1.7}
                  fontWeight={600}
                  fontSize={'1.5rem'}
                  variant="body2"
                >
                  {i.quarter.name}
                </Typography>
                {i.quarter.list.map((il: any, kl: number) => {
                  return (
                    <Typography key={`quarter-${kl}`} lineHeight={1.7} fontSize={'1rem'} variant="body2">
                      <b>·</b> {il.text}
                    </Typography>
                  )
                })}
              </Box>
            )}
            {i.subDesc && (
              <Typography mt={'20px'} lineHeight={1.7} fontSize={'1rem'} variant="body2">
                {i.subDesc}
              </Typography>
            )}
          </Box>
        )
      })}
    </>
  )
}
