import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Button, Divider } from '@mui/material'
import theme from 'theme'
import { useTranslation } from 'react-i18next'

const OptionWrapper = styled(Box)`
  padding: 0 56px;
  ${theme.breakpoints.down('md')} {
    padding: 0 40px;
  }
`
const OptionCard = styled(Box)`
  color: ${theme.palette.common.white};
  height: 100px;
  align-items: center;
  cursor: pointer;
  ${theme.breakpoints.down('md')} {
    height: 50px;
  }
  img {
    width: 44px;
    height: 44px;
    margin-right: 16px;
    ${theme.breakpoints.down('md')} {
      width: 30px;
      height: 30px;
    }
  }
`

export default function Option({
  link = null,
  onClickEvt,
  header,
  icon,
}: {
  link?: string | null
  onClickEvt?: () => void
  header: React.ReactNode
  icon: string
}) {
  const { t } = useTranslation()
  const content = (
    <OptionWrapper onClick={onClickEvt}>
      <OptionCard display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'}>
          <img src={icon} alt={'Icon'} />
          <Typography flex="1" variant="h4">
            {header}
          </Typography>
        </Box>
        <Button size="medium">{t('Connect')}</Button>
      </OptionCard>
      {header !== 'WalletConnect' && <Divider />}
    </OptionWrapper>
  )
  if (link) {
    return <a href={link}>{content}</a>
  }

  return content
}
