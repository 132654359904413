import { useEffect, useState, useCallback } from 'react'
import { postFetch } from 'utils'
import { BigNumber } from '@ethersproject/bignumber'

export function useNFTList(
  page = 1,
  pageSize = 10,
  nftName: string,
  nftType = 0,
  sort = 1,
  sortName = 1
): { list: List; loading: boolean } {
  const [list, setList] = useState<List>({
    current: 1,
    totalPage: 0,
    record: [],
  })
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    async function fetchNFTList() {
      setLoading(true)
      const result = await fetch(
        `${process.env.REACT_APP_INO_API_URL}/nft/list?page=${page}&pageSize=${pageSize}&nftName=${nftName}&nftType=${nftType}&sort=${sort}&sortName=${sortName}`
      )
        .then((res) => res.json())
        .then((res) => {
          return res.data
        })
      if (page === 1) {
        setList(result)
      } else {
        setList((res) => {
          res.record = [...res.record, ...result.record]
          return res
        })
      }
      setLoading(() => {
        return false
      })
    }
    fetchNFTList()
  }, [page, pageSize, nftName, nftType, sort, sortName])
  return { list, loading }
}

export interface NFTDetail {
  id: number
  contractAddress: string
  openBoxContract: string
  tokenId: string
  nftName: string
  nftComment: string
  contractType: number
  nftType: number
  imageUrl: string
  ownerAddress: string
  creator: string
  attributes: string
  infoId: string
  price: string
  fee: string
  bonusImage: string
  // priceToken: string
  state: number
  priceToken: {
    id: number
    precision: number
    token: string
    address: string
    icon: string
  }
}

export function useNFTDetail(id: string | number | undefined): { detail: NFTDetail | undefined; reFetch: () => void } {
  const [detail, setDetail] = useState<NFTDetail>()
  const [ir, setIr] = useState<boolean>(false)
  const fetchNFTDetail = useCallback(async () => {
    if (id) {
      const result = await fetch(`${process.env.REACT_APP_INO_API_URL}/nft/detail?id=${id}`)
        .then((res) => res.json())
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          console.log(err)
        })
      setDetail(result)
    }
  }, [id])
  useEffect(() => {
    if (!ir) {
      fetchNFTDetail()
    }
  }, [id, fetchNFTDetail, ir])
  const reFetch = useCallback(() => {
    setIr(true)
    fetchNFTDetail()
  }, [fetchNFTDetail])
  return { detail, reFetch }
}

export interface NFTSellParams {
  nftId: number
  userAddress: string
  exchangeAddress: string
  makerAddress: string
  side: number
  saleKind: number
  howToCall: number
  targetAddress: string
  calldatas: string | null | undefined
  replacementPatterns: string
  paymentToken: string
  basePrice: BigNumber | string
  extra: string
  listingTime: BigNumber | string
  expirationTime: BigNumber | string
  salt: BigNumber | string
  signature: string
  nonce: number
  fee: number
}

export async function nftSell(id: number, order: any, signature: string) {
  const params = {
    nftId: id,
    userAddress: order.maker,
    exchangeAddress: order.exchange,
    makerAddress: order.maker,
    side: order.side,
    saleKind: order.saleKind,
    howToCall: order.howToCall,
    targetAddress: order.target,
    calldatas: order.calldatas,
    replacementPatterns: order.replacementPattern,
    paymentToken: order.paymentToken,
    basePrice: order.basePrice.toString(),
    extra: order.extra,
    listingTime: order.listingTime.toString(),
    expirationTime: order.expirationTime.toString(),
    salt: order.salt.toString(),
    signature: signature.toLowerCase(),
    nonce: order.nonce.toNumber(),
    fee: order.makerFee,
  }
  const result = await postFetch(`${process.env.REACT_APP_INO_API_URL}/nft/sell`, params)
  return result
}

export async function nftBuy(id: number) {
  const result = await fetch(`${process.env.REACT_APP_INO_API_URL}/nft/buy?nftId=${id}`)
    .then((res) => res.json())
    .then((res) => {
      return res
    })
  return result
}

export async function getSalt() {
  const result = await fetch(`${process.env.REACT_APP_INO_API_URL}/system/random`)
    .then((res) => res.json())
    .then((res) => {
      return res.data
    })
  return result
}

export async function openBox(nftId: number, userAddress: string, signature: string) {
  const result = await postFetch(`${process.env.REACT_APP_INO_API_URL}/nft/open/box`, {
    nftId,
    userAddress,
    signature,
  })
  return result
}

export async function nftList(
  page = 1,
  pageSize = 10,
  nftName: string,
  nftType = 0,
  sort = 1,
  sortName = 1
): Promise<List> {
  const result = await fetch(
    `${process.env.REACT_APP_INO_API_URL}/nft/list?page=${page}&pageSize=${pageSize}&nftName=${nftName}&nftType=${nftType}&sort=${sort}&sortName=${sortName}`
  )
    .then((res) => res.json())
    .then((res) => {
      return res.data
    })
  return result
}

export async function tockenList() {
  const result = await fetch(`${process.env.REACT_APP_INO_API_URL}/nft/sell/tokens`)
    .then((res) => res.json())
    .then((res) => {
      return res.data
    })
  return result
}
