import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'
import { useLocation } from 'react-router'

const MenuBox = styled(Box)`
  width: 100%;
  height: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  .item {
    height: 48px;
    font-weight: 700;
    font-size: 16px;
    line-height: 48px;
    transition: color 0.3s ease-in-out;
    margin: 0 20px;
    cursor: pointer;
  }
  .active {
    position: relative;
    color: #2a64fb;
    &::before {
      position: absolute;
      width: 100%;
      bottom: 0;
      content: '';
      height: 2px;
      background: rgb(42, 100, 251);
    }
  }
`

export default function InfoMenu() {
  const { t } = useTranslation()
  const lang = useLang()
  const location = useLocation()
  const list = [
    {
      name: t('Information'),
      id: 1,
      path: `/${lang}/information/`,
    },
    {
      name: t('Strategy'),
      id: 2,
      path: `/${lang}/strategy/`,
    },
    {
      name: t('Learn'),
      id: 13,
      path: `/${lang}/learn/`,
    },
  ]
  return (
    <MenuBox>
      {list.map((item) => {
        return (
          <a href={item.path} className={`item ${item.path === location.pathname ? 'active' : ''}`} key={item.id}>
            {item.name}
          </a>
        )
      })}
    </MenuBox>
  )
}
