import { Link } from 'react-router-dom'
import { Box, styled, Typography, useMediaQuery } from '@mui/material'
import { shortAddress } from 'utils'
import AutoImg from '../Record/components/AutoImg'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'

import theme from 'theme'

const Wrapper = styled(Link)`
  /* background: #191919; */
  background: linear-gradient(135deg, #34322f 0%, #100f0d 100%);
  display: block;
  border-radius: 16px;
  ${theme.breakpoints.down('md')} {
    border-radius: 8px;
  }
  > p {
    ${theme.breakpoints.down('md')} {
      padding: 0 10px;
    }
  }
  > div:first-of-type {
    background: linear-gradient(135deg, #34322f 0%, #100f0d 100%);
    border-radius: 16px 16px 0 0;
    ${theme.breakpoints.down('md')} {
      border-radius: 8px 8px 0 0;
    }
  }
`

const Title = styled(Box)`
  /* display: flex; */
  /* margin: 20px 0 0px; */
  padding: 20px;
  > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    ${theme.breakpoints.down('md')} {
      margin-bottom: 15px;
      margin-right: 0;
      font-size: 12px;
      line-height: 21px;
    }
  }
  > div {
    display: flex;
    img {
      margin-right: 10px;
    }
    ${theme.breakpoints.down('md')} {
      p {
        font-size: 16px;
        font-weight: 900;
      }
    }
  }
  ${theme.breakpoints.down('md')} {
    display: block;
    padding: 10px 10px 20px 10px;
  }
`

const Owner = styled(Box)`
  display: flex;
  > p {
    margin-left: 10px;
  }
`
const Price = styled(Box)`
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  ${theme.breakpoints.down('md')} {
    justify-content: flex-start;
    margin-top: 10px;
  }
`

interface NftProps {
  data: {
    imageUrl: string
    nftName?: string
    tokenIcon?: string
    price: string
    ownerAddress: string
    id: number
    priceToken: string
  }
}

export default function Card({ data }: NftProps) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const lang = useLang()

  return (
    <Wrapper to={`/${lang}/nft/${data.id}`}>
      <AutoImg src={data.imageUrl} alt={data.nftName} style={{ borderRadius: '16px' }} />
      <Title>
        <Typography color={'#fff'} fontWeight={600} fontSize={'0.875rem'}>
          {data.nftName}
        </Typography>
        {!isMobile && (
          <Owner color={'#fff'} fontWeight={600} fontSize={'0.875rem'}>
            {t('Owner')}
            <Typography color={'rgba(255, 255, 255, 0.6)'} overflow={'hidden'} fontWeight={600} fontSize={'0.875rem'}>
              {shortAddress(data.ownerAddress)}
            </Typography>
          </Owner>
        )}
        <Price>
          <img width="20px" height="20px" src={data.tokenIcon} alt="" />
          <Typography color={'#fff'} fontWeight={600} fontSize={'1.25rem'}>
            {data.price} {data.priceToken.toLocaleUpperCase()}
          </Typography>
        </Price>
      </Title>
    </Wrapper>
  )
}
