import { Box, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import AutoImg from 'pages/Record/components/AutoImg'
import { fromNow } from 'utils/date'
import theme from 'theme'
import Spinner from 'components/Spinner'
import info from './data'

interface List {
  author: object
  categories: null
  created_at: string
  difficulty: null
  img: string
  link: string
  reading_time: null
  source: string
  summary: string
  title: string
  type: string
  updated_at: string
  _key: string
}
const InfoBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
  ${theme.breakpoints.down('md')} {
    margin-top: 15px;
  }
  .item {
    width: 32%;
    margin: 30px 0;
    ${theme.breakpoints.down('md')} {
      width: 100%;
      margin: 15px 0;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease-in-out;
    }
    .tiem {
      min-height: 28px;
      line-height: 28px;
      color: #999;
      font-size: 16px;
      font-weight: 700;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .dsc {
      font-weight: 700;
      color: #fff;
      font-size: 22px;
      line-height: 28px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
    }
  }
`
export default function Card({ type }: { type: string }) {
  const [list, setList] = useState<List[]>()

  useEffect(() => {
    if (type === 'news') {
      setList(info.data1.result)
    } else if (type === 'strategy') {
      setList(info.data2.result)
    } else {
      setList(info.data3.result)
    }
  }, [type])
  return list ? (
    <InfoBox>
      {list.map((item) => {
        return (
          <a className="item" key={item._key} href={item.link} target={'_blank'} rel="noreferrer">
            <AutoImg src={item.img} alt={item._key} height={'56%'} style={{ borderRadius: '16px' }} />
            <Typography className="tiem"> {item.updated_at ? fromNow(item.updated_at) : ''}</Typography>
            <Typography className="dsc"> {item.title}</Typography>
          </a>
        )
      })}
    </InfoBox>
  ) : (
    <Box minHeight={'calc(100vh - 305px)'} textAlign="center" pt="100px">
      <Spinner />
    </Box>
  )
}
