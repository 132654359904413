import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import theme from 'theme'
import Logo from 'assets/images/svg/logo_white.svg'
// import LogoPic from 'assets/images/png/logo.png'
import SnapchatLogo from 'assets/images/svg/snapchat_logo.svg'
// import FacebookLogo from 'assets/images/svg/facebook_logo.svg'
import TwitterLogo from 'assets/images/svg/twitter-white.svg'

const FooterBox = styled('footer')`
  background-color: #12141c;
  height: 120px;
  display: flex;
  align-items: center;
  margin-top: 65px;
  font-size: 0.75rem;
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    ${theme.breakpoints.down('md')} {
      display: block;
      text-align: center;
      > div {
        margin: 10px 0;
      }
      p {
        margin: 0;
        margin-left: 40px;
      }
    }
    img {
      margin-left: 40px;
    }
  }
`

export default function Footer() {
  return (
    <FooterBox>
      <div>
        <img width={153} height={44} src={Logo} alt="" />
        <Box justifyContent={'center'} flex={1} display={'flex'} alignItems={'flex-start'}>
          {/* <a href="https://www.instagram.com/accounts/login/?next=/popfungamefi/">
            <img width="20px" height="20px" src={InstagramLogo} alt="instagram_logo" />
          </a> */}
          <a href="https://twitter.com/PopFunGameFi">
            <img width="20px" height="20px" src={TwitterLogo} alt="twitter_logo" />
          </a>
          <a href="https://www.facebook.com/PopFun-GameFi-108403608443442">
            <img width="20px" height="20px" src={SnapchatLogo} alt="FacebookLogo" />
          </a>
          {/* <img width="20px" height="20px" src={SnapchatLogo} alt="snapchat_logo" />
          <a href="https://www.facebook.com/PopFun-GameFi-108403608443442">
            <img width="20px" height="20px" src={FacebookLogo} alt="FacebookLogo" />
          </a> */}
        </Box>
        <Typography mr={'40px'} fontWeight={500} color={theme.palette.common.white}>
          ©2022 PopFun
        </Typography>
      </div>
    </FooterBox>
  )
}
