import { Box, styled, Typography } from '@mui/material'
import theme from 'theme'
import AutoImg from 'pages/Record/components/AutoImg'

const TrendBox = styled(Box)`
  display: flex;
  border-radius: 16px;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  a {
    width: 31%;
    border-radius: 16px;
    cursor: pointer;
    background: #191919;
    padding-bottom: 35px;
    ${theme.breakpoints.down('md')} {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  img {
    border-radius: 16px;
    height: 260px;
    ${theme.breakpoints.down('md')} {
      height: 100%;
    }
  }
  .title {
    font-weight: 700;
    margin-top: 23px;
    padding: 0 20px;
    font-size: 24px;
    line-height: 33px;
    text-align: left;
    color: #fff;
  }
  .subTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    padding: 0 20px;
    min-height: 50px;
    color: #999;
  }
`

interface List {
  link: string
  img: string
  title: string
  subTitle: string
  raise: string
  time: string
}
interface props {
  list: List[]
}
export default function Trending(data: props) {
  return (
    <TrendBox>
      {data.list.map((item) => {
        return (
          <a key={item.title} href={item.link}>
            <AutoImg src={item.img} height={'72%'} style={{ borderRadius: '16px' }} />
            {/* <img src={item.img} alt="" width={'100%'} /> */}
            <Typography className="title">{item.title}</Typography>
            <Typography className="subTitle">{item.subTitle}</Typography>
          </a>
        )
      })}
    </TrendBox>
  )
}
