/**
 * @file
 * ino 首页列表卡片
 */
import { Link } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import theme from 'theme'
import InoCountdown from './InoCountdown'
import InoButton from './InoButton'
import { formatUTCTime } from 'utils/date'
import hotImg from 'assets/images/ino/hot.svg'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'

const CardWrapper = styled(Box)`
  display: flex;
  margin-bottom: 50px;
  ${theme.breakpoints.down('md')} {
    margin-bottom: 25px;
  }
  .img-box {
    width: 480px;
    height: 340px;
    border-radius: 16px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 50px;
    align-self: center;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hot {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .ino-info {
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: space-between;

    h4 {
      color: #fff;
      font-weight: 800;
      font-size: 28px;
      line-height: 38px;
      ${theme.breakpoints.down('md')} {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .ino-desc {
      margin-top: 10px;
      /* height: 40px; */
      opacity: 0.8;
      font-weight: 600;
      font-size: 0.7rem;
      color: #fff;
      line-height: 20px;
    }

    .link-about {
      line-height: 20px;
      font-weight: 600;
      font-size: 0.7rem;
      color: #fff;
      cursor: pointer;
    }
    .time {
      margin-top: 10px;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
    }
  }

  ${theme.breakpoints.down('md')} {
    flex-flow: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 30px;

    .img-box {
      width: 100%;
      margin-right: 0;
      /* padding-bottom: 70.85%; */
      position: relative;
      height: auto;
      .hot {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .ino-info {
      h4 {
        text-align: center;
        margin-top: 25px;
      }

      .ino-desc {
        margin-top: 20px;
      }
      .link-about {
        margin-bottom: 20px;
      }
      .time {
        margin-top: 8px;
        font-size: 14px;
      }
    }
  }
`

interface KeyMap {
  [key: string]: any
  [key: number]: any
}

// 按钮点击
// const handleButtonClick = (e: any) => {
//   e.preventDefault()
//   console.log(1211)
// }

interface Props {
  data: {
    id: number
    imageUrl: string
    inoTitle: string
    inoComment: string
    projectComment: string
    startTime: number
    endTime: number
    state: number
  }
  isM?: boolean
  onComplete?: () => void
}

// 描述
const DescDom = ({ data, isM }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <p className="ino-desc"> {data.inoComment}</p>
      {data.projectComment && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: isM ? 'flex-Starts' : 'flex-end',
            marginTop: '8px',
          }}
        >
          <span
            className="link-about"
            onClick={(e) => {
              e.preventDefault()
              window.open(data.projectComment)
            }}
          >
            &gt; {t('About Project')}
          </span>
        </Box>
      )}
    </>
  )
}

// 活动时间
const ActivityTime = ({ data, isM }: Props) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        fontWeight: '600',
        fontSize: '1rem',
        color: '#fff',
        lineHeight: '1',
        textAlign: isM ? 'center' : 'left',
      }}
    >
      <Box component="p" marginTop={isM ? '25px' : '6px'} color=" rgba(255, 255, 255, 0.8);">
        {t('ACTIVITY TIME')}
      </Box>
      <Box component="p" className="time">
        {formatUTCTime(data.startTime, 'YYYY-MM-DD HH:mm')} UTC - {formatUTCTime(data.endTime, 'YYYY-MM-DD HH:mm')} UTC
      </Box>
    </Box>
  )
}

export default function Card({ data, isM, onComplete }: Props) {
  const lang = useLang()
  const { t } = useTranslation()
  const btnMap: KeyMap = {
    0: t('COMING SOON'),
    1: t('JOIN NOW'),
    2: t('MORE DETAILS'),
  }
  return (
    <Box>
      <CardWrapper>
        <Box className="img-box">
          <img className="img" src={data.imageUrl} alt={data.inoTitle} />
          {data.state === 1 && <img className="hot" src={hotImg} alt=""></img>}
        </Box>
        <Box className="ino-info">
          <h4>{data.inoTitle}</h4>
          {isM ? (
            <>
              {/* 项目结束不展示 */}
              {data.state === 2 ? null : <InoCountdown data={data} isM={isM} onComplete={onComplete} page="index" />}
              <ActivityTime data={data} isM={isM} />
              <DescDom data={data} isM={isM} />
            </>
          ) : (
            <>
              <DescDom data={data} isM={isM} />
              <ActivityTime data={data} isM={isM} />

              {/* 项目结束不展示 */}
              {data.state === 2 ? null : (
                <>
                  <Box
                    sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      marginTop: '30px',
                    }}
                  >
                    {data.state === 0 ? t('SUBSCRIPTION STARTS') : data.state === 1 ? t('End in') : ''}
                  </Box>
                  <InoCountdown data={data} isM={isM} page="index" onComplete={onComplete} />
                </>
              )}
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: isM ? 'center' : 'flex-start',
              flex: '1',
              // paddingTop: '25px',
              textAlign: isM ? 'center' : 'left',
            }}
          >
            <Link to={`/${lang}/ino/${data.id}`}>
              <InoButton
                text={btnMap[data.state]}
                width={isM ? 'auto' : ''}
                height={isM ? 'auto' : ''}
                fontSize="1.2rem"
                padding={isM ? '15px 42px' : ''}
                type={data.state === 2 ? 'info' : data.state === 1 ? 'primary' : ''}
              />
            </Link>
          </Box>
        </Box>
      </CardWrapper>
    </Box>
  )
}
