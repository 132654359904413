import { Box, Typography, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'
import { useTranslation } from 'react-i18next'

import CardBg from 'assets/images/png/idle_bg.png'
import CardBgMobileBg from 'assets/images/png/idle_mobile.png'
import TwitterLogo from 'assets/images/svg/twitter-white.svg'
import TelegramLogo from 'assets/images/svg/telegram-white.svg'
import DiscordLogo from 'assets/images/svg/discord-white.svg'
import LanguageLogo from 'assets/images/svg/language-white.svg'

const Card = styled(Box)`
  text-align: center;
  background-image: url(${CardBg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 300px;
  display: flex;
  padding: 60px 71px;
  justify-content: space-between;
  border-radius: 20px;
  ${theme.breakpoints.down('md')} {
    padding: 28px 25px;
    background-image: url(${CardBgMobileBg});
    width: 100%;
    height: auto;
  }
  > div {
    text-align: left;
    > a {
      margin-right: 20px;
    }
  }
`

interface NextIgoCardsProps {
  img: string
  title: string
  name: string
  tr: string
  contact: {
    twitter: string
    telegram: string
    discord: string
    vector: string
  }
  time: string
}

export default function NextIgoCards(props: NextIgoCardsProps) {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [t] = useTranslation()
  return (
    <Card>
      <Box>
        <Typography
          lineHeight={matches ? '32.5px' : '73px'}
          color={theme.palette.common.white}
          fontSize={matches ? '2.083rem' : '3.5rem'}
          variant="subtitle1"
        >
          {props?.name}
        </Typography>
        <Typography
          justifyContent={'flex-start'}
          fontSize={matches ? '1rem' : '1.25rem'}
          color={theme.palette.common.white}
          display={'flex'}
          alignItems={'center'}
          variant="body2"
          mt={matches ? '2px' : '21px'}
        >
          {t('Total Raise')}: &nbsp;
          <Typography color={theme.palette.common.white} fontSize={'1.25rem'} fontWeight={500}>
            {props.tr}
          </Typography>
        </Typography>
        <Typography
          mt={matches ? '38px' : '50px'}
          color={colors.yellow}
          variant="body2"
          fontWeight={600}
          fontSize={matches ? '1.16666666667rem' : '1.75rem'}
        >
          Time: {props.time}
        </Typography>
      </Box>
      <Box
        mt={matches ? '100px' : '155px'}
        mr={matches ? '0' : '10px'}
        justifyContent={'center'}
        display={'flex'}
        alignItems={'center'}
      >
        <a href={props.contact.twitter} target="_blank" rel="noreferrer">
          <img src={TwitterLogo} alt="" />
        </a>
        <a href={props.contact.telegram} target="_blank" rel="noreferrer">
          <img src={TelegramLogo} alt="" />
        </a>
        <a href={props.contact.vector} target="_blank" rel="noreferrer">
          <img src={LanguageLogo} alt="" />
        </a>
        <a href={props.contact.discord} target="_blank" rel="noreferrer">
          <img src={DiscordLogo} alt="" />
        </a>
      </Box>
    </Card>
  )
}
