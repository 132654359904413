import cookie from 'react-cookies'

const Fetch = (url: string, options: any) => {
  url = `${process.env.REACT_APP_INO_API_URL}${url}`

  return new Promise((resolve) => {
    options.headers = {
      'Accept-Language': cookie.load('i18next'),
    }
    fetch(url, options)
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        resolve({
          ...data,
          success: data.code === 200,
        })
      })
      .catch((error) => {
        resolve({ success: false, message: error, data: {} })
      })
  })
}

const get = (url: string, options: any) => {
  if (options.params) {
    url += `?${new URLSearchParams(options.params).toString()}`
    delete options.params
  }

  return Fetch(url, options)
}

const post = (url: string, data: {}, options: object) => {
  return Fetch(url, { ...options, body: JSON.stringify(data) })
}

export { Fetch as fetch, get, post }
