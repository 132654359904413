/**
 * @file
 * 首页和详情页面倒计时
 */
import { styled, Box } from '@mui/material'
import theme from 'theme'
import Countdown from 'react-countdown'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Translation } from 'react-i18next'

dayjs.extend(utc)

const IndexWraper = styled(Box)`
  display: flex;
  list-style: none;
  margin-top: 20px;

  li {
    display: flex;
    align-items: flex-end;

    span {
      font-size: 0.875rem;
      line-height: 1;
    }

    span:first-of-type {
      font-weight: bold;
      display: inline-block;
      font-size: 1.75rem;
      line-height: 1;
      margin-right: 5px;
      min-width: 34px;
      align-self: flex-start;
    }
  }

  li + li {
    margin-left: 30px;
  }

  ${theme.breakpoints.down('md')} {
    justify-content: space-between;
    margin-top: 25px;

    li {
      flex-flow: column;
      align-items: center;
      margin-left: 0 !important;

      span {
        font-weight: 500;
      }

      span:first-of-type {
        margin-right: 0;
        margin-bottom: 15px;
        align-self: center;
        text-align: center;
      }
    }
  }
`

const DetailWraper = styled(Box)`
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;

  li:not(.line) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 6.25rem;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 2%, rgba(255, 255, 255, 0.06) 100%);
    border-radius: 10px;
    border: 1px solid #fff;

    span:not(:first-of-type) {
      font-weight: 500;
      font-size: 0.7rem;
      line-height: 1.167;
    }

    span:first-of-type {
      margin-bottom: 9px;
      font-weight: bold;
      font-size: 1.875rem;
      line-height: 1;
    }
  }

  li.line {
    font-weight: bold;
    margin: 0 1.675rem;
    font-size: 2.651rem;
  }

  ${theme.breakpoints.down('md')} {
    li.line {
      margin: 0 6px;
    }
  }
`

interface Props {
  data?: {
    id: number
    startTime: number
    endTime: number
    state: number
  }
  isM?: boolean
  page: 'index' | 'detail'
  onComplete?: () => void
}

const renderer = (page: string, { days, hours, minutes, seconds }: any) => {
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <li>
              <span>{days}</span>
              <span>{t('DAYS')}</span>
            </li>
            {page === 'detail' ? <li className="line">:</li> : null}
            <li>
              <span>{hours}</span>
              <span>{t('HOURS')}</span>
            </li>
            {page === 'detail' ? <li className="line">:</li> : null}
            <li>
              <span>{minutes}</span>
              <span>{t('MINS')}</span>
            </li>
            {page === 'detail' ? <li className="line">:</li> : null}
            <li>
              <span>{seconds}</span>
              <span>{t('SECS')}</span>
            </li>
          </>
        )
      }}
    </Translation>
  )
}

export default function InoCountdown(props: Props) {
  const date =
    props.data?.state === 0
      ? Date.now() + (Number(props.data?.startTime) - dayjs().unix() * 1000)
      : props.data?.state === 1
      ? Date.now() + (Number(props.data?.endTime) - dayjs().unix() * 1000)
      : 0
  return props.page === 'index' ? (
    <IndexWraper>
      <Countdown date={date} onComplete={props.onComplete} renderer={(cp) => renderer(props.page, cp)} />
    </IndexWraper>
  ) : (
    <DetailWraper>
      <Countdown date={date} onComplete={props.onComplete} renderer={(cp) => renderer(props.page, cp)} />
    </DetailWraper>
  )
}
