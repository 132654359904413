import { useEffect, useState } from 'react'
import { Dialog, DialogTitle, styled, Box, useMediaQuery } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import theme from 'theme'
import LoadingIcon from 'components/LoadingIcon'
import { cancelOrder } from 'pages/Nft/hooks'
import Message from 'components/Message'
import { useTranslation } from 'react-i18next'

const Tag = styled('span')`
  background-image: linear-gradient(180deg, #fde600 0%, #cdfe00 100%);
  border-radius: 6px;
  padding: 7px 6px;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
  flex-shrink: 0;
  white-space: nowrap;
  align-self: flex-start;
  position: absolute;
  right: 20px;
  top: 20px;

  ${theme.breakpoints.down('md')} {
    margin-left: 0;
    margin-top: 0;
  }
`

const ImgStyle = styled('img')`
  object-fit: cover;
  width: 300px;
  height: 300px;
  border-radius: 24px;
`

const ButtonWrap = styled(Box)`
  button {
    border-radius: 10px;
    font-size: 1.25rem;
    line-height: 1;
    font-weight: 500;
    width: 50%;
    height: 48px;
    outline: none;
    border: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;

    &.info {
      background: rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.6);
      margin-left: 20px;
    }

    &.primary {
      background-image: linear-gradient(90deg, #2a64fb 0%, #00a1ff 100%);
      border-radius: 10px;
      color: #fff;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`

const Loading = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  z-index: 2;
`

interface Props {
  open: boolean
  data:
    | {
        id: number
        nftNme: string
        nftType: 1 | 2
        imageUrl: string
      }
    | any
  onClose: () => void
  onSuccess: () => void
}

export default function CustomDialog(props: Props) {
  const { t } = useTranslation()
  const { data, open, onClose, onSuccess } = props
  const [submiting, setSubmiting] = useState(false)
  const [success, setSuccess] = useState(false)
  const { account, library } = useWeb3React()
  const [message, setMessage] = useState<{ show: boolean; text: string }>({ show: false, text: '' })
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })

  const onSubmit = () => {
    setSubmiting(true)

    cancelOrder(data.id, library)
      .then((res: any) => {
        setSubmiting(false)

        if (res.success) {
          setSuccess(true)
        } else {
          // nft不存在和不在售卖中
          console.log(res)
          if ([3002, 3004].includes(res.code)) {
            setMessage({ show: true, text: res.message })
            setTimeout(() => {
              onClose()
            }, 1000)
          }
          setMessage({ show: true, text: res.message })
          onClose()
        }
      })
      .catch((ereor) => {
        console.log(ereor)
        setSubmiting(false)
        setSuccess(true)
        // setMessage({ show: true, text: ereor.data?.message || ereor.message })
      })
  }

  useEffect(() => {
    if (!account) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    if (open) {
      setSubmiting(false)
      setSuccess(false)
      setMessage({ show: false, text: '' })
    }
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <Message
        show={message.show}
        message={message.text}
        onClose={() => {
          setMessage({ show: false, text: '' })
        }}
      />

      {success ? (
        <Box padding={isM ? '40px 20px' : '80px 240px 60px'} display="flex" flexDirection="column" alignItems="center">
          <DialogTitle
            sx={{
              fontSize: '1.75rem',
              lineHeight: '2.75rem',
              wordBreak: 'break-word',
              padding: '0',
            }}
          >
            {t('SUBMITTED')}
          </DialogTitle>
          <Box
            sx={{
              fontWeight: '500',
              fontSize: '1rem',
              color: '#fff',
              lineHeight: '1.75rem',
              textAlign: 'center',
              marginTop: '30px',
              flex: '1',
              minHeight: isM ? '60px' : '120px',
            }}
          >
            {t('Delay may occur on blockchain, please wait for responding.')}
          </Box>
          <ButtonWrap display="flex" width="100%">
            <button type="button" className="primary" onClick={onSuccess} style={{ width: '100%' }}>
              {t('YES')}
            </button>
          </ButtonWrap>
        </Box>
      ) : (
        <Box padding={isM ? '40px 20px' : '40px 30px'} display="flex">
          {!isM && (
            <Box
              sx={{
                position: 'relative',
                marginRight: '20px',
              }}
            >
              <ImgStyle src={data?.imageUrl} alt="" loading="lazy" />
              {data.nftType === 1 ? <Tag>{t('MYSTERY BOX')}</Tag> : null}
            </Box>
          )}

          <Box
            sx={{
              flex: '1',
              display: 'flex',
              flexFlow: 'column',
            }}
          >
            <DialogTitle
              sx={{
                fontSize: '1.75rem',
                lineHeight: '2.75rem',
                wordBreak: 'break-word',
                padding: '0',
                textAlign: isM ? 'center' : 'left',
              }}
            >
              {t('Do you want to cancel this order')}
            </DialogTitle>
            <Box
              sx={{
                fontSize: 'rem',
                lineHeight: '1.75rem',
                color: '#fff',
                minWidth: '23rem',
                fontWeight: '500',
                marginTop: isM ? '30px' : '10px',
                flex: '1',
                minHeight: isM ? '50px' : '100px',
              }}
            >
              {t('A certain amount of gas fee is required to cancel this order.')}
            </Box>
            <ButtonWrap display="flex">
              <button type="button" className="primary" onClick={onClose}>
                {t('NO')}
              </button>
              <button type="button" className="info" disabled={submiting} onClick={onSubmit}>
                {submiting && (
                  <Loading>
                    <LoadingIcon width="100%" height="100%" />
                  </Loading>
                )}
                {t('YES')}
              </button>
            </ButtonWrap>
          </Box>
        </Box>
      )}
    </Dialog>
  )
}
