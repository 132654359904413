import { useState } from 'react'
import {
  useMediaQuery,
  styled,
  Box,
  Typography,
  Container,
  Grid,
  Alert,
  AlertTitle,
  Stack,
  Snackbar,
} from '@mui/material'
import Modals from './components/Modals'
import { useLang } from 'state/language/hooks'

import { useWeb3React } from '@web3-react/core'
import { useParams } from 'react-router-dom'
import theme from 'theme'
import { formatUTCTime } from 'utils/date'
import { useInoDetail, useInoBonusList } from 'hooks/useIno'
import { GreyButton } from 'styleds'

import InoBanner from './components/Banner'
import InoButton from './components/InoButton'
import Dialog from './components/Dialog'
import Spinner from 'components/Spinner'
import { useTranslation } from 'react-i18next'

const Layout = styled(Box)`
  min-height: calc(100vh - 305px);
  ${theme.breakpoints.down('md')} {
    min-height: calc(100vh - 80px);
    padding: 16px;
  }

  .ino-desc {
    padding-top: 1.825rem;
    font-size: 14px;
    line-height: 24px;
    ${theme.breakpoints.down('md')} {
      padding-top: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .link-about {
    font-weight: 500;
  }
`

// 活动时间
const ActiveTime = styled(Box)`
  margin-top: 30px;
  padding: 50px;
  background-image: linear-gradient(180deg, rgba(66, 67, 79, 0.57) 0%, rgba(31, 32, 39, 0.34) 100%);
  border-radius: 16px;
  text-align: center;
  ${theme.breakpoints.down('md')} {
    padding: 25px 30px;
  }
  .time {
    font-size: 1rem;
    font-weight: 500;
    ${theme.breakpoints.down('md')} {
      font-weight: 800;
      font-size: 12px;
    }
  }
`

const Progress = styled(Box)`
  height: 10px;
  border-radius: 10px;
  background-color: #343f53;
  margin-top: 3.4375rem;
  display: flex;
  align-items: center;

  .bar {
    background-color: #0098ff;
    transition: all 0, 3s ease-in-out;
    height: 10px;
    border-radius: 10px;
  }

  .mark {
    background-image: linear-gradient(90deg, #0098ff 0%, #56c1ff 100%);
    border: 3px solid rgba(255, 255, 255, 0.58);
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 50%;
    margin-left: -4px;
    position: relative;
  }
`
const Cards = styled(Box)`
  display: flex;
  background: #191919;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  padding: 20px;
  ${theme.breakpoints.down('md')} {
    padding: 16px;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  > img {
    margin-right: 20px;
  }
  img {
    width: 7.5rem;
    height: 7.5rem;
    object-fit: cover;
    border-radius: 0.875rem;
    margin-right: 1rem;
    ${theme.breakpoints.down('md')} {
      width: 88px;
      height: 88px;
    }
  }

  h4 {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    ${theme.breakpoints.down('md')} {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }

  p.desc {
    opacity: 0.6;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
  }

  p.probability {
    margin-top: 13px;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    ${theme.breakpoints.down('md')} {
      font-size: 12px;
      line-height: 16px;
    }

    span {
      color: #26c48b;
    }
  }
`
const Rewards = styled(Container)`
  margin-top: 25px;
  ${theme.breakpoints.down('md')} {
    margin-top: 0px;
  }

  h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1;
    text-align: center;
  }

  ${theme.breakpoints.down('md')} {
    ul {
      flex-flow: column;

      li + li {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
`

const Tooltip = styled(Box)`
  position: absolute;
  bottom: -100%;
  left: 50%;
  background-color: #343f53;
  border-radius: 6px;
  padding: 3px 8px;
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  transform: translate(-50%, 16px);

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    margin-left: -4px;
    background-color: #343f53;
    top: -4px;
    left: 50%;
    transform: rotate(45deg);
  }

  ${theme.breakpoints.down('md')} {
    transform: translate(-50%, 26px);
  }
`

interface KeyMap {
  [key: string]: any
  [key: number]: any
}

interface Props {
  data: {
    id: number
    inoComment: String
    projectComment: string
  }
  isM: Boolean
}

// 描述
const DescDom = ({ data, isM }: Props) => {
  const { t } = useTranslation()

  return (
    <>
      <p className="ino-desc"> {data.inoComment}</p>
      <Box
        sx={{
          display: 'flex',
          justifyContent: isM ? 'flex-Starts' : 'flex-end',
          marginTop: '8px',
        }}
      >
        {data.projectComment && (
          <a href={data.projectComment} target="_blank" rel="noreferrer" className="link-about">
            &gt; {t('About Project')}
          </a>
        )}
      </Box>
    </>
  )
}

export default function Detail() {
  const { t } = useTranslation()
  const lang = useLang()
  const btnMap: KeyMap = {
    0: t('COMING SOON'),
    1: t('JOIN NOW'),
    2: t('Event Ended'),
  }
  const { account } = useWeb3React()
  const [open, setOpen] = useState(false)

  const [openDialog, setOpenDialog] = useState(false)
  const [openAlert, setOpenAlert] = useState<boolean>(false)
  const [openAlertMsg, setOpenAlertMsg] = useState<string>('')
  const [openAlertType, setOpenAlertType] = useState<'success' | 'info' | 'warning' | 'error'>()
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  const { id } = useParams()
  const { data, reFetch } = useInoDetail(id, account)
  const bonusList = useInoBonusList(id)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const openError = (msg: string, type: 'success' | 'info' | 'warning' | 'error') => {
    setOpenAlertMsg(msg)
    setOpenAlert(true)
    setOpenAlertType(type)
  }
  const onComplete = (): void => {
    reFetch()
  }

  return data ? (
    <Layout>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={openAlertType} sx={{ width: '100%' }}>
            <AlertTitle>
              <Typography fontWeight={'bold'} color={openAlertType === 'success' ? '#4caf50' : 'rgb(255, 69, 105)'}>
                {openAlertType?.toUpperCase()}
              </Typography>
            </AlertTitle>
            <p style={{ fontSize: '14px', fontWeight: 500, marginTop: '-15px' }}>{openAlertMsg}</p>
          </Alert>
        </Snackbar>
      </Stack>
      <InoBanner page="detail" isM={isM} data={data} onComplete={onComplete} />
      <Box
        sx={{
          padding: isM ? '25px 0' : '30px 0',
        }}
      >
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          <DescDom data={data} isM={isM} />
          <ActiveTime>
            <Box component="h3" fontSize="2rem" marginBottom={isM ? '15px' : '1.875rem'} lineHeight="1">
              {t('ACTIVITY TIME')}
            </Box>
            <p className="time">
              {formatUTCTime(data.startTime, 'YYYY-MM-DD HH:mm')} UTC -{' '}
              {formatUTCTime(data.endTime, 'YYYY-MM-DD HH:mm')} UTC
            </p>
            <Progress>
              <div className="bar" style={{ width: `${(data.buyNum / data.destNftNum) * 100}%` }} />
              <span className="mark">
                <Tooltip>
                  {data.buyNum}/{data.destNftNum}
                </Tooltip>
              </span>
            </Progress>
            <Box mt={isM ? '6rem' : '4.0625rem'}>
              {data.buyNum >= data.destNftNum ? (
                <GreyButton
                  sx={{
                    padding: isM ? '15px 42px' : '',
                    width: isM ? 'auto' : '280px',
                    height: isM ? 'auto' : '48px',
                    fontSize: '20px',
                    lineHeight: '1',
                  }}
                >
                  {t('SOLD OUT')}
                </GreyButton>
              ) : (
                <InoButton
                  text={btnMap[data.state]}
                  width={isM ? 'auto' : ''}
                  height={isM ? 'auto' : ''}
                  padding={isM ? '15px 42px' : ''}
                  type={data.state === 2 ? 'info' : data.state === 1 ? 'primary' : ''}
                  onCilck={(e) => {
                    e.preventDefault()
                    if (btnMap[data.state] === t('JOIN NOW')) {
                      setOpenDialog(true)
                    }
                  }}
                />
              )}
            </Box>
          </ActiveTime>

          <Box mt={isM ? '25px' : '3.125rem'}>
            <Typography
              component="h3"
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',
                lineHeight: 1,
                textAlign: 'center',
              }}
            >
              {t('ACTIVITY RULES')}
            </Typography>
            <Box
              sx={{
                marginTop: '1.875rem',
                opacity: '0.6rem',
                fontWeight: 500,
                fontSize: isM ? '14px' : '0.875rem',
                lineHeight: '24px',
                whiteSpace: 'pre-line',
              }}
            >
              {data.rule}
            </Box>
          </Box>
        </Box>
      </Box>

      <Rewards maxWidth="lg">
        <h3>{t('ACTIVITY REWARDS')}</h3>
        <Grid sx={{ marginTop: '.1rem' }} container spacing={3.75} columns={{ md: 12, xs: 4 }}>
          {bonusList?.record.map((item, key) => {
            return (
              <Grid key={`nft-market-${key}`} item xs={4}>
                <Cards>
                  <img src={item.bonusImage} alt="" />
                  <Box>
                    <h4>{item.comment}</h4>
                    {/* <p className="desc">{item.comment}</p> */}
                    <p className="probability">
                      {t('Probability')} <span>{Number(item.rarity) / 100}%</span>
                    </p>
                  </Box>
                </Cards>
              </Grid>
            )
          })}
        </Grid>
        {/* <Box width={isM ? '80%' : '320px'} m={isM ? '10px auto' : '20px auto'}>
          <Button variant="contained" fullWidth color="secondary">
            <Typography color={colors.fontColor} fontWeight={600} fontSize={'0.875rem'}>
              See More
            </Typography>
          </Button>
        </Box> */}
      </Rewards>

      <Modals
        open={open}
        onClose={(e, reson) => {
          setOpen(false)
          if (reson === 'escapeKeyDown') {
            window.location.href = `/${lang}/record`
          }
          reFetch()
        }}
      />

      <Dialog
        data={data}
        open={openDialog}
        onClose={(isTrrue) => {
          // reFetch()
          if (isTrrue) {
            setOpen(true)
          }
          setOpenDialog(false)
        }}
        callback={(msg, type) => openError(msg, type)}
      ></Dialog>
    </Layout>
  ) : (
    <Box minHeight={'calc(100vh - 305px)'} textAlign="center" pt="100px">
      <Spinner />
    </Box>
  )
}
