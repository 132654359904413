import { useEffect, useState, useCallback } from 'react'
import cookie from 'react-cookies'

export interface InoList {
  id: number
  imageUrl: string
  inoTitle: string
  inoComment: string
  projectComment: string
  startTime: number
  endTime: number
  state: number
}
export function useInoList(state: number, page = 1, pageSize = 10): { list: InoList[] | undefined; loading: boolean } {
  const [list, setList] = useState<InoList[]>()
  const [loading, setLoading] = useState<boolean>(true)
  useEffect(() => {
    async function fetchInoList() {
      const result = await fetch(
        `${process.env.REACT_APP_INO_API_URL}/ino/list?state=${state}&page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            'Accept-Language': cookie.load('i18next'),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          return res.data.record
        })
      setLoading(false)
      setList(result)
    }
    fetchInoList()
  }, [state, page, pageSize])
  return { list, loading }
}

export interface InoDetail {
  id: number
  contractAddress: string
  inoTitle: string
  imageUrl: string
  startTime: number
  endTime: number
  destNftNum: number
  minNum: number
  maxNum: number
  price: string
  state: number
  inoComment: string
  rule: string
  projectComment: string
  bonusImage: string
  buyNum: number
  sourceAmount: number
  type: number
  userBuyNum: number
  imageDetailUrl: string
  token: {
    id: number
    icon: string
    precision: number
    token: string
    address: string
  }
}

export function useInoDetail(
  id: string | undefined,
  userAddress?: string | undefined | null
): { data: InoDetail | undefined; reFetch: () => void } {
  const [data, setData] = useState<InoDetail>()
  const fetchInoDetail = useCallback(async () => {
    if (id) {
      const result = await fetch(
        `${process.env.REACT_APP_INO_API_URL}/ino/detail?id=${id}&userAddress=${userAddress}`,
        {
          headers: {
            'Accept-Language': cookie.load('i18next'),
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          return res.data
        })
      setData(result)
    }
  }, [id, userAddress])
  useEffect(() => {
    fetchInoDetail()
  }, [id, fetchInoDetail, userAddress])
  const reFetch = useCallback(() => {
    fetchInoDetail()
  }, [fetchInoDetail])
  return { data, reFetch }
}

interface BonusList {
  current: number
  totalPage: number
  record: {
    id: number
    name: string
    bonusImage: string
    comment: string
    quantity: number
    rarity: string
  }[]
}

export function useInoBonusList(inoId: string | undefined, page = 1, pageSize = 100): BonusList | undefined {
  const [list, setList] = useState<BonusList>()
  useEffect(() => {
    async function fetchInoBonusList() {
      if (inoId) {
        const result = await fetch(
          `${process.env.REACT_APP_INO_API_URL}/ino/bonus/list?inoId=${inoId}&page=${page}&pageSize=${pageSize}`,
          {
            headers: {
              'Accept-Language': cookie.load('i18next'),
            },
          }
        )
          .then((res) => res.json())
          .then((res) => {
            return res.data
          })
        setList(result)
      }
    }
    fetchInoBonusList()
  }, [inoId, page, pageSize])
  return list
}
