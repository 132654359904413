import { LinearProgress, linearProgressClasses, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'

interface BorderLinearProgressProps {
  height: number
}

const BorderLinearProgress = styled(LinearProgress)((props: BorderLinearProgressProps) => ({
  height: props.height,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `${theme.palette.common.white}`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: `${colors.yellow}`,
  },
}))

const Wrapper = styled(Box)`
  position: relative;
`

const Progress = styled('h4')(({ value }: { value: number }) => ({
  position: 'absolute',
  left: `${value}%`,
  top: 0,
  marginLeft: `${value < 5 ? '0' : '-50px'}`,
  display: value === 0 ? 'none' : 'block',
}))

interface FundedProgressProps {
  value: number
  height?: number
}

const FundedProgress = ({ value, height = 56 }: FundedProgressProps) => {
  return (
    <Wrapper lineHeight={height + 'px'}>
      <BorderLinearProgress height={height} variant="determinate" value={value > 100 ? 100 : value} />
      <Progress value={value > 100 ? 100 : value}>{value.toFixed(0)}%</Progress>
    </Wrapper>
  )
}

export default FundedProgress
