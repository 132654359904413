import { Container, Box, styled, useMediaQuery, Grid, Typography } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroller'
import { useNFTList } from 'hooks/useNFT'
import Empty from '../Record/components/Empty'
import Card from './Card'
import Loading from './components/Loading'
import { useTranslation } from 'react-i18next'

import theme from 'theme'
import { useState } from 'react'
import Filters from './Filters'

const Wrapper = styled(Box)`
  min-height: calc(100vh - 305px);
  ${theme.breakpoints.down('md')} {
    /* min-height: calc(100vh - 80px); */
  }
`

const More = styled(Box)`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    margin-top: 50px;
    ${theme.breakpoints.down('md')} {
      margin-bottom: 70px;
    }
  }
`
const BoxEmpty = styled(Box)`
  position: relative;
  width: 100%;
  height: 500px;
`

export default function Nft() {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [params, setParams] = useState<FileProps>({
    sort: 2,
    sortName: 1,
    nftName: '',
    nftType: 0,
    page: 1,
  })
  const { list, loading } = useNFTList(params.page, 15, params.nftName, params.nftType, params.sort, params.sortName)
  const filterSearch = (par: FileProps): void => {
    setParams(() => {
      return par
    })
  }
  const loadList = () => {
    if (list.totalPage < params.page) {
      return
    }
    setParams((res) => {
      return { ...res, page: params.page + 1 }
    })
  }
  return (
    <Container maxWidth="lg">
      <Wrapper>
        {/* <Box>
          <img width="100%" src={BannerPic} alt="" />
        </Box> */}
        <Box m={isMobile ? '20px 0' : '50px 0'}>
          <Filters filters={filterSearch} />
        </Box>
        <Box>
          <InfiniteScroll
            loadMore={() => loadList()}
            pageStart={0}
            useWindow={true}
            initialLoad={false}
            hasMore={!loading}
          >
            <Box>
              <Grid container spacing={isMobile ? 2 : 3.75} columns={{ md: 12, xs: 6 }}>
                {list.record.map((i, k) => {
                  return (
                    <Grid key={`nft-market-${k}`} item xs={3}>
                      <Card data={i} />
                    </Grid>
                  )
                })}
              </Grid>
              {loading && <Loading />}
              {list.totalPage > 0 && list.totalPage <= params.page && !loading && (
                <More>
                  <Box>
                    <Typography>{t('No More…')}</Typography>
                  </Box>
                </More>
              )}
              {!loading && list.totalPage === 0 && (
                <BoxEmpty>
                  <Empty />
                </BoxEmpty>
              )}
            </Box>
          </InfiniteScroll>
        </Box>
      </Wrapper>
    </Container>
  )
}
