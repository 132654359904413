import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state/hooks'
import { useCallback } from 'react'
import { AppState } from '../index'
import { setLanguage } from './reduce'

export function useLang() {
  return useSelector((state: AppState) => state.language.lang)
}
export function useSetLanguage(lang: string): () => void {
  const dispatch = useAppDispatch()
  return useCallback(() => dispatch(setLanguage(lang)), [lang, dispatch])
}
