import * as abis from 'abis/NFTAbis'
import { getContract } from 'utils'
import { Web3Provider } from '@ethersproject/providers'
import ERC20_ABI from 'abis/erc20.json'

import { parseEther, formatEther } from '@ethersproject/units'
import Web3 from 'web3'

export async function getTokenIdCount(provider: Web3Provider, contractAddress: string | null | undefined) {
  if (contractAddress) {
    try {
      const web3 = new Web3(window.ethereum as any)
      const _contarct = new web3.eth.Contract(abis.mintAbi as any[], contractAddress)
      const count = await _contarct.methods.getTokenIdCount().call()
      if (count) {
        return count
      }
    } catch (error) {
      console.log(error)
    }
  }
}
export async function remainCount(provider: Web3Provider, contractAddress: string | null | undefined) {
  if (contractAddress) {
    const web3 = new Web3(window.ethereum as any)
    const _contarct = new web3.eth.Contract(abis.mintAbi as any[], contractAddress)
    const count = await _contarct.methods.remainCount().call()
    if (count) {
      return count
    }
  }
}
export async function mint(provider: Web3Provider, contractAddress: string | null | undefined) {
  try {
    const contract = contractAddress && getContract(contractAddress, abis.mintAbi, provider)
    const signer = await provider.getSigner()
    return contract && (await contract.connect(signer).mint())
  } catch (error) {
    console.log(error)
  }
}

export async function approve(
  provider: Web3Provider,
  owen: string,
  number: string | number,
  account?: string | null | undefined,
  contractAddress?: string | null | undefined
) {
  if (account) {
    const contract = contractAddress && getContract(owen, ERC20_ABI, provider, account)
    try {
      const signer = provider.getSigner()
      const info =
        contract && (await contract.connect(signer).approve(contractAddress, parseEther(String(number)).toString()))
      return info
    } catch (error) {
      console.log(error)
    }
  }
}
export async function allowance(
  provider: Web3Provider,
  owen: string,
  account?: string | null | undefined,
  contractAddress?: string | null | undefined
) {
  if (account) {
    const contract = contractAddress && getContract(owen, ERC20_ABI, provider, account)
    try {
      const info = contract && (await contract.allowance(account, contractAddress))
      return info
    } catch (error) {
      console.log(error)
    }
  }
}
export async function getUserInfo(
  provider: Web3Provider,
  contractAddress: string | null | undefined,
  account: string | null | undefined
) {
  if (contractAddress && provider && account) {
    try {
      const contract = contractAddress && getContract(contractAddress, abis.mintAbi, provider)
      return contract && (await contract.getUserInfo(account))
    } catch (error) {
      console.log(error)
    }
  }
}
export async function isEnableWhiteList(provider: Web3Provider, contractAddress: string | null | undefined) {
  if (contractAddress && provider) {
    try {
      const contract = contractAddress && getContract(contractAddress, abis.mintAbi, provider)
      return contract && (await contract.isEnableWhiteList())
    } catch (error) {
      console.log(error)
    }
  }
}
export async function isInWhiteList(
  provider: Web3Provider,
  contractAddress: string | null | undefined,
  account: string | null | undefined
) {
  if (contractAddress && provider && account) {
    try {
      const contract = contractAddress && getContract(contractAddress, abis.mintAbi, provider)
      return contract && (await contract.isInWhiteList(account))
    } catch (error) {
      console.log(error)
    }
  }
}
export async function mintApprove(item: FreeMint, library: Web3Provider, account: string | null | undefined) {
  try {
    const isWhite = await isEnableWhiteList(library, item.contractAddress)
    if (isWhite) {
      const inWhite = await isInWhiteList(library, item.contractAddress, account)
      if (!inWhite) {
        return 1
      }
    }
    const selfNumber = await getUserInfo(library, item.contractAddress, account)
    if (selfNumber && selfNumber[1].length > 0) {
      return 2
    }
    const _allowance = await allowance(library, item.priceToken.address, account, item.contractAddress)
    let info = null
    if (Number(formatEther(_allowance)) === 0) {
      info = await approve(library, item.priceToken.address, item.price, account, item.contractAddress)
      await info.wait()
      console.log(info)
      if (info) {
        return await mint(library, item.contractAddress)
      }
      return info
    }
    return await mint(library, item.contractAddress)
  } catch (error) {
    console.log(error)
  }
}
