import Slider from 'react-slick'

import BannerInoImg1 from 'assets/images/home/landpage.jpeg'
import BannerIndexImg from 'assets/images/home/banner.jpg'

import { useLang } from 'state/language/hooks'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { styled, useMediaQuery } from '@mui/material'

const SliderBox = styled(Slider)`
  margin-top: 50px;
  .slick-list {
    border-radius: 18px;
    img {
      border-radius: 18px;
    }
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev {
    left: 15px;
    z-index: 1;
  }
  .slick-dots {
    bottom: 25px;
    text-align: left;
    padding-left: 25px;
    li button:before {
      opacity: 1;
      color: rgba(255, 255, 255, 0.3);
    }
    li.slick-active button:before {
      color: #fff;
      opacity: 1;
      border: 1px solid #fff;
      border-radius: 50%;
    }
  }
`

interface imgList {
  link: string
  img: string
}
export default function Carousel() {
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  const lang = useLang()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnDotsHover: true,
    adaptiveHeight: true,
    focusOnSelect: true,
    arrows: isM ? false : true,
  }
  const list: imgList[] = [
    {
      link: `/${lang}/mint`,
      img: 'https://ino-image.s3.ap-southeast-1.amazonaws.com/popfun/prod/nft/leapcard/HomeTopBanner.jpg',
    },
    {
      link: `/${lang}/mint`,
      img: 'https://ino-image.s3.ap-southeast-1.amazonaws.com/popfun/prod/mint/hero/home_head_banner.jpg',
    },
    {
      link: `/${lang}/ino/7`,
      img: 'https://ino-image.s3.ap-southeast-1.amazonaws.com/popfun/prod/ino/solitaire/info/home_head_banner.png',
    },
    {
      link: 'https://www.bybit.com/en-US/trade/spot/launchdetail/',
      img: BannerInoImg1,
    },
    {
      link: `/${lang}/`,
      img: BannerIndexImg,
    },
  ]
  return (
    <SliderBox {...settings}>
      {list.map((item) => {
        return (
          <a key={item.img} href={item.link}>
            <img src={item.img} alt="" width={'100%'} height={isM ? '156px' : '480px'} />
          </a>
        )
      })}
    </SliderBox>
  )
}
