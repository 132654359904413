import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import theme, { colors } from 'theme'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useEndIgos } from 'hooks/useIGO'
import { useLang } from 'state/language/hooks'

import { useTranslation } from 'react-i18next'

import Title from './Title'

const Cards = styled(Box)(({ size }: { size: string }) => ({
  background: '#191919',
  borderRadius: '10px',
  backdropFilter: 'blur(10px)',
  // background: colors.barSecondBackground,
  height: size === 'medium' ? '264px' : '164px',
  // borderRadius: '12px',
  padding: '12px',
  display: 'flex',
  '@media (max-width: 899.5px)': {
    // background: theme.palette.common.white,
  },
  '> img': {
    borderRadius: '8px',
    marginRight: '12px',
  },
}))

const Multiple = styled('span')`
  width: 53px;
  height: 22px;
  background: ${theme.palette.primary.main};
  border-radius: 4px;
  color: ${theme.palette.common.white};
  padding: 2px 10px;
  margin-left: 8px;
  font-weight: 600;
  font-size: 0.875rem;
`

function EndIgosCards({ size, items, t }: { size: string; items: any; t: any }) {
  return (
    <Cards size={size}>
      <img
        width={size === 'medium' ? '240px' : '140px'}
        height={size === 'medium' ? '240px' : '140px'}
        src={items.headFigure}
        alt=""
      />
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Typography
          fontWeight={500}
          color={colors.fontColor2}
          fontSize={size === 'medium' ? '1rem' : '0.875rem'}
          lineHeight={1}
        >
          {items.title}
          <Multiple>x{items.mul}</Multiple>
        </Typography>
        <Typography mt={'21px'} lineHeight={1} fontWeight={600} fontSize={size === 'medium' ? '1.75rem' : '1.25rem'}>
          {items.name}
        </Typography>
        <Typography
          display={'flex'}
          lineHeight={1}
          color={colors.fontColor2}
          mt={'21px'}
          fontWeight={500}
          fontSize={'1.25rem'}
          alignItems={'center'}
        >
          {t('*Ath')}:&nbsp;
          <Typography color={colors.fontColor} fontWeight={600} fontSize={'1.25rem'}>
            {items.ath}
          </Typography>
        </Typography>
      </Box>
    </Cards>
  )
}

export default function EndIgos() {
  const { t } = useTranslation()
  const lang = useLang()
  const endIgos = useEndIgos()
  const navigate = useNavigate()
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  return (
    <div>
      <Title text={t('End IGOs')} subTitle={matches ? '' : t('*ATH: All Time High / traded on CEX or DEX')} />
      <Box>
        <Grid container spacing={matches ? 1.2 : 4.5} sx={{ mt: 0 }} columns={{ md: 12, xs: 4, mt: 0 }}>
          {endIgos &&
            endIgos.map((i, k) => {
              return (
                <Grid key={k + i.id} item xs={4} onClick={() => navigate(`/${lang}/igo/${i.id}`)}>
                  <EndIgosCards size="small" items={i} t={t} />
                </Grid>
              )
            })}
        </Grid>
        <br />
      </Box>
      {/* <Box width={matches ? '80%' : '320px'} m={matches ? '10px auto' : '50px auto'}>
        <Button variant="contained" fullWidth color="secondary">
          <Typography color={colors.fontColor} fontWeight={600} fontSize={'0.875rem'}>
            {t('See More')}
          </Typography>
        </Button>
      </Box> */}
    </div>
  )
}
