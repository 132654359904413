import { Button, Box } from '@mui/material'
import { ReactChild } from 'react'
import Loading from './Loading'

interface Props {
  children?: ReactChild
  loading?: boolean
  width?: string
  height?: string
  onClick?: () => void
  sx?: {}
}

export default function ButtonMore(props: Props) {
  const { children, loading, width, height, onClick, sx = {} } = props

  return (
    <Button
      onClick={() => {
        onClick && onClick()
      }}
      disabled={loading}
      sx={{
        backgroundImage: 'linear-gradient(90deg, #2A64FB 0%, #00A1FF 100%)',
        borderRadius: '10px',
        width: width || 'auto',
        height: height || 'auto',
        transition: 'all 0.3s ease-in-out',
        cursor: 'pointer',
        ...sx,
      }}
    >
      <Box>{children || 'Load More'}</Box>
      {loading && <Loading button width="1.875rem" height="1.875rem" />}
    </Button>
  )
}
