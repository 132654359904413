import { ReactChild } from 'react'
import { Box } from '@mui/material'
import LoadingIcon from 'components/LoadingIcon'
import { useTranslation } from 'react-i18next'

interface Props {
  children?: ReactChild
  width?: string
  height?: string
  button?: boolean
}

export default function Loading(props: Props) {
  const { t } = useTranslation()
  const { children, width, height, button = false } = props

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
        height: height ? height : '500px',
      }}
    >
      <LoadingIcon width={width ? width : '1.875rem'} height={height ? height : '1.875rem'} />

      {!button && (
        <Box
          sx={{
            fontSize: '0.875',
            color: '#FFFFFF',
            fontWeight: '500',
            lineHeight: '1.1875rem',
            marginLeft: '1rem',
          }}
        >
          {children || t('Loading...')}
        </Box>
      )}
    </Box>
  )
}
