import { useState, useMemo, useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { formatUnits } from '@ethersproject/units'
import ERC20_ABI from 'abis/erc20.json'
import usePrevious from 'hooks/usePrevious'
import { fetchAllowance } from 'constants/erc20'

import { getContract } from 'utils'

export function useTokenBalance(token: string) {
  const [balance, setBalance] = useState<string>()
  const { library, account } = useWeb3React()
  const previousBalance = usePrevious(balance)
  useMemo(() => {
    async function getBalance() {
      if (token !== undefined) {
        if (!library) return setBalance('')
        const _contarct = getContract(token, ERC20_ABI, library)
        const result = await _contarct.balanceOf(account)
        const decimal = await _contarct.decimals()
        if (!previousBalance) {
          const val = formatUnits(result, decimal)
          setBalance(val)
        }
      }
    }
    try {
      getBalance()
    } catch (err) {
      setBalance('0')
    }
  }, [account, previousBalance, token, library])
  return balance
}

export function useTokenDecimal(token: string) {
  const [decimals, setDcimals] = useState<number>()
  const { library } = useWeb3React()
  const _contarct = getContract(token, ERC20_ABI, library)
  const previousDecimal = usePrevious(decimals)
  useMemo(() => {
    async function getDecimal() {
      const result = await _contarct.decimals()
      if (!previousDecimal) {
        setDcimals(result)
      }
    }
    getDecimal()
  }, [_contarct, previousDecimal])
  return decimals
}

export function useAllowance(owner: string | undefined, poolAddr: string | undefined) {
  const [allowance, setAllowance] = useState<string>()
  const { library, account } = useWeb3React()
  const getData = useCallback(async () => {
    if (owner && poolAddr) {
      const result = await fetchAllowance(library, owner, account, poolAddr)
      const val = formatUnits(result)
      setAllowance(val)
    }
  }, [account, library, owner, poolAddr])
  useEffect(() => {
    if (account !== undefined) {
      getData()
    }
  })
  const doReFetch = useCallback(() => {
    getData()
  }, [getData])
  return { allowance, doReFetch }
}
