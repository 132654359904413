import { Box, styled } from '@mui/material'

const TagWrap = styled(Box)`
  background-image: linear-gradient(180deg, #fde600 0%, #cdfe00 100%);
  border-radius: 6px;
  padding: 7px 6px;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  line-height: 14px;
  flex-shrink: 0;
  white-space: nowrap;
  z-inde: 10;
`

interface Props {
  children?: React.ReactNode
  sx?: {}
}

export default function Tag(props: Props) {
  return (
    <TagWrap component="span" sx={{ ...props.sx }}>
      {props.children}
    </TagWrap>
  )
}
