import { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { parseUnits } from '@ethersproject/units'
import { splitSignature } from '@ethersproject/bytes'
import { Box, Typography, useMediaQuery, styled, Button } from '@mui/material'
import { useModalOpen, useBuyNFTModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { NFTDetail, nftBuy } from 'hooks/useNFT'
import { useTokenBalance, useAllowance } from 'hooks/useErc20'
import { approveMax } from 'constants/erc20'
import { transferProxy } from 'constants/nft'
import { fixedFormat } from 'utils'
import theme from 'theme'
import { useTranslation } from 'react-i18next'
import { useLang } from 'state/language/hooks'
import { paymentTokenAddress as PTA } from 'constants/nft'

import { makerBuyOrder, makeSellOrder } from './hooks'

import Modal from 'components/Modal'
import CustomizeInput from 'components/CustomizeInput'
import LoadingIcon from 'components/LoadingIcon'
import { BuleButton } from 'styleds'

import BuyNftPic from 'assets/images/png/buy_nft_over.png'
import SellNftPic from 'assets/images/png/sell_nft_over.png'
import Reading from 'assets/images/png/Reading.gif'

const UsdtPic = 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png'

const ModalContent = styled(Box)`
  display: flex;
  padding: 0 40px 40px;
  width: 780px;
  > img {
    border-radius: 12px;
  }
  ${theme.breakpoints.down('md')} {
    display: block;
    padding: 0 20px 20px;
    width: 100%;
    > img {
      width: 100%;
      height: inherit;
    }
    > div {
      margin-left: 0;
      > p {
        font-size: 20px;
        line-height: 30px;
        > p {
          font-size: 20px;
        }
      }
      img {
        width: 20px;
        height: 20px;
      }
      > div {
        > p {
          margin-bottom: 0;
          > p {
            font-size: 20px;
            > p {
              font-size: 14px;
            }
          }
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
`

export default function Modals({
  type,
  info,
  openModal,
  reFetch,
  openError,
}: {
  type: 'buy' | 'sell' | 'buy-finish' | 'sell-finish' | string | undefined
  info: NFTDetail
  openModal: (type: string) => void
  reFetch: () => void
  openError: (msg: string, type: 'success' | 'info' | 'warning' | 'error') => void
}) {
  const paymentAddress = info?.priceToken?.address
  const { t } = useTranslation()
  const lang = useLang()

  const { account, library } = useWeb3React()

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const walletModalOpen = useModalOpen(ApplicationModal.NFT_MODELS)
  const toggleBuyNftModal = useBuyNFTModalToggle()

  const balance = useTokenBalance(paymentAddress) //info.priceToken.address
  // console.log(balance)
  const { allowance, doReFetch } = useAllowance(paymentAddress, transferProxy)
  const [approveLoading, setApproveLoading] = useState<boolean>(false)
  const [buyLoading, setBuyLoading] = useState<boolean>(false)
  const [sellLoading, setSellLoading] = useState<boolean>(false)
  const [sellPrice, setSellPrice] = useState<string>('')
  const [paymentTokenAddress, setPaymentTokenAddress] = useState<string>(PTA)
  const [icon, setIcon] = useState(UsdtPic)
  const [name, setName] = useState('USDT')

  const approve = async (address: string) => {
    if (approveLoading) return
    setApproveLoading(true)
    try {
      const tx = await approveMax(library, transferProxy, address, account)
      await tx.wait()
      setApproveLoading(false)
      doReFetch()
    } catch (error) {
      setApproveLoading(false)
      openError(error.message || error.data.message, 'error')
      console.error(error)
    }
  }

  const buy = async (id: number) => {
    setBuyLoading(true)
    const res = await nftBuy(id)
    if (res.code !== 200) {
      openError(res.message || res.data.message, 'error')
    } else {
      const detail = res.data
      const order = {
        exchange: detail.exchangeAddress,
        maker: detail.makerAddress,
        side: detail.side,
        saleKind: detail.saleKind,
        howToCall: detail.howToCall,
        target: detail.targetAddress,
        calldatas: detail.calldatas,
        replacementPattern: detail.replacementPatterns,
        paymentToken: detail.paymentToken,
        basePrice: parseUnits(detail.basePrice, 0),
        extra: '0',
        listingTime: parseUnits(detail.listingTime.toString(), 0),
        expirationTime: parseUnits(detail.expirationTime.toString(), 0),
        salt: parseUnits(detail.salt, 0),
        nonce: detail.nonce,
        makerFee: detail.fee,
      }
      // console.log(order)
      const _sellerSignature = splitSignature(detail.signature)
      // console.log(_signature)
      try {
        const buyRes = await makerBuyOrder(
          library,
          paymentAddress,
          order,
          info.tokenId,
          _sellerSignature,
          account,
          info.contractAddress,
          info.price
        )
        await buyRes.wait()
        if (buyRes) {
          openModal('buy-finish')
          reFetch()
          setBuyLoading(false)
        }
      } catch (err) {
        console.log(err)
        setBuyLoading(false)
        openError(err?.data?.message || err?.message, 'error')
      }
    }
  }

  const sell = async () => {
    if (sellLoading) return
    if (sellPrice !== '' && Number(sellPrice) > 0) {
      setSellLoading(true)
      try {
        const sellRes = await makeSellOrder(
          library,
          info,
          account,
          info.contractAddress,
          sellPrice,
          paymentTokenAddress
        )
        if (sellRes.code !== 200) {
          openError(sellRes.message, 'error')
        } else {
          reFetch()
          openModal('sell-finish')
        }
      } catch (err) {
        console.log(err)
        openError(err.data?.message || err.message, 'error')
        setSellLoading(false)
      }
      setSellLoading(false)
    }
  }

  const closeModal = () => {
    window.location.href = `/${lang}/record`
  }
  const onClose = (e: object, reason: string) => {
    if (reason === 'backdropClick') {
      if (type === 'buy-finish' || type === 'sell-finish') {
        reFetch()
      }
    } else {
      //escapeKeyDown
      if (type === 'buy-finish' || type === 'sell-finish') {
        window.location.href = `/${lang}/record`
      }
    }
    toggleBuyNftModal()
  }

  return (
    <Modal
      isTitle={true}
      isIcon={type === 'buy' && !allowance ? false : true}
      isOpen={walletModalOpen}
      onClose={(e, reason) => {
        if (type === 'buy' && !allowance) return
        onClose(e, reason)
      }}
    >
      {type === 'sell' && (
        <ModalContent>
          <img width="300px" height="300px" src={info.imageUrl} alt="" />
          <Box ml="30px" display="flex" flexDirection="column" justifyContent={'space-between'}>
            <Typography fontSize="28px" fontWeight="500" lineHeight="40px">
              {info.nftName}
            </Typography>
            <CustomizeInput
              icon={icon}
              name={name.toLocaleUpperCase()}
              change={(e) => {
                const _value = new RegExp(/^([0-9]{1,}[.]{0,1}[0-9]{0,8})$/)
                if (e.target.value && !_value.test(e.target.value)) {
                  e.preventDefault()
                } else {
                  setSellPrice(e.target.value.replace(/[^\d.]/g, ''))
                }
              }}
              select={(address, name, icon) => {
                setIcon(icon)
                setName(name)
                setPaymentTokenAddress(address)
              }}
              value={sellPrice}
              fullWidth
            />
            {Number(sellPrice) === 0 && (
              <Typography fontSize="14px" fontWeight="500" lineHeight="14px" color="red">
                {t('Please enter correct price')}
              </Typography>
            )}
            <Typography fontSize="14px" fontWeight="500" lineHeight="14px">
              {t('Gas fee will be required to cancel a listed order.')}
            </Typography>
            {Number(sellPrice) > 0 && (
              <Typography fontSize="14px" fontWeight="500" lineHeight="14px" textAlign={'right'}>
                {t('Fee Rate')}
                &nbsp; &nbsp; &nbsp; &nbsp;
                <span style={{ color: '#FAE700' }}> 2.5%</span>
              </Typography>
            )}
            {Number(sellPrice) > 0 && (
              <Typography fontSize="14px" fontWeight="500" lineHeight="14px" textAlign={'right'}>
                {t('Total')}
                &nbsp; &nbsp; &nbsp; &nbsp;
                <span style={{ color: '#FAE700' }}>
                  {parseFloat((Number(sellPrice) * 0.975).toFixed(16))}&nbsp; &nbsp;{name}
                </span>
              </Typography>
            )}
            <BuleButton sx={{ width: '100%' }} onClick={() => sell()}>
              {sellLoading && <LoadingIcon />}&nbsp;{t('SELL')}
            </BuleButton>
          </Box>
        </ModalContent>
      )}
      {type === 'buy' && allowance && (
        <ModalContent>
          <img width="300px" height="300px" src={info.imageUrl} alt="" />
          <Box flex={1} ml="30px" display="flex" flexDirection="column" justifyContent={'space-between'}>
            <Typography fontSize="28px" fontWeight="500" lineHeight="40px">
              {info.nftName}
            </Typography>
            {type === 'buy' && (
              <Box sx={{ width: '100%' }}>
                <Typography
                  mb={'20px'}
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="14px"
                >
                  {t('PAYMENT')}
                  <Typography
                    margin="0 0 0 18px"
                    display="flex"
                    alignItems="center"
                    fontSize="28px"
                    fontWeight="500"
                    lineHeight="28px"
                  >
                    <img width="28px" height="28px" src={info.priceToken?.icon} alt="" />
                    <span style={{ marginLeft: '10px' }}>{info.price}</span>
                    <Typography pt="5px" ml="5px" fontSize="16px" fontWeight="500" lineHeight="30px">
                      {info.priceToken?.token.toLocaleUpperCase()}
                    </Typography>
                  </Typography>
                </Typography>
                <Typography
                  color="rgba(255, 255, 255, 0.5)"
                  display="flex"
                  alignItems="center"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="14px"
                >
                  {t('BALANCE')}
                  <Typography
                    margin="0 0 0 18px"
                    display="flex"
                    alignItems="center"
                    fontSize="20px"
                    fontWeight="500"
                    lineHeight="28px"
                    color="rgba(255, 255, 255, 0.5)"
                  >
                    <img width="28px" height="28px" src={info.priceToken?.icon} alt="" />
                    <span style={{ marginLeft: '10px' }}>{fixedFormat(balance)}</span>
                    <Typography
                      color="rgba(255, 255, 255, 0.5)"
                      pt="3px"
                      ml="5px"
                      fontSize="14x"
                      fontWeight="500"
                      lineHeight="30px"
                    >
                      {info.priceToken?.token.toLocaleUpperCase()}
                    </Typography>
                  </Typography>
                </Typography>
              </Box>
            )}
            <Box>
              {Number(info.price) > Number(balance) && (
                <Typography
                  margin="0 0 0 18px"
                  fontSize="16px"
                  fontWeight="500"
                  textAlign="center"
                  lineHeight="20px"
                  color="#FF2222"
                  mb={'20px'}
                >
                  {t('Insufficient available balance')}
                </Typography>
              )}
              {account && Number(allowance) > 0 && (
                <BuleButton
                  disabled={Number(info.price) > Number(balance) || buyLoading}
                  sx={{ width: '100%' }}
                  onClick={() => buy(info.id)}
                >
                  {buyLoading && <LoadingIcon />}&nbsp;BUY
                </BuleButton>
              )}
              {account && Number(allowance) <= 0 && (
                <Button
                  startIcon={approveLoading ? <LoadingIcon /> : ''}
                  onClick={() => approve(paymentAddress)}
                  variant="contained"
                  color="warning"
                  fullWidth
                >
                  {t('Approve')}
                </Button>
              )}
            </Box>
          </Box>
        </ModalContent>
      )}
      {type === 'buy-finish' && (
        <Box p={isMobile ? '0 20px 40px' : '0 100px 40px'} display="flex" flexDirection="column" alignItems="center">
          <img width="150px" src={BuyNftPic} alt="" />
          <Typography fontSize="32px" lineHeight="32px" fontWeight={800}>
            {t('Succeed')}
          </Typography>
          <Typography fontSize="16px" lineHeight={isMobile ? '20px' : '50px'}>
            {t('*A delay might be caused by the traffic congestion on chain.')}
          </Typography>
          <BuleButton sx={{ width: '280px' }} onClick={() => closeModal()}>
            {t('CHECK YOUR NFT')}
          </BuleButton>
        </Box>
      )}
      {type === 'sell-finish' && (
        <Box p={isMobile ? '0 20px 40px' : '0 200px 40px'} display="flex" flexDirection="column" alignItems="center">
          <img width="200px" src={SellNftPic} alt="" />
          <Typography fontSize="16x" lineHeight="50px">
            {t('Your NFT now is listed for sale')}
          </Typography>
          <BuleButton sx={{ width: '280px' }} onClick={() => closeModal()}>
            {t('CHECK YOUR NFT')}
          </BuleButton>
        </Box>
      )}
      {type === 'buy' && !allowance && (
        <Box p={isMobile ? '0 20px 40px' : '0 200px 40px'} display="flex" flexDirection="column" alignItems="center">
          <img width="120px" src={Reading} alt="" />
          <Typography fontSize="16x" lineHeight="50px">
            {t('Reading on-chain data')}
          </Typography>
        </Box>
      )}
    </Modal>
  )
}
