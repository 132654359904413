import { styled } from '@mui/material'

import { useInoList } from 'hooks/useIno'

import Spinner from 'components/Spinner'
import InoCard from './Card'

const Title = styled('h3')`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1;
`

enum TITLE {
  'NEXT INOS',
  'INO',
  'END INOS',
}

export default function InoList({ isMobile, type }: { isMobile: boolean; type: number }) {
  const { list, loading } = useInoList(type)
  const onComplete = (): void => {
    window.location.reload()
  }
  return loading ? (
    <Spinner />
  ) : (
    <>
      {list && list.length > 0 && (
        <Title
          sx={{
            margin: isMobile ? '25px 0 25px' : '50px 0',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          {TITLE[type]}
        </Title>
      )}

      {list?.map((item) => {
        return <InoCard data={item} key={item.id} isM={isMobile} onComplete={onComplete} />
      })}
    </>
  )
}
