import { Box, Container, styled, Typography, useMediaQuery, Button } from '@mui/material'
import Bannerabout from 'assets/images/home/bannerabout.jpg'
import Ps1 from 'assets/images/home/ps-1.png'
import Ps2 from 'assets/images/home/ps-2.png'
import Ps3 from 'assets/images/home/ps-3.png'

import { useTranslation } from 'react-i18next'
import theme from 'theme'

const AboutBanner = styled(Box)`
  background: url(${Bannerabout}) no-repeat 0 0/100% 288px;
  width: 100%;
  height: 288px;
  font-size: 28px;
  line-height: 36px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-top: 30px;
  ${theme.breakpoints.down('md')} {
    height: 85px;
    background: url(${Bannerabout}) no-repeat 0 0/100% 85px;
    font-size: 16px;
  }
`
const List = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  .title {
    margin: 10px 0;
    padding: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }
  .des {
    font-size: 16px;
    line-height: 26px;
    padding: 10px;
  }
`

export default function About() {
  const { t } = useTranslation()
  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  const list = [
    {
      img: Ps1,
      title: t('The Ultimate Blockchain Gaming Platform'),
      des: t(
        'PopFun is a one-stop shop for gamers of all backgrounds to explore and experience what GameFi has to offer. Trade, design, earn, and play all of the best blockchain games on the same platform!'
      ),
    },
    {
      img: Ps2,
      title: t('NFT Marketplace'),
      des: t('Trade items between games seamlessly with our NFT Marketplace and Trading system.'),
    },
    {
      img: Ps3,
      title: t('POP-OFF Launchpad'),
      des: t(
        'Take your blockchain game to the next level with our POP-OFF Launchpad Services. Marketing, tokenomics, legal, developmental, and financial consulting for GameFi companies at all stages!'
      ),
    },
  ]
  return (
    <Container maxWidth="lg">
      <AboutBanner>{t('One-stop incubation platform for GameFi.')}</AboutBanner>
      <Typography sx={{ marginTop: '37px', fontWeight: 600, fontSize: '36px', lineHeight: '47px' }}>
        {t('About Us')}
      </Typography>
      <Typography sx={{ marginTop: '20px', fontSize: '16px', lineHeight: '30px' }}>
        {t(
          'At PopFun, we are dedicated to producing, curating, and hosting the next generation of blockchain games as well as providing a secure ecosystem for users to discover, play, socialize, and design. PopFun has already completed a $4.88 million angel round of financing which is being used for team development, product research, and platform development.'
        )}
      </Typography>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '36px',
          lineHeight: '47px',
          paddingTop: '48px',
          paddingBottom: '53px',
          textAlign: 'center',
        }}
      >
        {t('Products & Services')}
      </Typography>
      <List>
        {list.map((item) => {
          return (
            <Box key={item.title} sx={{ width: isM ? '100%' : '32%' }}>
              <img src={item.img} alt="" width={80} height={80} />
              <Typography className="title">{item.title}</Typography>
              <Typography className="des">{item.des}</Typography>
            </Box>
          )
        })}
      </List>
      <Typography
        sx={{
          marginTop: isM ? '90px' : '180px',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '23px',
          textAlign: 'center',
        }}
      >
        {t('If you have any questions, please feel free to contact us, and we will reply within 1 to 3 working days.')}
      </Typography>
      <Box sx={{ width: '100%', textAlign: 'center', marginTop: '38px', marginBottom: isM ? '100px' : '0' }}>
        <Button
          sx={{ width: '150px' }}
          href="https://docs.google.com/forms/d/1rzYf5RjFoWR8Z9Yc75igdCAFoQJssVxL_k-XvXyd44E/edit"
          variant="contained"
          target={'_blank'}
        >
          {t('Contact Us')}
        </Button>
      </Box>
    </Container>
  )
}
