import { useEffect, useState, useCallback } from 'react'
import { igosType } from 'constants/config'
import { formatUnits } from '@ethersproject/units'
import { igoCall } from 'utils/mutilcall'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import IGO_ABI from 'abis/igo.json'
import { useActiveWeb3React } from 'hooks/web3'
import { getNetworkLibrary } from 'connectors'

dayjs.extend(utc)

const Provider = getNetworkLibrary()

export interface igoInfoType {
  contract: string
  totalDestCurrencyAmount: string
  totalSourceCurrencyAmount: string
  destCurrency: {
    name: string
    icon: string
    address: string
    decimals: number
  }
  sourceCurrency: {
    name: string
    icon: string
    address: string
    decimals: number
  }
  name: any
  headFigure: string
  startTime: any
  endTime: any
  isWhiteList: any
  maxSubAmount: string
  minSubAmount: string
  totalPurchasedSourceCurrency: string
  purchasedAmount: string
  status: string
  title?: string
  Ath?: string
  mul?: string
  type?: string
  id?: string
}

export function useIgoBaseInfo(igo: igosType | undefined) {
  const { account } = useActiveWeb3React()
  const [info, setInfo] = useState<igoInfoType>()

  const fetchBaseInfo = useCallback(async () => {
    if (Provider && igo) {
      // const calls: {
      //   address: string
      //   name: string
      //   params?: string[]
      // }[] = [
      //   {
      //     address: igo.contract,
      //     name: 'baseInfo',
      //   },
      //   {
      //     address: igo.contract,
      //     name: 'totalPurchasedSourceCurrency',
      //   },
      // ]
      const _result = await igoCall(IGO_ABI, igo.contract, account || '')
      // const _result = await multiCall(IGO_ABI, calls, Provider)
      if (_result !== null) {
        const _info: any = _result[0]
        const _totalPurchasedSourceCurrency: any = _result[1]
        const _igo = {
          id: String(igo.id),
          contract: igo.contract,
          totalDestCurrencyAmount: formatUnits(_info.totalDestCurrencyAmount, igo.destCurrency.decimals),
          totalSourceCurrencyAmount: formatUnits(_info.totalSourceCurrencyAmount, igo.sourceCurrency.decimals),
          destCurrency: igo.destCurrency,
          sourceCurrency: igo.sourceCurrency,
          name: _info.name,
          headFigure: igo.headFigure,
          startTime: _info.startTime.toString(),
          endTime: _info.endTime.toString(),
          isWhiteList: _info.isWhiteList,
          maxSubAmount: formatUnits(_info.maxSubAmount, igo.destCurrency.decimals),
          minSubAmount: formatUnits(_info.minSubAmount, igo.destCurrency.decimals),
          totalPurchasedSourceCurrency: formatUnits(_totalPurchasedSourceCurrency, igo.sourceCurrency.decimals),
          status: '',
          purchasedAmount: '',
          title: igo.title,
        }
        if (account) {
          const _purchasedAmount = _result[2]
          _igo.purchasedAmount = formatUnits(_purchasedAmount, igo.sourceCurrency.decimals)
        }
        if (dayjs().unix() > Number(_info.startTime) && dayjs().unix() < Number(_info.endTime)) {
          _igo.status = 'inprogress'
        } else if (dayjs().unix() < Number(_info.startTime)) {
          _igo.status = 'notStarted'
        } else if (dayjs().unix() > Number(_info.endTime)) {
          _igo.status = 'over'
        }
        setInfo(_igo)
      }
    }
  }, [igo, account])

  useEffect(() => {
    fetchBaseInfo()
  }, [fetchBaseInfo, igo])

  const doFetchBaseInfo = useCallback(() => {
    fetchBaseInfo()
  }, [fetchBaseInfo])

  // console.log(info)

  return {
    info,
    doFetchBaseInfo,
  }
}

export function useUpcomingIgos() {
  const [upcomingIgos, setUpcomingIgos] = useState<igosType[]>()
  async function fetchUpcomingIgos() {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/launch/igos/upcoming?pageNum=1&pageSize=10`)
      .then((res) => res.json())
      .then((res) => {
        return res.data.list
      })
    setUpcomingIgos(result)
  }
  useEffect(() => {
    fetchUpcomingIgos()
  }, [])
  return upcomingIgos
}
interface nextIGOType {
  id: number
  img: string
  title: string
  name: string
  tr: string
  contact: {
    twitter: string
    telegram: string
    discord: string
    vector: string
  }
  time: string
}
export function useNextIgos() {
  const [nextIgos, setNextIgos] = useState<nextIGOType[]>()
  async function fetchUpcomingIgos() {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/launch/igos/next?pageNum=1&pageSize=10`)
      .then((res) => res.json())
      .then((res) => {
        return res.data.list
      })
    setNextIgos(result)
  }
  useEffect(() => {
    fetchUpcomingIgos()
  }, [])
  return nextIgos
}

export function useEndIgos() {
  const [endIgos, setEndIgos] = useState<igosType[]>()
  async function fetchEndIgos() {
    const result = await fetch(`${process.env.REACT_APP_API_URL}/launch/igos/end?pageNum=1&pageSize=3`)
      .then((res) => res.json())
      .then((res) => {
        return res.data.list
      })
    setEndIgos(result)
  }
  useEffect(() => {
    fetchEndIgos()
  }, [])
  return endIgos
}

export function useIgoInfo(id: string | undefined) {
  const [info, setInfo] = useState()
  useEffect(() => {
    async function fetchIgoInfo() {
      if (id) {
        const result = await fetch(`${process.env.REACT_APP_API_URL}/launch/igos/detail?id=${id}`)
          .then((res) => res.json())
          .then((res) => {
            return res.data
          })
        setInfo(result)
      }
    }
    fetchIgoInfo()
  }, [id])
  return info
}
