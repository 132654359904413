import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'
// import '@fontsource/ibm-plex-sans'

interface COLOR {
  barBackground: string
  barSecondBackground: string
  barThirdBackground: string
  barFourthBackground: string
  yellow: string
  fontColor: string
  fontColor2: string
  fontColor3: string
}

export const colors: COLOR = {
  barBackground: '#4221DE',
  barSecondBackground: 'linear-gradient(135deg, #34322F 0%, #100F0D 100%)',
  barThirdBackground: '#1B4CE4',
  barFourthBackground: '#164CF5',
  yellow: '#FCB707',
  fontColor: '#fff',
  fontColor2: '#999',
  fontColor3: '#999',
}

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#2A64FB',
      // dark: '#fff111',
      contrastText: '#03011B',
    },
    secondary: {
      main: 'rgba(102, 102, 102, 0.4)',
    },
    warning: {
      main: '#FC9F49',
    },
    error: {
      main: red.A400,
    },
    background: {
      paper: '#191919',
      default: '#03011B',
    },
    grey: {
      50: 'rgba(255, 255, 255, 0.8)',
      100: 'rgba(255, 255, 255, 0.5)',
      200: 'rgba(0, 0, 0, 0.2)',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontSize: 0.875,
    h1: {
      fontSize: '2.25rem',
      lineHeight: '47px',
      fontWeight: 600,
      '@media (max-width: 899.5px)': {
        fontSize: '1.333333rem',
        lineHeight: '20px',
      },
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: 2,
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      lineHeight: 2,
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: '600',
      lineHeight: 2,
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '20px',
      color: '#fff',
    },
    body2: {
      fontSize: '1.25rem',
      color: '#999',
      lineHeight: '26px',
      whiteSpace: 'pre-wrap',
    },
    subtitle2: {
      fontWeight: '600',
      lineHeight: 1.5,
      color: 'rgba(255, 255, 255, 0.5)',
      fontSize: '1rem',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    overline: {
      fontSize: '1rem',
      color: 'rgba(255, 255, 255, 0.8)',
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: `
          background: #191919;
          border-radius: 12px;
          box-shadow: 0px 3px 4px #000000a3;
          color: rgba(255, 255, 255, 0.6)
        `,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: `
          padding-left: 1rem;
          padding-right: 1rem;
          @media (min-width: 600px){
            padding-left: 1rem;
            padding-right: 1rem;
            box-sizing: border-box;
          }
        `,
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: `
          font-size: 1.25rem;
        `,
        root: `
          color: #fff;
          padding: 6px 16px;
          border-radius: 10px;
          box-shadow: none;
          text-transform: none;
          font-size: 16px;
          &:hover {
            opacity: 0.8;
            box-shadow: none;
          }
          &.Mui-disabled {
            color: rgba(255, 255, 255, 0.5);
            background-color: rgba(42, 100, 251, 1);
          }
          @media (max-width: 599.5px){
            padding: 6px 12px;
          }
        `,
        containedPrimary: `
          &.Mui-disabled {
            background-color: #7498f5;
            color: #fff;
          }
        `,
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: `
          min-width: 35px;
        `,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: `
          flex: 1;
          color: rgba(255, 255, 255, 0.8);
          background-color: rgba(16, 14, 38, 1);
        `,
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: `
          background-color: transparent;
        `,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: `
          margin-bottom: 0px;
          border-radius: 0;
        `,
        thumb: `
          border-radius: 0px;
          width: 8px;
        `,
        track: `
          height: 12px;
        `,
        mark: `
          display: none;
        `,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: `
          border-color: rgba(0, 0, 0, 0.05);
        `,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: `
          max-width: 780px;
          border-radius: 12px;
          background: #191919;
        `,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: `
          background: #191919;
          color: #fff;
          padding: 20px 40px;
          font-size: 2rem;
          line-height: 2.5rem;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: space-between;

        `,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        ::-webkit-scrollbar {
            height: 6px;
            width: 6px;
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 0.2);
        }
        @-moz-document url-prefix() {
            * {
                scrollbar-width: thin;
                scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
            }
        }
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        html {
          @media (max-width: 899.5px){
            font-size: 12px;
          }
        }
        body {
          background: #020202;// linear-gradient(180deg, #F9FBFF 0%, #FFFFFF 100%);
          font-family:Avenir,IBM Plex Sans, sans-serif;
          font-style: normal;
          color: #fff;
          overflow-x: hidden;
          @media (max-width: 899.5px){
            background: #020202;
          }
        }
        #root {
          position: relative;
        }
        a {
          text-decoration: none;
          color: #fff;
        }
        .carousel .thumb, .carousel .thumb:focus {
          border: 0;
        }
        .carousel .thumb.selected, .carousel .thumb:hover {
          border: 3px solid #00FF0A;
          height: 28.77px;
        }
        .carousel .thumbs-wrapper {
          position: absolute;
          bottom: 0;
        }
        .MuiTypography-gutterBottom {
          margin-bottom: 1.375rem !important;
        }
        .MuiTab-root.Mui-selected {
          background: transparent;
        }
        .input-ph-r {
          input {
            padding-right: 8px;
            text-align: right;
            font-weight: 600;
            &::placeholder {
              text-align: right;
              color: rgba(3, 1, 27, 0.2)
            },
          },
        },
        .PrivateSwipeArea-root {
          width: 0 !important;
          padding: 0 !important;
        }
      `,
    },
  },
})
// console.log(theme)
export default theme
