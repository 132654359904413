import { useEffect, useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { parseUnits, formatUnits } from '@ethersproject/units'
import { Box, Typography, Button, Alert, AlertTitle, Stack, Snackbar, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import theme, { colors } from 'theme'
import { igoInfoType } from 'hooks/useIGO'
import { useWalletModalToggle } from 'state/application/hooks'
import { useAllowance } from 'hooks/useErc20'
import { approveMax } from 'constants/erc20'
import { isInWhiteList, subscribe, estimateClaim } from 'constants/igo'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { formatAmount } from 'utils'

import CustomizeInput from 'components/CustomizeInput'
import LoadingIcon from 'components/LoadingIcon'

import PayArrowPic from 'assets/images/svg/pay-arrow.svg'

dayjs.extend(utc)
const PayWapper = styled(Box)`
  background: ${colors.barThirdBackground};
  border-radius: 20px;
  width: 380px;
  margin-top: 34px;
  padding: 36px 24px;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 24px 18px;
  }
`

export default function Pay({ info, reFetch }: { info: igoInfoType | undefined; reFetch: () => void }) {
  const matches = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { account, library } = useWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const [payValue, setPayValue] = useState<string>('')
  const [approveLoading, setApproveLoading] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [inWhiteList, setInWhiteList] = useState<boolean>(false)
  const [receiveValue, setReceiveValue] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { allowance, doReFetch } = useAllowance(info?.sourceCurrency.address, info?.contract)
  const [openAlert, setOpenAlert] = useState<boolean>(false)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenAlert(false)
  }

  const confirm = async () => {
    setConfirmLoading(true)
    if (payValue !== '') {
      try {
        const tx = await await subscribe(library, info?.contract, parseUnits(payValue), account)
        await tx.wait()
        setConfirmLoading(false)
        setPayValue('')
        setReceiveValue('')
        reFetch()
      } catch (error) {
        setConfirmLoading(false)
        setError(error.data.message)
        setOpenAlert(true)
        console.error(error)
      }
    }
  }
  // console.log(allowance)
  const approve = async () => {
    setApproveLoading(true)
    if (info) {
      try {
        const tx = await approveMax(library, info?.contract, info?.sourceCurrency.address, account)
        await tx.wait()
        setApproveLoading(false)
        doReFetch()
      } catch (error) {
        setApproveLoading(false)
        console.error(error)
      }
    }
  }
  const fetchIsInWhiteList = useCallback(async () => {
    if (account && info && library) {
      const _isInWhiteList = await isInWhiteList(library, info?.contract, account)
      setInWhiteList(_isInWhiteList)
    }
  }, [account, info, library])
  useEffect(() => {
    fetchIsInWhiteList()
  }, [fetchIsInWhiteList])

  const sourceChange = async (event: any) => {
    if (event.target.value <= Number(info?.maxSubAmount) - Number(info?.purchasedAmount) && account && info) {
      setPayValue(event.target.value.replace(/[^1-9]{0,1}(\d*(?:\.\d{0,9})?).*$/g, '$1') as string)
      if (event.target.value !== '') {
        const _receive = await estimateClaim(library, info?.contract, parseUnits(event.target.value), account)
        setReceiveValue(formatUnits(_receive, info.destCurrency.decimals))
      } else {
        setReceiveValue('')
      }
    }
  }

  // useEffect
  // estimateClaim

  return (
    <PayWapper>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            <AlertTitle>Error</AlertTitle>
            <p style={{ marginTop: '-15px' }}>{error}</p>
          </Alert>
        </Snackbar>
      </Stack>
      <Box mb={'8px'} display={'flex'} justifyContent={'space-between'}>
        <Typography color={theme.palette.grey[100]} fontWeight={500} fontSize={matches ? '1rem' : '0.875rem'}>
          Pay
        </Typography>
        <Typography color={theme.palette.grey[100]} fontWeight={500} fontSize={matches ? '1rem' : '0.875rem'}>
          Min {Number(info?.minSubAmount)} - Max {Number(info?.maxSubAmount) - Number(info?.purchasedAmount)}
        </Typography>
      </Box>
      <Box>
        <CustomizeInput
          icon={info?.sourceCurrency.icon}
          name={info?.sourceCurrency.name}
          value={payValue}
          fullWidth
          change={sourceChange}
          select={() => {
            //
          }}
        />
      </Box>
      <Box mt={'16px'} mb={matches ? '6px' : '10px'} textAlign={'center'}>
        <img width={matches ? '32px' : '48px'} src={PayArrowPic} alt="" />
      </Box>
      <Box mb={'8px'}>
        <Typography color={theme.palette.grey[100]} fontWeight={500} fontSize={matches ? '1rem' : '0.875rem'}>
          Receive(Estimated)
        </Typography>
      </Box>
      <Box mb={'16px'}>
        <CustomizeInput
          readonly
          icon={info?.destCurrency.icon}
          name={info?.destCurrency.name}
          value={receiveValue}
          fullWidth
          select={() => {
            //
          }}
        />
      </Box>
      {account && Number(allowance) > 0 && (
        <Button
          disabled={!inWhiteList || payValue === '' || dayjs().unix() < Number(info?.startTime)}
          startIcon={confirmLoading ? <LoadingIcon /> : ''}
          variant="contained"
          color="warning"
          fullWidth
          onClick={confirm}
        >
          Confirm
        </Button>
      )}
      {account && Number(allowance) <= 0 && inWhiteList && (
        <Button
          startIcon={approveLoading ? <LoadingIcon /> : ''}
          onClick={approve}
          variant="contained"
          color="warning"
          fullWidth
        >
          Approve
        </Button>
      )}
      {!account && (
        <Button variant="contained" color="warning" fullWidth onClick={toggleWalletModal}>
          Connect Wallet
        </Button>
      )}
      {!inWhiteList && account && (
        <Typography
          mt={'19px'}
          color={theme.palette.common.white}
          fontWeight={500}
          textAlign={'center'}
          fontSize={'1.25rem'}
        >
          Non-whitelisted!
        </Typography>
      )}
      {Number(info?.purchasedAmount) > 0 && (
        <Typography
          mt={'19px'}
          color={theme.palette.common.white}
          fontWeight={500}
          textAlign={'center'}
          fontSize={'1.25rem'}
        >
          You have subscribed: {formatAmount(Number(info?.purchasedAmount))} {info?.sourceCurrency.name}
        </Typography>
      )}
    </PayWapper>
  )
}
