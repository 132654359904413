/**
 * @file
 * 首页和详情页面倒计时
 */
import { styled, Box, Typography } from '@mui/material'
import theme from 'theme'
import Countdown from 'react-countdown'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Translation, useTranslation } from 'react-i18next'

dayjs.extend(utc)

const IndexWraper = styled(Box)`
  display: flex;
  list-style: none;
  ${theme.breakpoints.down('md')} {
    width: 100%;
  }
  .gress {
    width: 100%;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    /* text-align: right; */
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #c0c0c0;
    }

    span:first-of-type {
      font-weight: bold;
      display: inline-block;
      font-weight: 800;
      font-size: 16px;
      line-height: 22px;
      align-self: flex-start;
      color: #ffffff;
      margin-right: 4px;
    }
    span:last-of-type {
      font-weight: 800;
      font-size: 14px;
      line-height: 19px;
      color: #ffffff;
      margin-left: 8px;
    }
  }

  li + li {
    margin-left: 12px;
  }

  /* ${theme.breakpoints.down('md')} {
    justify-content: space-between;
    margin-top: 25px;

    li {
      flex-flow: column;
      align-items: center;
      margin-left: 0 !important;

      span {
        font-weight: 500;
      }

      span:first-of-type {
        margin-right: 0;
        margin-bottom: 15px;
        align-self: center;
        text-align: center;
      }
    }
  } */
`

const DetailWraper = styled(Box)`
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;

  li:not(.line) {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 60px;
    background: rgba(255, 255, 255, 0.06);
    border: 0.4px solid #c0c0c0;
    backdrop-filter: blur(12px);
    border-radius: 4px;

    span:not(:first-of-type) {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #c0c0c0;
    }

    span:first-of-type {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
    }
  }

  li.line {
    font-weight: bold;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    margin: 0 14px;
  }
`

interface Props {
  data: {
    id: number
    startTime: number
    endTime: number
    state: number
  }
  isM?: boolean
  page: 'index' | 'detail'
  onComplete?: () => void
}

const renderer = (page: string, { days, hours, minutes, seconds }: any) => {
  return (
    <Translation>
      {(t) => {
        return (
          <>
            <li>
              <span>{days}</span>
              <span>{t('DAYS')}</span>
              {page === 'index' ? <span>:</span> : null}
            </li>
            {page === 'detail' ? <li className="line">:</li> : null}
            <li>
              <span>{hours}</span>
              <span>{t('HOURS')}</span>
              {page === 'index' ? <span>:</span> : null}
            </li>
            {page === 'detail' ? <li className="line">:</li> : null}
            <li>
              <span>{minutes}</span>
              <span>{t('MINS')}</span>
              {page === 'index' ? <span>:</span> : null}
            </li>
            {page === 'detail' ? <li className="line">:</li> : null}
            <li>
              <span>{seconds}</span>
              <span>{t('SECS')}</span>
              <span></span>
            </li>
          </>
        )
      }}
    </Translation>
  )
}

export default function MintCountdown(props: Props) {
  const { t } = useTranslation()
  const date =
    Date.now() < props.data?.startTime
      ? Date.now() + (Number(props.data?.startTime) - dayjs().unix() * 1000)
      : Date.now() > props.data?.startTime
      ? Date.now() + (Number(props.data?.endTime) - dayjs().unix() * 1000)
      : 0
  return props.page === 'index' ? (
    <IndexWraper>
      {date < Date.now() ? (
        <Typography className="gress" sx={{ textAlign: props.isM ? 'left' : 'right' }}>
          {t('In Progress')}
        </Typography>
      ) : (
        <Countdown date={date} onComplete={props.onComplete} renderer={(cp) => renderer(props.page, cp)} />
      )}
    </IndexWraper>
  ) : (
    <DetailWraper>
      <Countdown date={date} onComplete={props.onComplete} renderer={(cp) => renderer(props.page, cp)} />
    </DetailWraper>
  )
}
