import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Igo from 'pages/Igo'
import IgoDetails from 'pages/IgoDetails'
import Nft from 'pages/Nft'
import NftDetails from 'pages/Nft/Details'
import Home from 'pages/Home/index'
import About from 'pages/About/index'
import Information from 'pages/Info/Informations'
import Strategy from 'pages/Info/Strategy'
import Learn from 'pages/Info/Learn'
import Detail from 'pages/Info/Detail'
import Games from 'pages/games'
import GamesDetail from 'pages/games/detail'
import FreeMint from 'pages/FreeMint/index'

import Ino from 'pages/Ino'
import InoDetail from 'pages/Ino/detail'
import Record from 'pages/Record'
import { useLang, useSetLanguage } from 'state/language/hooks'
import { useTranslation } from 'react-i18next'
import '../i18n'
import { langs } from 'utils/langs'
import { Box, useMediaQuery } from '@mui/material'

export default function Routers() {
  const lang = useLang()
  const { i18n } = useTranslation()

  let localLang = window.location.pathname.slice(1, 6)
  if (!langs.includes(localLang) && localLang) {
    localLang = lang
    window.location.href = `/${lang}/`
  }
  const changeLang = useSetLanguage(localLang || 'en-us')
  if (lang !== localLang) {
    changeLang()
    i18n.changeLanguage(localLang || 'en-us')
  }

  const isM = useMediaQuery((theme: any) => {
    return theme.breakpoints.down('md')
  })
  return (
    <Router>
      <Header />
      <Box sx={{ mt: isM ? '80px' : '120px' }}>
        <Routes>
          <Route path={`${lang}/`} caseSensitive element={<Home />} />
          <Route path={`${lang}/about-us`} element={<About />} />
          <Route path={`${lang}/information`} element={<Information />} />
          <Route path={`${lang}/strategy`} element={<Strategy />} />
          <Route path={`${lang}/detail/:id`} element={<Detail />} />
          <Route path={`${lang}/learn`} element={<Learn />} />
          <Route path={`${lang}/igo`} element={<Igo />} />
          <Route path={`${lang}/igo/:id`} element={<IgoDetails />} />
          <Route path={`${lang}/nft`} element={<Nft />} />
          <Route path={`${lang}/nft/:id`} element={<NftDetails />} />
          <Route path={`${lang}/ino`} element={<Ino />} />
          <Route path={`${lang}/ino/:id`} element={<InoDetail />} />
          <Route path={`${lang}/record/`} element={<Record />} />
          <Route path={`${lang}/record/:tab`} element={<Record />} />
          <Route path={`${lang}/games`} element={<Games />} />
          <Route path={`${lang}/games/:id`} element={<GamesDetail />} />
          <Route path={`${lang}/mint`} element={<FreeMint />} />

          <Route path={`${lang}`} element={<Igo />} />
          <Route path="" caseSensitive element={<Home />} />
        </Routes>
      </Box>
      <Footer />
    </Router>
  )
}
