import { useState, useEffect } from 'react'
import { Box, styled, useMediaQuery } from '@mui/material'

import theme from 'theme'
import { useTranslation } from 'react-i18next'

import MboxPic from 'assets/images/svg/mbox.svg'
import PricePic from 'assets/images/svg/price.svg'
import DatePic from 'assets/images/svg/date.svg'
import SelectIconPicUp from 'assets/images/svg/select-icon-up.svg'
import SelectIconPicDown from 'assets/images/svg/select-icon-down.svg'
import SelectIconPic from 'assets/images/svg/select-icon.svg'

import SearchIconPic from 'assets/images/svg/search.svg'

const Wrapper = styled(Box)`
  display: flex;
  ${theme.breakpoints.down('md')} {
    width: 100%;
    display: unset;
    > div {
      &:nth-of-type(1) {
        display: flex;
        align-items: center;
        div:nth-of-type(1) {
          width: 50%;
          > div {
            width: 100%;
          }
        }
        div:nth-of-type(3) {
          width: 50%;
          > div {
            width: 100%;
          }
        }
      }
      &:nth-of-type(2) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        div:nth-of-type(1) {
          justify-content: space-between;
          margin-top: 0;
          flex: 1;
          height: 50px;
          align-items: center;
          input {
            height: 50px;
            width: 90%;
            font-size: 19px;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
        div:nth-of-type(2) {
          overflow: hidden;
          flex-direction: column;
          align-items: center;
          height: 50px;
          font-size: 10px;
          font-weight: 500;
          padding: 7px 10px 10px;
          margin-left: 15px;
          img {
            margin-right: 0;
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
`

const SelectItems = styled(Box)`
  display: inline-flex;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 8px;
  justify-content: space-between;
  > div {
    display: inline-flex;
  }
  cursor: pointer;
  img.select-start-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    ${theme.breakpoints.down('md')} {
      width: 18px;
      height: 18px;
    }
  }
  img.select-icon {
    margin-left: 10px;
    color: #2e5bff;
  }
  &.active {
    border-color: #2e5bff;
    background: #061235;
    color: #2e5bff;
    overflow: hidden;
    img {
      filter: drop-shadow(#2e5bff 100px 0);
      transform: translateX(-100px);
    }
  }
`

const InputBox = styled(Box)`
  display: inline-flex;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 0 15px;
  height: 43px;
  font-size: 14px;
  border-radius: 8px;
  img {
    margin: 12px 0;
    cursor: pointer;
  }
  input {
    height: 43px;
    margin-right: 10px;
    outline: none;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border: 0;
  }
  &:hover {
    border-color: #fff;
  }
  ${theme.breakpoints.down('md')} {
    margin-top: 10px;
  }
`
interface FiltersPro {
  filters: (par: FileProps) => void
}

export default function Filters(pro: FiltersPro) {
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [srcDate, setSrcDate] = useState(SelectIconPicDown)
  const [src, setSrc] = useState(SelectIconPic)
  const [params, setParams] = useState<FileProps>({
    sort: 2,
    sortName: 1,
    nftName: '',
    nftType: 0,
    page: 1,
  })
  const [nftName, setNftName] = useState<string>('')
  useEffect(() => {
    pro.filters(params)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  useEffect(() => {
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])
  return (
    <Wrapper>
      <Box display={'flex'}>
        <Box>
          <SelectItems
            onClick={(e) => {
              setSrcDate(SelectIconPic)
              setSrc(SelectIconPic)
              setParams({ ...params, ...{ sortName: 1, page: 1 } })
              if (params.sort === 1) {
                setSrcDate(SelectIconPicDown)
                setParams((par) => {
                  return { ...par, ...{ sort: 2, page: 1 } }
                })
              } else {
                setSrcDate(SelectIconPicUp)
                setParams((par) => {
                  return { ...par, ...{ sort: 1, page: 1 } }
                })
              }
            }}
            id="release-Date-button"
          >
            <Box>
              <img className={'select-start-icon'} src={DatePic} alt="" />
              {t('Release Date')}
            </Box>
            <img className={'select-icon'} src={srcDate} alt="" />
          </SelectItems>
        </Box>
        <Box width={isMobile ? '15px' : '20px'} height="10px"></Box>
        <Box>
          <SelectItems
            id="price-sort-menu-button"
            onClick={(e) => {
              setSrcDate(SelectIconPic)
              setParams({ ...params, ...{ sortName: 2, page: 1 } })
              if (params.sort === 1) {
                setSrc(() => {
                  return SelectIconPicDown
                })
                setParams((par) => {
                  return { ...par, ...{ sort: 2, page: 1 } }
                })
              } else {
                setSrc(() => {
                  return SelectIconPicUp
                })
                setParams((par) => {
                  return { ...par, ...{ sort: 1, page: 1 } }
                })
              }
            }}
          >
            <Box>
              <img className={'select-start-icon'} src={PricePic} alt="" />
              {t('Price Sort')}
            </Box>
            <img className={'select-icon'} src={src} alt="" />
          </SelectItems>
        </Box>
        {!isMobile && <Box width={'20px'} height="10px"></Box>}
        {!isMobile && <Mystery />}
      </Box>
      <Box display={'flex'} flex="1" justifyContent={'flex-end'}>
        <InputBox>
          <input
            placeholder={t('Search items...')}
            type="search"
            value={nftName}
            onBlur={(e) => {
              setParams(Object.assign({}, params, { nftName, page: 1 }))
            }}
            onChange={(e) => {
              setNftName(e.target.value)
            }}
          />
          <img
            src={SearchIconPic}
            alt=""
            onClick={(e) => {
              setParams(Object.assign({}, params, { nftName, page: 1 }))
            }}
          />
        </InputBox>
        {isMobile && <Mystery />}
      </Box>
    </Wrapper>
  )

  function Mystery() {
    return (
      <SelectItems
        className={params.nftType === 1 ? 'active' : ''}
        onClick={(e) => {
          if (params.nftType === 0) {
            setParams(Object.assign({}, params, { nftType: 1, page: 1 }))
          } else {
            setParams(Object.assign({}, params, { nftType: 0, page: 1 }))
          }
        }}
      >
        <img className={'select-start-icon'} src={MboxPic} alt="" />
        {t('Mystery Box')}
      </SelectItems>
    )
  }
}
