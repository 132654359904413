import * as React from 'react'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'
import LogoPic from 'assets/images/svg/logo_white.svg'
import Hot from 'assets/images/png/hot-hander.png'
import { useLang } from 'state/language/hooks'
import { useTranslation } from 'react-i18next'
import IpNotice from './IpNotice'
import cookie from 'react-cookies'
import { langsSub } from 'utils/langs'
import SearchIconPic from 'assets/images/svg/search.svg'
import { useLocation } from 'react-router'
import SnapchatLogo from 'assets/images/svg/snapchat_logo.svg'
import TwitterLogo from 'assets/images/svg/twitter-white.svg'

import {
  Menu,
  MenuItem,
  Box,
  useMediaQuery,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Collapse,
  Input,
  Typography,
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import theme from 'theme'
import Web3Status from '../Web3Status'
import MenuPic from 'assets/images/svg/menu.svg'
import { get } from 'utils/fetch'
import ExpandMore from '@mui/icons-material/ExpandMore'

const HeaderBox = styled('header')`
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 80px;
  background-color: #131313;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  ${theme.breakpoints.down('md')} {
    margin-bottom: 0;
    height: 44px;
  }
  > div {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    ${theme.breakpoints.down('md')} {
      padding: 0 1rem;
      justify-content: space-between;
    }
    img {
      cursor: pointer;
    }
    a {
      color: #fff;
      display: flexbox;
      ${theme.breakpoints.down('md')} {
        display: flex;
        margin-right: 0;
      }
      img {
        ${theme.breakpoints.down('md')} {
          width: 66.5px;
        }
      }
    }
    > nav {
      flex: 1;
      display: flex;
      color: #fff;
      align-items: center;
      a {
        margin-left: 10px;
        height: 36px;
        border-radius: 4px;
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        line-height: 1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #2a64fb;
        }
      }
      a.active {
        color: #2a64fb;
      }
    }
  }
`
const BetaTag = styled(Box)`
  background-color: #2a64fb;
  font-size: 12px;
  padding: 2px 7px;
  border-radius: 18px;
  margin-top: -4px;
  margin-left: -12px;
  flex-shrink: 0;
  align-self: flex-start;
  line-height: 1;
  ${theme.breakpoints.down('md')} {
    margin-left: -4px;
  }
`
const MenuItemH5 = styled(MenuItem)`
  min-height: 36px;
  background: #3e3e3e;
  border-bottom: 0.6px solid #656565;
  margin: 0 16px;
  &.isactive {
    background: #2a64fb;
  }
  &:last-child {
    border-bottom: 0;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`
const MenuItemWeb = styled(Box)`
  min-height: 36px;
  color: #fff;
  line-height: 36px;
  border-radius: 5px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: #2a64fb;
  }
`

const Right = styled('div')`
  display: flex;
  align-items: center;
  #language {
    padding-right: 0;
  }
  ${theme.breakpoints.down('md')} {
    flex: 1;
    justify-content: flex-end;
    img {
      margin-left: 16px;
    }
  }
`
const SubMenu = styled(Box)`
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  height: 36px;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .MuiSvgIcon-root {
    transition: all 0.3s ease-in-out;
    margin: 1px -2px 0 6px;
    font-size: 18px;
  }

  &:hover,
  &[aria-expanded] {
    color: #2a64fb;
    .MuiSvgIcon-root {
      transform: rotate(180deg);
    }
  }
  &.active {
    color: #2a64fb;
  }
`

const DropMenu = styled(Menu)`
  .MuiPaper-root {
    min-width: 200px;
    padding: 18px;
    border-radius: 4px;
    background-color: #222;
    margin-top: 6px;

    .MuiList-root {
      padding: 0;

      li {
        padding: 0;
        background-color: transparent;

        a {
          display: block;
          width: 100%;
          transition: all 0.3s ease-in-out;
          font-weight: bold;
          line-height: 36px;
          padding: 0 10px;
          border-radius: 4px;

          &:hover {
            background-color: rgba(255, 255, 255, 0.08);
            color: #2a64fb;
          }
        }
      }
    }
  }
`
const LangInput = styled(Box)`
  border: 1px solid rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 30px;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
    width: 15px;
    height: 15px;
  }
  &:hover {
    border: 1px solid #2a64fb;
  }
  input {
    height: 100%;
    outline: none;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border: 0;
  }
`

const ListLink = styled('a')`
  display: block;
  width: 100%;
  &.isactive {
    background: #2a64fb;
  }
`
const FooterBox = styled('footer')`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  bottom: 0;
  width: 100%;
  a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 30px;
    img {
      margin-right: 18px;
    }
  }
  p {
    width: 100%;
    box-shadow: inset 0px 0.6px 0px #666666;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.48px;
    padding: 17px 0;
    color: rgba(255, 255, 255, 0.8);
  }
`
interface Lang {
  lang: string
  short: string
  label: string
}
export default function Header() {
  const lang = useLang()
  const { t } = useTranslation()
  const location = useLocation()
  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpenSub] = React.useState<string>('')
  const [ipSupport, setIpSupport] = React.useState(false)
  const [openLaunch, setOpenLaunch] = React.useState(false)
  const [openInfo, setOpenInfo] = React.useState(false)
  const [localLang] = React.useState(() => {
    const _lang = langsSub.filter((item) => item.lang === lang)[0]
    return _lang.label
  })
  const [langList, setLangList] = React.useState(langsSub)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, targetSub: string) => {
    setAnchorEl(event.currentTarget)
    setOpenSub(targetSub)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setOpenSub('')
    setLangList(langsSub)
  }
  const handleClickOpen = () => {
    setOpenLaunch(!openLaunch)
  }
  const handleClickOpenInfo = () => {
    setOpenInfo(!openInfo)
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setDrawerOpen(open)
  }

  const changeLang = (item: Lang) => {
    window.location.href = `${window.location.origin}/${item.lang}/${window.location.pathname.slice(7)}`
  }
  React.useEffect(() => {
    if (!cookie.load('ipModal')) {
      get('/system/country_check', {}).then((res: any) => {
        if (res.success) {
          cookie.save('ipModal', 'true', {
            expires: new Date(+new Date() + 24 * 60 * 60 * 1000),
            path: '/',
            domain: window.location.hostname,
          })
          setIpSupport(!res.data)
        }
      })
    }
  }, [])
  const handleCloseIp = (): void => {
    setIpSupport(false)
  }

  return (
    <>
      <HeaderBox>
        <div>
          <Box display={'flex'} alignItems="center" flexShrink="0">
            <a href={`/${lang}/`}>
              <img src={LogoPic} alt="logo" />
            </a>
            <BetaTag>{t('Beta')}</BetaTag>
          </Box>
          {matches && (
            <nav>
              <a href={`/${lang}/`} className={location.pathname.endsWith(`/${lang}/`) ? 'active' : ''}>
                {t('Home')}
              </a>
              <>
                <SubMenu
                  id="launch-button"
                  aria-controls={open === 'launch' ? 'launch-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open === 'launch' ? 'true' : undefined}
                  className={
                    location.pathname.indexOf(`igo/`) > -1 || location.pathname.indexOf(`ino/`) > -1 ? 'active' : ''
                  }
                  onMouseEnter={(ev) => {
                    handleClick(ev, 'launch')
                  }}
                >
                  {t('Launch Pool')}
                  <ExpandMoreIcon />
                </SubMenu>
                <DropMenu
                  id="launch-menu"
                  anchorEl={anchorEl}
                  open={open === 'launch'}
                  onClose={handleClose}
                  onMouseLeave={handleClose}
                  // MenuListProps={{
                  //   'aria-labelledby': 'launch-button',
                  // }}
                >
                  <MenuItem onClick={handleClose}>
                    <a
                      style={{ color: location.pathname.endsWith(`/${lang}/igo/`) ? '#2a64fb' : '' }}
                      href={`/${lang}/igo/`}
                    >
                      {t('IGO')}
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a
                      href={`/${lang}/ino/`}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        color: location.pathname.endsWith(`/${lang}/ino/`) ? '#2a64fb' : '',
                      }}
                    >
                      {t('Mystery Box')}
                      <img width={10} height={13} src={Hot} alt=""></img>
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a
                      style={{ color: location.pathname.endsWith(`/${lang}/mint/`) ? '#2a64fb' : '' }}
                      href={`/${lang}/mint/`}
                    >
                      {t('Mints')}
                    </a>
                  </MenuItem>
                </DropMenu>
              </>
              <NavLink to={`${lang}/games`}>{t('Games')}</NavLink>
              <NavLink to={`${lang}/nft`}>{t('NFT Market')}</NavLink>
              <>
                <SubMenu
                  id="basic-button"
                  className={
                    location.pathname.indexOf(`information/`) > -1 ||
                    location.pathname.indexOf(`learn/`) > -1 ||
                    location.pathname.indexOf(`strategy/`) > -1
                      ? 'active'
                      : ''
                  }
                  aria-controls={open === 'info' ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open === 'info' ? 'true' : undefined}
                  onMouseEnter={(ev) => {
                    handleClick(ev, 'info')
                  }}
                >
                  {t('Info')}
                  <ExpandMoreIcon />
                </SubMenu>
                <DropMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open === 'info'}
                  onClose={handleClose}
                  onMouseLeave={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {/* <MenuItem onClick={handleClose}>
                    <a href={`/${lang}/markets/`}>{t('Markets')}</a>
                  </MenuItem> */}
                  <MenuItem onClick={handleClose}>
                    <a
                      style={{ color: location.pathname.endsWith(`/${lang}/information/`) ? '#2a64fb' : '' }}
                      href={`/${lang}/information/`}
                    >
                      {t('Information')}
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a
                      style={{ color: location.pathname.endsWith(`/${lang}/strategy/`) ? '#2a64fb' : '' }}
                      href={`/${lang}/strategy/`}
                    >
                      {t('Strategy')}
                    </a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a
                      style={{ color: location.pathname.endsWith(`/${lang}/learn/`) ? '#2a64fb' : '' }}
                      href={`/${lang}/learn/`}
                    >
                      {t('Learn')}
                    </a>
                  </MenuItem>
                </DropMenu>
              </>
              <a
                href={`/${lang}/about-us/`}
                className={location.pathname.endsWith(`/${lang}/about-us/`) ? 'active' : ''}
              >
                {t('About Us')}
              </a>
            </nav>
          )}
          <Right>
            <Web3Status />
            {!matches && <img width={'24px'} height={'24px'} src={MenuPic} alt="logo" onClick={toggleDrawer(true)} />}
            {matches && (
              <>
                <SubMenu
                  id="language"
                  aria-controls={open === 'lang' ? 'lang-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open === 'lang' ? 'true' : undefined}
                  onMouseEnter={(ev) => {
                    handleClick(ev, 'lang')
                  }}
                >
                  {localLang}
                  <ExpandMoreIcon />
                </SubMenu>
                <DropMenu id="lang-menu" anchorEl={anchorEl} open={open === 'lang'} onClose={handleClose}>
                  <LangInput>
                    <img src={SearchIconPic} alt=""></img>
                    <Input
                      disableUnderline={true}
                      onChange={(e) => {
                        const list = langsSub.filter((item) => {
                          return (
                            item.label.includes(e.target.value.toLocaleLowerCase()) ||
                            item.lang.includes(e.target.value.toLocaleLowerCase())
                          )
                        })

                        setLangList(list)
                        //
                      }}
                    />
                  </LangInput>
                  {langList.map((item) => {
                    return (
                      <MenuItemWeb
                        className={location.pathname.indexOf(item.lang) > -1 ? 'isactive' : ''}
                        key={item.short}
                        onClick={() => {
                          changeLang(item)
                        }}
                      >
                        {item.label} &nbsp;<span>{item.short}</span>
                      </MenuItemWeb>
                    )
                  })}
                </DropMenu>
              </>
            )}
          </Right>
        </div>
        <SwipeableDrawer
          sx={{
            display: { sm: 'block', md: 'block' },
            position: 'relative',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '80%' },
          }}
          anchor={'right'}
          open={matches ? false : drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 16px 0', alignItems: 'center' }}>
            <Box display={'flex'} alignItems="center" flexShrink="0">
              <a href={`/${lang}/`}>
                <img src={LogoPic} alt="logo" />
              </a>
              <BetaTag>{t('Beta')}</BetaTag>
            </Box>
            {/* <img src={CloseIcon} alt="" style={{ cursor: 'pointer' }} onClick={toggleDrawer(false)} /> */}
            <img width={'24px'} height={'24px'} src={MenuPic} alt="logo" onClick={toggleDrawer(false)} />
          </Box>
          <List sx={{ height: 'calc(100vh - 250px)', overflow: 'auto' }}>
            <ListItem disablePadding>
              <ListLink href={`/${lang}/`} className={location.pathname.endsWith(`/${lang}/`) ? 'isactive' : ''}>
                <ListItemButton>
                  <ListItemText primary={t('Home')} />
                </ListItemButton>
              </ListLink>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton onClick={handleClickOpen}>
                <ListItemText primary={t('Launch')} />
                {openLaunch ? (
                  <ExpandLess sx={{ width: '24px', color: '#fff', height: '24px' }} />
                ) : (
                  <ExpandMore sx={{ width: '24px', color: '#fff', height: '24px' }} />
                )}
              </ListItemButton>
              <Collapse in={openLaunch} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <MenuItemH5
                    onClick={handleClickOpen}
                    className={location.pathname.startsWith(`/${lang}/igo/`) ? 'isactive' : ''}
                  >
                    <a href={`/${lang}/igo/`}>{t('IGO')}</a>
                  </MenuItemH5>
                  <MenuItemH5
                    onClick={handleClickOpen}
                    className={location.pathname.startsWith(`/${lang}/ino/`) ? 'isactive' : ''}
                  >
                    <a href={`/${lang}/ino/`}>
                      {t('Mystery Box')}
                      <img width={10} height={13} src={Hot} alt="" style={{ marginLeft: '20px' }}></img>
                    </a>
                  </MenuItemH5>
                  <MenuItemH5
                    onClick={handleClickOpen}
                    className={location.pathname.startsWith(`/${lang}/mint/`) ? 'isactive' : ''}
                  >
                    <a href={`/${lang}/mint/`}>{t('Mints')}</a>
                  </MenuItemH5>
                </List>
              </Collapse>
            </ListItem>
            <ListItem disablePadding>
              <ListLink
                href={`/${lang}/games/`}
                className={location.pathname.startsWith(`/${lang}/games/`) ? 'isactive' : ''}
              >
                <ListItemButton>
                  <ListItemText primary={t('Games')} />
                </ListItemButton>
              </ListLink>
            </ListItem>
            <ListItem disablePadding>
              <ListLink
                href={`/${lang}/nft/`}
                className={location.pathname.startsWith(`/${lang}/nft/`) ? 'isactive' : ''}
              >
                <ListItemButton>
                  <ListItemText primary={t('NFT Market')} />
                </ListItemButton>
              </ListLink>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton onClick={handleClickOpenInfo}>
                <ListItemText primary={t('Info')} />
                {openInfo ? (
                  <ExpandLess sx={{ width: '24px', color: '#fff', height: '24px' }} />
                ) : (
                  <ExpandMore sx={{ width: '24px', color: '#fff', height: '24px' }} />
                )}
              </ListItemButton>
              <Collapse in={openInfo} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {/* <MenuItemH5 className={location.pathname.startsWith(`/${lang}/markets/`) ? 'isactive' : ''}>
                    <a href={`/${lang}/markets/`}>{t('Markets')}</a>
                  </MenuItemH5> */}
                  <MenuItemH5 className={location.pathname.startsWith(`/${lang}/information/`) ? 'isactive' : ''}>
                    <a href={`/${lang}/information/`}>{t('Information')}</a>
                  </MenuItemH5>
                  <MenuItemH5 className={location.pathname.startsWith(`/${lang}/strategy/`) ? 'isactive' : ''}>
                    <a href={`/${lang}/strategy/`}>{t('Strategy')}</a>
                  </MenuItemH5>
                  <MenuItemH5 className={location.pathname.startsWith(`/${lang}/learn/`) ? 'isactive' : ''}>
                    <a href={`/${lang}/learn/`}>{t('Learn')}</a>
                  </MenuItemH5>
                </List>
              </Collapse>
            </ListItem>
            <ListItem disablePadding>
              <ListLink
                href={`/${lang}/about-us/`}
                className={location.pathname.endsWith(`/about-us/`) ? 'isactive' : ''}
              >
                <ListItemButton>
                  <ListItemText primary={t('About Us')} />
                </ListItemButton>
              </ListLink>
            </ListItem>
            <ListItem disablePadding>
              <ListLink
                href={`/${lang}/Record/`}
                className={location.pathname.startsWith(`/${lang}/Record/`) ? 'isactive' : ''}
              >
                <ListItemButton>
                  <ListItemText primary={t('Record')} />
                </ListItemButton>
              </ListLink>
            </ListItem>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={() => {
                  if (open) {
                    setOpenSub('')
                  } else {
                    setOpenSub('lang-h5')
                  }
                }}
              >
                <ListItemText primary={localLang} />
                {open === 'lang-h5' ? (
                  <ExpandLess sx={{ width: '24px', color: '#fff', height: '24px' }} />
                ) : (
                  <ExpandMore sx={{ width: '24px', color: '#fff', height: '24px' }} />
                )}
              </ListItemButton>
              <Collapse in={open === 'lang-h5'} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {langList.map((item) => {
                    return (
                      <MenuItemH5
                        className={location.pathname.indexOf(item.lang) > -1 ? 'isactive' : ''}
                        key={item.short}
                        onClick={() => {
                          changeLang(item)
                        }}
                      >
                        {item.label} &nbsp;<span>{item.short}</span>
                      </MenuItemH5>
                    )
                  })}
                </List>
              </Collapse>
            </ListItem>
          </List>
          <FooterBox>
            <a href="https://twitter.com/PopFunGameFi">
              <img width="20px" height="20px" src={TwitterLogo} alt="twitter_logo" />
              {t('twitter')}
            </a>
            <a href="https://www.facebook.com/PopFun-GameFi-108403608443442">
              <img width="20px" height="20px" src={SnapchatLogo} alt="FacebookLogo" />
              {t('facebook')}
            </a>
            <Typography>©2022 PopFun</Typography>
          </FooterBox>
        </SwipeableDrawer>
      </HeaderBox>
      <IpNotice open={ipSupport} close={handleCloseIp}></IpNotice>
    </>
  )
}
