import {
  Box,
  Container,
  styled,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Divider,
  Button,
  Grid,
  useMediaQuery,
  useScrollTrigger,
  Fade,
} from '@mui/material'
import { useGameNftList, useGamesDetail } from 'hooks/useGames'

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandMore'
import Sorting from 'assets/images/games/sorting.png'
import Edit from 'assets/images/games/edit.png'
import Back from 'assets/images/games/back.png'

import { useTranslation } from 'react-i18next'
import SearchIconPic from 'assets/images/svg/search.svg'
import SearchIcon from 'assets/images/games/search.png'

import InfiniteScroll from 'react-infinite-scroller'
import Card from 'pages/Nft/Card'
import Empty from 'pages/Record/components/Empty'
import Loading from 'pages/Nft/components/Loading'
import theme from 'theme'
import Spinner from 'components/Spinner'

// import ExpandMore from '@mui/icons-material/ExpandMore'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const More = styled(Box)`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    margin-top: 50px;
    ${theme.breakpoints.down('md')} {
      margin-bottom: 70px;
    }
  }
`
const BoxDetail = styled(Box)`
  font-family: 'Avenir';
  .banner {
    width: 100%;
    max-height: 360px;
    position: relative;
    margin-bottom: 50px;
    .bannerimagge {
      width: 100%;
      max-height: 360px;
      min-height: 360px;
      object-fit: cover;
      ${theme.breakpoints.down('md')} {
        max-height: 90px;
        min-height: 90px;
      }
    }
    .tw {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 20px;
      right: 100px;
      ${theme.breakpoints.down('md')} {
        right: 10px;
        bottom: 10px;
      }
      a {
        width: 24px;
        height: 24px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
        ${theme.breakpoints.down('md')} {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
    .icon {
      width: 80px;
      height: 80px;
      position: absolute;
      left: calc(50% - 40px);
      bottom: -40px;
      background: radial-gradient(50% 50% at 50% 50%, #1f1f1f 92.36%, #2f2f2f 100%);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      ${theme.breakpoints.down('md')} {
        width: 70px;
        height: 70px;
      }
    }
  }
  .content {
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    em {
      font-style: normal;
      margin: 0 10px;
    }
    ${theme.breakpoints.down('md')} {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 8px;
    }
  }
  .info {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #cecece;
    margin-bottom: 80px;
    ${theme.breakpoints.down('md')} {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;
    }
  }
  .select {
    width: 140px;
    color: #ffffff;
    margin-right: 10px;
    border-radius: 4px;
    ${theme.breakpoints.down('md')} {
      width: 47%;
      margin-right: 0px;
      margin-bottom: 25px;
    }
    &:hover {
      /* border: 1px solid #676767; */
      fieldset {
        border-color: #676767;
      }
    }
    .MuiSelect-select {
      padding: 6.5px 10px;
    }
    fieldset {
      border-color: #676767;
    }
    .MuiSelect-icon {
      width: 18px;
      height: 18px;
      top: calc(50% - 9px);
      color: #ffffff;
    }
  }
`
const Check = styled(Checkbox)`
  color: #fff;
  svg {
    width: 19.5px;
    height: 19.5px;
  }
`
const PriceMeun = styled(Box)`
  padding: 10px;
  .money {
    display: flex;
    align-items: center;
    margin: 10px 0 4px 0;
    .item {
      margin-right: 10px;
      padding: 2px 10px;
      gap: 10px;
      height: 32px;
      background: #252525;
      border-radius: 2px;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 16px;
    line-height: 32px;
    color: #ffffff;
    .reset {
      padding: 0 30px;
      gap: 10px;
      width: 110px;
      height: 32px;
      background: #252525;
      border-radius: 2px;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;
    }
    .ok {
      text-align: center;
      padding: 0 30px;
      gap: 10px;
      width: 110px;
      height: 32px;
      background: #3a63f2;
      border-radius: 2px;
      cursor: pointer;
    }
  }
`
const PriceInput = styled(OutlinedInput)`
  margin-top: 10px;
  .MuiInputBase-input {
    padding: 0 10px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    width: 80px;
  }
  fieldset {
    border-color: #a4a4a4;
  }
`
const InputBox = styled(Box)`
  border: 1px solid #676767;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  padding: 6.5px 16px;
  width: 250px;
  align-items: center;
  justify-content: center;
  ${theme.breakpoints.down('md')} {
    width: 85%;
    height: 40px;
  }
  img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
  input {
    width: 100%;
    height: 19px;
    margin-right: 10px;
    outline: none;
    color: #fff;
    background: transparent;
    border: 0;
    font-weight: 500;
    font-size: 14px;
  }
  &:hover {
    border-color: #fff;
  }
`
const BoxEmpty = styled(Box)`
  position: relative;
  width: 100%;
  height: 500px;
`
const GameMenuItem = styled(MenuItem)`
  border-radius: 4px;
  margin: 0 10px 10px;

  &.Mui-selected {
    background-color: #2a64fb;
  }
  &:hover {
    background-color: #2a64fb;
  }
`
const SearchGame = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  .search {
    border: 1px solid #676767;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    background: url(${SearchIcon}) center/13px 13px no-repeat;
  }
`
interface Price {
  maxPrice: string
  minPrice: string
}
interface Props {
  window?: () => Window
  children: React.ReactElement
}

function ScrollTop(props: Props) {
  const isM = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#root')

    if (anchor) {
      anchor.scrollIntoView({
        block: 'start',
      })
    }
  }

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: isM ? 50 : 150, right: isM ? 16 : 40 }}
      >
        {children}
      </Box>
    </Fade>
  )
}

export default function Detail() {
  const { id = 1 } = useParams()
  const { t } = useTranslation()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [active, setActive] = useState<boolean>(isMobile ? false : true)
  const [noSearch, setNoSearch] = useState<boolean>(false)

  const { detail } = useGamesDetail({ id: Number(id) })
  const [typeName, setTypeName] = useState<string[]>([])
  const [newest, setNewest] = useState('')
  const [priceToken, setPriceToken] = useState('')
  const [minPrice, setMinPrice] = useState<number>(0)
  const [maxPrice, setMaxPrice] = useState<number>(0)
  const [category, setCategory] = useState<string[]>([])
  const [nftName, setNftName] = useState('')
  const [sort, setSort] = useState(2)
  const [sortName, setSortName] = useState(1)
  const [open, setOpen] = useState(false)
  const [price, setPrice] = useState<Price>({ maxPrice: '', minPrice: '' })
  const [more, setMore] = useState<boolean>(true)
  const [page, setPage] = useState(1)
  const status = [
    { id: 1, name: t('Mystery Boxes') },
    { id: 2, name: t('NFTs') },
  ]

  const { list, loading } = useGameNftList({
    gameId: Number(id),
    page,
    pageSize: 16,
    nftName,
    sort,
    sortName,
    category: typeName.join(','),
    nftType: category.length === 2 ? 0 : category.indexOf(t('Mystery Boxes')) > -1 ? 1 : 2,
    priceToken,
    minPrice,
    maxPrice,
  })
  const handleChange = (event: SelectChangeEvent<typeof typeName>) => {
    const {
      target: { value },
    } = event
    setTypeName(typeof value === 'string' ? value.split(',') : value)
    setPage(1)
    setNoSearch(true)
    setMore(true)
  }
  const handleChangeStatus = (event: SelectChangeEvent<typeof category>) => {
    const {
      target: { value },
    } = event
    setCategory(typeof value === 'string' ? value.split(',') : value)
    setPage(1)
    if (value.length > 0) {
      setNoSearch(true)
    }
    //  else {
    //   setNoSearch(false)
    // }
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const clear = () => {
    setPage(1)
    setNewest('')
    setTypeName([])
    setCategory([])
    setPriceToken('')
    setMaxPrice(0)
    setMinPrice(0)
    setPrice({ minPrice: '', maxPrice: '' })
    setSort(2)
    setSortName(1)
    setNoSearch(false)
  }
  const fetchItems = () => {
    if (list && list?.totalPage > page && !loading) {
      setPage((page) => {
        return page + 1
      })
    }
    if (list && list?.totalPage === page) {
      setMore(false)
    }
  }

  return detail ? (
    <BoxDetail>
      <Box className="banner">
        <img className="bannerimagge" src={detail?.banner} alt={detail?.name} />
        <Box className="tw">
          {detail?.platforms.map((item) => {
            return (
              <a href={item.url} key={item.name}>
                <img src={item.icon} alt="" />
              </a>
            )
          })}
        </Box>
        <img className="icon" src={detail?.icon} alt="12" />
      </Box>
      <Container maxWidth="lg">
        <Typography className="content">
          {detail?.name}
          {/* <em>|</em>
          <Typography sx={{ fontWeight: 800, fontSize: '28px', marginRight: '4px' }}> {detail?.nftTotal}</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 800 }}> {t('Items')}</Typography> */}
        </Typography>
        <Typography className="info">{detail?.info}</Typography>
        {isMobile && (
          <SearchGame>
            <InputBox>
              <input
                value={nftName}
                onChange={(e) => {
                  setNftName(e.target.value)
                  setPage(1)
                }}
                placeholder={t('Search items...')}
                type="search"
              />
              <img src={SearchIconPic} alt="" />
            </InputBox>
            <Box
              className="search"
              onClick={() => {
                setActive((act) => {
                  return !act
                })
              }}
            ></Box>
          </SearchGame>
        )}
        <Box sx={{ display: 'flex' }}>
          {active && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: isMobile ? 'space-between' : 'revert',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="select"
                placeholder="sadsadsadsa"
                value={newest}
                IconComponent={() => (
                  <Box sx={{ position: 'absolute', right: '7px', top: 'calc(50% - 9px)' }}>
                    <img src={Sorting} alt="" />
                  </Box>
                )}
                onChange={(event) => {
                  setNewest(event.target.value)
                  if (t(event.target.value) === t('Newest')) {
                    setSort(2)
                    setSortName(1)
                  } else if (t(event.target.value) === t('Oldest')) {
                    setSort(1)
                    setSortName(1)
                  } else if (t(event.target.value) === t('Lowest Price')) {
                    setSort(1)
                    setSortName(2)
                  } else if (t(event.target.value) === t('Highest Price')) {
                    setSort(2)
                    setSortName(2)
                  }
                  setPage(1)
                  setNoSearch(true)
                }}
                displayEmpty={true}
                renderValue={(selected) => {
                  if (selected === '') {
                    return t('Newest')
                  }
                  return t(selected)
                }}
              >
                <GameMenuItem value="Newest">{t('Newest')}</GameMenuItem>
                <GameMenuItem value="Oldest">{t('Oldest')}</GameMenuItem>
                <GameMenuItem value="Lowest Price">{t('Lowest Price')}</GameMenuItem>
                <GameMenuItem value="Highest Price">{t('Highest Price')}</GameMenuItem>
              </Select>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                className="select"
                multiple
                value={typeName}
                onChange={handleChange}
                MenuProps={MenuProps}
                displayEmpty={true}
                IconComponent={ExpandLess}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return t('Type')
                  }
                  return selected.join(', ')
                }}
              >
                {detail?.categoryList.length > 0 ? (
                  detail?.categoryList.map((name: any) => (
                    <MenuItem key={name} value={name}>
                      <ListItemText primary={name} />
                      <Check checked={typeName.indexOf(name) > -1} />
                    </MenuItem>
                  ))
                ) : (
                  <Box sx={{ height: '200px' }}>
                    <Empty />
                  </Box>
                )}
              </Select>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="select"
                open={open}
                value={''}
                onOpen={handleOpen}
                onClose={handleClose}
                IconComponent={ExpandLess}
                displayEmpty={true}
                renderValue={(selected) => {
                  if (selected === '') {
                    return t('Price')
                  }
                  return selected
                }}
              >
                <PriceMeun>
                  <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '19px' }}>{t('Coin')}</Typography>
                  <Box className="money">
                    {detail?.paymentTokens.map((item: any) => {
                      return (
                        <Box
                          className="item"
                          style={{ background: item.id === priceToken ? '#3A63F2' : '' }}
                          onClick={() => {
                            setPriceToken(item.id)
                            setNoSearch(true)
                          }}
                          key={item.id}
                        >
                          {item.token.toLocaleUpperCase()}
                        </Box>
                      )
                    })}
                  </Box>
                  <Typography sx={{ fontWeight: 500, fontSize: '14px', lineHeight: '19px' }}>{t('Price')}</Typography>
                  <Box className="price">
                    <PriceInput
                      className="maxPrice"
                      placeholder="Min."
                      value={price?.minPrice}
                      onChange={(event) => {
                        setPrice((res) => {
                          if (res) {
                            return { ...res, minPrice: event.target.value }
                          }
                          return { maxPrice: '', minPrice: event.target.value }
                        })
                      }}
                    />
                    <Divider variant="middle" sx={{ width: '10px', margin: '0 10px', borderColor: '#A4A4A4' }} />
                    <PriceInput
                      placeholder="Max."
                      value={price?.maxPrice}
                      onChange={(event) => {
                        setPrice((res) => {
                          if (res) {
                            return { ...res, maxPrice: event.target.value }
                          }
                          return { minPrice: '', maxPrice: event.target.value }
                        })
                      }}
                    />
                  </Box>
                  <Box className="btn">
                    <Box
                      className="reset"
                      onClick={() => {
                        setPrice({ minPrice: '', maxPrice: '' })
                      }}
                    >
                      {t('Reset')}
                    </Box>
                    <Box
                      className="ok"
                      onClick={() => {
                        // setPriceToken(price?.priceToken)
                        setMinPrice(Number(price?.minPrice))
                        setMaxPrice(Number(price?.maxPrice))
                        setPage(1)
                        setOpen(false)
                        if (!price?.minPrice && !price?.maxPrice && !priceToken) {
                          setNoSearch(false)
                        } else {
                          setNoSearch(true)
                        }
                      }}
                    >
                      {t('OK')}
                    </Box>
                  </Box>
                </PriceMeun>
              </Select>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                className="select"
                multiple
                IconComponent={ExpandLess}
                value={category}
                onChange={handleChangeStatus}
                MenuProps={MenuProps}
                displayEmpty={true}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return t('Status')
                  }
                  return selected.join(', ')
                }}
              >
                {status.map((item) => (
                  <MenuItem key={item.name} value={item.name}>
                    <ListItemText primary={item.name} />
                    <Check checked={category.indexOf(item.name) > -1} />
                  </MenuItem>
                ))}
              </Select>
              {!isMobile && noSearch && (
                <Button
                  variant="contained"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '4px',
                    height: '33px',
                  }}
                  onClick={clear}
                >
                  <Box sx={{ marginRight: '4px' }}>
                    <img src={Edit} alt="" />
                  </Box>
                  {t('Clear All')}
                </Button>
              )}
            </Box>
          )}
          {!isMobile && (
            <Box display={'flex'} flex="1" justifyContent={'flex-end'}>
              <InputBox>
                <input
                  value={nftName}
                  onChange={(e) => {
                    setNftName(e.target.value)
                    setPage(1)
                  }}
                  placeholder={t('Search items...')}
                  type="search"
                />
                <img src={SearchIconPic} alt="" />
              </InputBox>
            </Box>
          )}
        </Box>
        <Box>
          <InfiniteScroll
            loadMore={fetchItems}
            pageStart={0}
            useWindow={true}
            initialLoad={false}
            hasMore={more && !loading}
          >
            <Box sx={{ marginTop: isMobile ? 0 : '30px' }}>
              <Grid container spacing={isMobile ? 2 : 3.75} columns={{ md: 12, xs: 6 }}>
                {list &&
                  list?.record.map((i, k) => {
                    return (
                      <Grid key={`nft-market-${k}`} item xs={3}>
                        <Card data={i} />
                      </Grid>
                    )
                  })}
              </Grid>
              {loading && <Loading />}
              {!loading && list?.totalPage === 0 && (
                <BoxEmpty>
                  <Empty />
                </BoxEmpty>
              )}
              {list && list?.totalPage === page && !loading && (
                <More>
                  <Box>
                    <Typography>{t('No More…')}</Typography>
                  </Box>
                </More>
              )}
            </Box>
          </InfiniteScroll>
        </Box>
      </Container>
      <ScrollTop>
        <img src={Back} alt="" width={isMobile ? 30 : 60} height={isMobile ? 30 : 60} />
      </ScrollTop>
    </BoxDetail>
  ) : (
    <Box minHeight={'calc(100vh - 305px)'} textAlign="center" pt="100px">
      <Spinner />
    </Box>
  )
}
