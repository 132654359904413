import { Typography, Container, Box, styled, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import GamesCard from 'components/games'
import { useState } from 'react'
import Loading from 'pages/Nft/components/Loading'
import Empty from '../Record/components/Empty'
import theme from 'theme'
import cookie from 'react-cookies'

const BoxEmpty = styled(Box)`
  position: relative;
  width: 100%;
  height: 500px;
`
const More = styled(Box)`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    margin-top: 50px;
    ${theme.breakpoints.down('md')} {
      margin-bottom: 70px;
    }
  }
`

export default function Games() {
  const { t } = useTranslation()
  const [list, setList] = useState<GameList>()
  const [loading, setLoading] = useState<boolean>(false)
  const [more, setMore] = useState<boolean>(true)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const [page, setPage] = useState(1)
  const fetchItems = async () => {
    if (loading) {
      return
    }
    try {
      setLoading(true)
      await fetch(`${process.env.REACT_APP_INO_API_URL}/game/list?page=${page}&pageSize=${isMobile ? 10 : 9}`, {
        headers: {
          'Accept-Language': cookie.load('i18next'),
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (page === result.data?.totalPage) {
            setMore(false)
          } else {
            setPage((page) => {
              return page + 1
            })
          }
          setList((res) => {
            if (res?.current) {
              res.record = [...res.record, ...result.data.record]
              return res
            }
            return result.data
          })
        })
    } finally {
      setLoading(() => {
        return false
      })
    }
  }

  return (
    <Container maxWidth="lg">
      <Typography
        sx={{
          fontWeight: 900,
          fontSize: isMobile ? '24px' : '32px',
          lineHeight: isMobile ? '33px' : '44px',
          textAlign: 'center',
          marginBottom: isMobile ? '16px' : '40px',
        }}
      >
        {t('Games')}
      </Typography>
      <Box>
        <InfiniteScroll
          loadMore={fetchItems}
          pageStart={0}
          useWindow={true}
          initialLoad={true}
          hasMore={more && !loading}
        >
          {list && <GamesCard list={list} source="home" />}
          {loading && <Loading height={page === 1 ? '500px' : '30px'} />}
          {list?.totalPage === 0 && (
            <BoxEmpty>
              <Empty />
            </BoxEmpty>
          )}
          {list && list?.totalPage <= page && !loading && (
            <More>
              <Box>
                <Typography>{t('No More…')}</Typography>
              </Box>
            </More>
          )}
        </InfiniteScroll>
      </Box>
    </Container>
  )
}
