import { useEffect, useState, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import RevokeNft from 'components/Modal/RevokeNft'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'

import Buttons from './Buttons'
import OrderTable from './OrderTable'
import MobileList from './OrderMobileList'
import Message from 'components/Message'
import { api } from '../api'
import Loading from './Loading'
import Empty from './Empty'

// const TitleStyle = styled(Box)`
//   font-size: 1.5rem;
//   font-weight: bold;
//   margin-bottom: 30px;

//   ${theme.breakpoints.down('md')} {
//     margin-bottom: 15px;
//   }
// `
interface Props {
  isM: boolean
  account: string | undefined | null
}
let page = 0
const pageSize = 20 // 请求每次请求条数
const sellPageSize = 10
const defaultData = {
  current: 0,
  totalPage: 1,
  record: [],
}

export default function Record({ isM, account }: Props) {
  const { t } = useTranslation()
  // const typeList = [t('purchase'), t('sale')]
  const typeList = [
    { lable: 'purchase', name: t('purchase') },
    { lable: 'sale', name: t('sale') },
  ]

  const [searchParams, setSearchParams] = useSearchParams()
  const queryType = searchParams.get('type') || ''
  const [type, setType] = useState('purchase')
  const [buyData, setBuyData] = useState(defaultData)
  const [buyLoading, setBuyLoading] = useState(false)
  const [buyMore, setBuyMore] = useState(false)
  const [sellData, setSellData] = useState(defaultData)
  const [sellLoading, setSellLoading] = useState(false)
  const [sellMore, setSellMore] = useState(false)
  const [message, setMessage] = useState<{ show: boolean; text: string }>({ show: false, text: '' })
  const hasChange = useRef('purchase')

  const [revoke, setRevoke] = useState<{
    id: number
    nftNme: string
    nftType: 1 | 2
    imageUrl: string
  }>({ id: 0, nftNme: '', nftType: 1, imageUrl: '' })
  const [showRevoke, setShowRevoke] = useState(false)

  // 初始化数据
  const initData = () => {
    setBuyData(defaultData)
    setSellData(defaultData)
    setBuyMore(false)
    setSellMore(false)
  }
  const handleChangeType = async (value: string) => {
    if (value === type) return
    // if (!typeList.includes(value)) value = 'purchase'
    initData()
    setType(value)
    setSearchParams({ type: value })
    page = 0
  }
  // 获取买列表
  const getBuyList = async () => {
    if (!account) return
    page = page + 1
    setBuyLoading(true)
    hasChange.current = type

    const params: Params = {
      userAddress: account,
      pageSize,
      page,
    }

    const res: any = await api.getBuyList(params)
    if (hasChange.current === type) setBuyLoading(false)
    if (res.success) {
      if (res.data.record && hasChange.current === type) {
        setBuyMore(res.data.totalPage <= page)
        if (page === 1) {
          setBuyData(res.data)
        } else {
          setBuyData((re) => {
            res.record = [...re.record, ...res.data.record]
            return res
          })
        }
      }
    } else {
      setMessage({ show: true, text: res.message })
    }
  }
  // 获取卖列表
  const getSellList = () => {
    if (!account) return
    page = page + 1
    hasChange.current = type

    const params: Params = {
      userAddress: account,
      pageSize: sellPageSize,
      page,
    }

    api.getSellList(params).then((res: any) => {
      setSellLoading(false)
      if (res.success) {
        if (res.data.record && hasChange.current === type) {
          setSellMore(res.data.totalPage <= page)
          if (page === 1) {
            setSellData(res.data)
          } else {
            setSellData((re) => {
              res.record = [...re.record, ...res.data.record]
              return res
            })
          }
        }
      } else {
        setMessage({ show: true, text: res.message })
      }
    })
  }

  // 取消订单
  const handleCancel = (detail: any) => {
    const copy = JSON.parse(JSON.stringify(detail))
    copy.id = copy.nftId
    setRevoke(copy)
    setShowRevoke(true)
  }
  useEffect(() => {
    page = 0
  }, [])
  useEffect(() => {
    if (queryType) {
      handleChangeType(queryType)
    }
  }, [type]) // eslint-disable-line

  useEffect(() => {
    if (account) {
      if (type === 'purchase') {
        getBuyList()
      } else {
        getSellList()
      }
    } else {
    }

    initData()
  }, [type, account]) // eslint-disable-line

  return (
    <Box>
      <Message
        show={message.show}
        message={message.text}
        onClose={() => {
          setMessage({ show: false, text: '' })
        }}
      />

      <Buttons
        list={typeList}
        width="100px"
        type={type}
        click={(value) => {
          handleChangeType(value)
        }}
      />
      <Box>
        <InfiniteScroll
          loadMore={(page) => {
            if (type === 'purchase') {
              getBuyList()
            } else {
              getSellList()
            }
          }}
          pageStart={0}
          useWindow={true}
          initialLoad={false}
          hasMore={type === 'purchase' ? !buyMore || !setBuyLoading : !sellMore || !setSellLoading}
        >
          {isM ? (
            <Box
              marginTop="25px"
              minHeight={buyData.record.length || sellData.record.length ? '360px' : '360px'}
              position="relative"
            >
              {type === 'purchase' ? (
                <>
                  <MobileList
                    list={buyData.record}
                    type="purchase"
                    loading={buyLoading}
                    current={buyData.current}
                    hasMore={buyMore}
                  />
                </>
              ) : (
                <>
                  {!sellLoading && !sellData.record.length && <Empty />}
                  {sellLoading && <Loading />}
                  <>
                    <MobileList
                      list={sellData.record}
                      type="sale"
                      loading={sellLoading}
                      current={sellData.current}
                      hasMore={sellMore}
                      onCancel={handleCancel}
                    />
                  </>
                  {/* {!!sellData.record.length && (
                    <>
                      <MobileList
                        list={sellData.record}
                        type="sale"
                        loading={sellLoading}
                        current={sellData.current}
                        hasMore={sellMore}
                        onCancel={handleCancel}
                      />
                    </>
                  )} */}
                </>
              )}
            </Box>
          ) : (
            <Box
              mt="50px"
              minHeight={buyData.record.length || sellData.record.length ? '' : '360px'}
              position="relative"
            >
              {type === 'purchase' ? (
                <>
                  <OrderTable list={buyData.record} type="purchase" loading={buyLoading} />
                </>
              ) : (
                <>
                  {!sellLoading && !sellData.record.length && <Empty />}
                  {sellLoading && <Loading />}
                  {!!sellData.record.length && (
                    <>
                      <OrderTable list={sellData.record} type="sale" loading={sellLoading} onCancel={handleCancel} />
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          {(buyData.record.length > 0 || sellData.record.length > 0) && (sellMore || buyMore) && (
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '16px',
                lineHeight: '22px',
                textAlign: 'center',
              }}
            >
              {t('No More…')}
            </Typography>
          )}
        </InfiniteScroll>
      </Box>

      <RevokeNft
        data={revoke}
        open={showRevoke}
        onSuccess={() => {
          setShowRevoke(false)
          window.location.reload()
        }}
        onClose={() => {
          setShowRevoke(false)
        }}
      />
    </Box>
  )
}
