import { Contract, Provider } from 'ethcall'
import { Web3Provider } from '@ethersproject/providers'
// InfuraProvider, JsonRpcProvider
import { getNetworkLibrary } from 'connectors'
import Web3 from 'web3'

// const infuraKey = process.env.REACT_APP_INFURA_KEY
// const provider = new InfuraProvider('mainnet', infuraKey)
export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (exemple: balanceOf)
  symbol?: string // Function name on the contract (exemple: balanceOf)
  params?: string[] // Function params
}

const provider = getNetworkLibrary()

export const igoCall = async (abi: any[], address: string, account?: string) => {
  try {
    const result = []
    // const _contarct = getContract(address, abi, library)
    // if (!window.ethereum) return null
    const web3 = new Web3(window.ethereum as any)
    const _contarct = new web3.eth.Contract(abi, address)
    console.log(_contarct)
    result[0] = await _contarct.methods.baseInfo().call()
    result[1] = await _contarct.methods.totalPurchasedSourceCurrency().call()
    if (account) {
      result[2] = await _contarct.methods.getPurchasedAmount(account).call()
    }
    return result
  } catch (e) {
    console.error(e)
    console.log('合约执行出错', e.message)
    return null
  }
}

const multiCall = async (abi: any[], calls: Call[], library?: Web3Provider) => {
  const ethCallProvider = new Provider()
  await ethCallProvider.init(library ? library : provider)
  try {
    return await ethCallProvider.all(
      calls.map((el) => {
        if (el.params) {
          return new Contract(el.address, abi)[el.name](...el.params)
        } else {
          const res = new Contract(el.address, abi)
          return res[el.name]
        }
      })
    )
  } catch (e) {
    console.error(e)
    console.log('合约执行出错', e.message)
    return null
  }
}
export default multiCall
