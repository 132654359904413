import { Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
// import { MaxUint256 } from '@ethersproject/constants'

import IGO_ABI from 'abis/igo.json'
import { AccountType } from './type'

import { getContract } from 'utils'

export async function baseInfo(provider: any, contractAddress: string) {
  const contract = getContract(contractAddress, IGO_ABI, provider)
  console.log(contract)
  return contract.baseInfo()
}

export async function totalPurchasedSourceCurrency(provider: any, contractAddress: string) {
  const contract = getContract(contractAddress, IGO_ABI, provider)
  return contract.totalPurchasedSourceCurrency()
}

export async function isInWhiteList(provider: Web3Provider, contractAddress: string, account: string) {
  const contract = getContract(contractAddress, IGO_ABI, provider)
  return contract.isInWhiteList(account)
}

export async function getPurchasedAmount(provider: any, contractAddress: string, account: string) {
  const contract = getContract(contractAddress, IGO_ABI, provider)
  return contract.getPurchasedAmount(account)
}

export async function estimateClaim(
  provider: Web3Provider,
  contractAddress: string,
  amount: BigNumber,
  account: AccountType
) {
  const contract = getContract(contractAddress, IGO_ABI, provider)
  return contract.estimateClaim(account, amount)
}

export async function canClaim(provider: Web3Provider, contractAddress: string, account: string) {
  const contract = getContract(contractAddress, IGO_ABI, provider)
  return contract.canClaim(account)
}

export async function claim(provider: Web3Provider, contractAddress: string, account: string) {
  const contract = getContract(contractAddress, IGO_ABI, provider, account)
  return contract.claim()
}

export async function subscribe(
  provider: Web3Provider,
  contractAddress: string | null | undefined,
  amount: BigNumber,
  account: AccountType
) {
  const contract = account && contractAddress && getContract(contractAddress, IGO_ABI, provider, account)
  return contract && contract.subscribe(amount)
}
