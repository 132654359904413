import { Typography, Box, Container, styled, Button, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MintCountdown from './components/MintCountdown'
import { useFreeMint } from 'hooks/useGames'
import Spinner from 'components/Spinner'
import theme from 'theme'
import { formatUTCTime } from 'utils/date'
import { useWeb3React } from '@web3-react/core'
import { mintApprove } from 'constants/mint'
import LoadingIcon from 'components/LoadingIcon'
import { useState } from 'react'
import MintModal from './components/MintModal'
import { useWalletModalToggle } from 'state/application/hooks'
import { useLang } from 'state/language/hooks'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const Mint = styled(Box)`
  background: #191919;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
  ${theme.breakpoints.down('md')} {
    flex-wrap: wrap;
    padding: 16px 10px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 40px;
    }
  }
  .img1 {
    border-radius: 20px;
    width: 200px;
    height: 200px;
    ${theme.breakpoints.down('md')} {
      width: 100px;
      height: 100px;
      border-radius: 8px;
    }
  }
  .img2 {
    position: absolute;
    top: 10px;
    left: 140px;
    width: 50px;
    height: 20px;
    ${theme.breakpoints.down('md')} {
      width: 32px;
      height: 16px;
      top: 24px;
      left: 70px;
    }
  }
  .center {
    width: 600px;
    /* margin: 26px 20px; */
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.down('md')} {
      flex: 1;
      margin-left: 15px;
    }
    .title {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 10px;
      ${theme.breakpoints.down('md')} {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
      }
    }
    .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #c0c0c0;
      /* margin-bottom: 40px; */
      min-height: 66px;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      ${theme.breakpoints.down('md')} {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        -webkit-line-clamp: 4;
        min-height: 64px;
      }
    }
    .number {
      em {
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        color: #ffffff;
        font-style: normal;
        margin-right: 4px;
      }
      .mr {
        margin-right: 20px;
      }
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #c0c0c0;
    }
    .tokenPrice {
      display: flex;
      align-items: center;
      font-weight: 900;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 1px;
      margin: 10px 0 13px 0;
      em {
        font-weight: 900;
        font-size: 20px;
        line-height: 27px;
        font-style: normal;
        margin-right: 4px;
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }
  .pNumber {
    margin-top: 10px;
    ${theme.breakpoints.down('md')} {
      min-width: 100vw;
    }
    .number {
      em {
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        color: #ffffff;
        font-style: normal;
        margin-right: 4px;
      }
      .mr {
        margin-right: 20px;
      }
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #c0c0c0;
    }
  }
  .mint {
    padding: 26px 20px 26px 20px;
    height: 200px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    ${theme.breakpoints.down('md')} {
      height: auto;
      padding: 0;
      padding-top: 16px;
      min-width: 220px;
      align-items: flex-start;
      width: 100%;
    }
    .time {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #c0c0c0;
      margin-bottom: 16px;
    }
    .end {
      width: 220px;
      height: 44px;
      background: #363636;
      font-weight: 800;
      font-size: 24px;
      line-height: 44px;
      color: #c0c0c0;
      text-align: center;
      border-radius: 10px;
      ${theme.breakpoints.down('md')} {
        width: 100%;
      }
    }
    .upcoming {
      width: 220px;
      height: 44px;
      background: rgba(42, 100, 251, 0.2);
      border-radius: 10px;
      font-weight: 800;
      font-size: 24px;
      line-height: 44px;
      text-align: center;
      margin-top: 16px;
      ${theme.breakpoints.down('md')} {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tokenPrice {
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-weight: 800;
          font-size: 16px;
          line-height: 22px;
          em {
            margin-right: 4px;
            font-style: normal;
          }
        }
        img {
          width: 18px;
          height: 19px;
          margin-right: 8px;
        }
      }
    }
    .Participated {
      background: #363636;
    }
  }
`
interface ModalAttr {
  state: number
  desc: string
  open: boolean
}
export default function FreeMint() {
  const { t } = useTranslation()
  const { list, loading } = useFreeMint()
  const toggleWalletModal = useWalletModalToggle()
  const lang = useLang()

  const [loadingMint, setLoadingMint] = useState<Boolean>(false)
  const [modalAttr, setModalAttr] = useState<ModalAttr>({ state: 1, desc: t('Mint Success！'), open: false })
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const { endlist } = useFreeMint(1, 10, 1)
  const { account, library } = useWeb3React()
  const approves = async (item: FreeMint) => {
    try {
      if (loadingMint) return
      setLoadingMint(true)
      const info = await mintApprove(item, library, account)

      if (info) {
        if (info === 1) {
          setModalAttr({
            state: 2,
            desc: t('Sorry, your wallet address is not on the whitelist.'),
            open: true,
          })
        } else {
          // window.location.reload()
          setModalAttr({ state: 1, desc: t('Mint Success！'), open: true })
        }
      }
      setLoadingMint(false)
    } catch (error) {
      setLoadingMint(false)
      console.log(error)
    }
  }
  const clonse = () => {
    setModalAttr((res) => {
      return { ...res, open: false }
    })
    if (modalAttr.state === 1) {
      window.location.href = `/${lang}/record`
    }
  }
  const onComplete = () => {
    window.location.reload()
  }
  return !loading ? (
    <Container maxWidth="lg" sx={{ minHeight: 'calc(100vh - 300px)' }}>
      {list?.record && list?.record.length > 0 && (
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: '32px',
            lineHeight: '44px',
            textAlign: 'center',
            marginBottom: isMobile ? '24px' : '40px',
          }}
        >
          {t('Mints')}
        </Typography>
      )}

      {list?.record.map((item) => {
        return !isMobile ? (
          <Mint key={item.id}>
            <img className="img1" src={item.img} alt="" />
            {item.badge && <img className="img2" src={item.badge} alt="" />}
            <Box className="center">
              <Typography className="title">{item.name}</Typography>
              <Typography sx={{ marginBottom: item.price > 0 ? '0' : '40px' }} className="desc">
                {item.desc}
              </Typography>
              {item.price > 0 && (
                <Box className="tokenPrice">
                  <img src={item.priceToken.icon} alt="" />
                  <Typography>
                    <em>{item.price}</em>
                    {item.priceToken.token.toLocaleUpperCase()}
                  </Typography>
                </Box>
              )}
              <Box className="number">
                <em>{item.amount}</em>
                {t('Amount of casting')}
                <em className="mr" />
                <em>{item.remainCount}</em>
                {t('Amount of remaining')}
              </Box>
            </Box>
            <Box className="mint">
              {dayjs(Date.now()).add(1, 'month').valueOf() < item.endTime && Date.now() > item.startTime ? (
                <Box sx={{ fontWeight: 800, fontSize: '24px', lineHeight: '33px' }}>{t('In Progress')}</Box>
              ) : (
                <MintCountdown
                  data={{ id: item.id, startTime: item.startTime, endTime: item.endTime, state: 0 }}
                  onComplete={onComplete}
                  page="index"
                />
              )}
              {!account && (
                <Button
                  variant="contained"
                  sx={{ width: '220px' }}
                  onClick={() => {
                    toggleWalletModal()
                  }}
                >
                  {loadingMint && <LoadingIcon />}
                  {t('CONNECT WALLET')}
                </Button>
              )}
              {Date.now() > item.startTime &&
                Date.now() < item.endTime &&
                !item.mint &&
                item.remainCount &&
                item.remainCount > 0 &&
                account && (
                  <Button
                    variant="contained"
                    sx={{ width: '220px' }}
                    disabled={item.mint}
                    onClick={() => {
                      approves(item)
                    }}
                  >
                    {loadingMint && <LoadingIcon />}
                    {t('Mint')}
                  </Button>
                )}
              {item.mint && <Box className="upcoming Participated">{t('Participated')}</Box>}
              {item.remainCount === 0 && !item.mint && <Box className="upcoming Participated">{t('Sold Out')}</Box>}
              {Date.now() < item.startTime && <Box className="upcoming">{t('Upcoming')}</Box>}
            </Box>
          </Mint>
        ) : (
          <Mint key={item.id}>
            <img className="img1" src={item.img} alt="" />
            {item.badge && <img className="img2" src={item.badge} alt="" />}
            <Box className="center">
              <Typography className="title">{item.name}</Typography>
              <Typography className="desc">{item.desc}</Typography>
            </Box>
            <Box className="pNumber">
              <Typography className="number">
                <em>{item.amount}</em>
                {t('Amount of casting')}
                <em className="mr" />
                <em>{item.remainCount}</em>
                {t('Amount of remaining')}
              </Typography>
            </Box>
            <Box className="mint">
              {dayjs(Date.now()).add(1, 'month').valueOf() < item.endTime && Date.now() > item.startTime ? (
                <Box sx={{ fontWeight: 800, fontSize: '24px', lineHeight: '33px', marginBottom: '20px' }}>
                  {t('In Progress')}
                </Box>
              ) : (
                <Box sx={{ marginBottom: '20px' }}>
                  <Typography sx={{ fontWeight: 500, color: '#C0C0C0', fontSize: '14px', lineHeight: '19px' }}>
                    {Date.now() < item.startTime ? t('Coming Soon') : t('Time Remaining')}
                  </Typography>
                  <MintCountdown
                    data={{ id: item.id, startTime: item.startTime, endTime: item.endTime, state: 0 }}
                    page="index"
                    onComplete={onComplete}
                  />
                </Box>
              )}
              {!account && (
                <Button
                  variant="contained"
                  sx={{ width: '100%' }}
                  onClick={() => {
                    toggleWalletModal()
                  }}
                >
                  {loadingMint && <LoadingIcon />}
                  {t('CONNECT WALLET')}
                </Button>
              )}
              {Date.now() > item.startTime &&
                Date.now() < item.endTime &&
                !item.mint &&
                item.remainCount &&
                item.remainCount > 0 &&
                account && (
                  <Button
                    variant="contained"
                    sx={{ width: '100%' }}
                    disabled={item.mint}
                    onClick={() => {
                      approves(item)
                    }}
                  >
                    {loadingMint && <LoadingIcon />}
                    {t('Mint')}
                  </Button>
                )}
              {item.mint && <Box className="upcoming Participated">{t('Participated')}</Box>}
              {item.remainCount === 0 && !item.mint && <Box className="upcoming Participated">{t('Sold Out')}</Box>}
              {Date.now() < item.startTime && (
                <Box className="upcoming">
                  {item.price > 0 && (
                    <Box className="tokenPrice">
                      <img src={item.priceToken.icon} alt="" />
                      <Typography>
                        <em>{item.price}</em>
                        {item.priceToken.token.toLocaleUpperCase()}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Mint>
        )
      })}
      {endlist?.record && endlist?.record.length > 0 && (
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: '32px',
            lineHeight: '44px',
            textAlign: 'center',
            marginBottom: isMobile ? '24px' : '40px',
          }}
        >
          {t('End Mint')}
        </Typography>
      )}
      {endlist?.record.map((item) => {
        return !isMobile ? (
          <Mint key={item.id}>
            <img className="img1" src={item.img} alt="" />
            {item.badge && <img className="img2" src={item.badge} alt="" />}
            <Box className="center">
              <Typography className="title">{item.name}</Typography>
              <Typography sx={{ marginBottom: item.price ? '0' : '40px' }} className="desc">
                {item.desc}
              </Typography>
              {item.price > 0 && (
                <Box className="tokenPrice">
                  <img src={item.priceToken.icon} alt="" />
                  <Typography>
                    <em>{item.price}</em>
                    {item.priceToken.token.toLocaleUpperCase()}
                  </Typography>
                </Box>
              )}
              <Typography className="number">
                <em>{item.amount}</em>
                {t('Amount of casting')}
                <em className="mr" />
                <em>{item.remainCount}</em>
                {t('Amount of remaining')}
              </Typography>
            </Box>
            <Box className="mint">
              <Box>{formatUTCTime(item.endTime, 'MM/DD/YYYY HH:mm:ss')}</Box>
              <Box className="end">{t('End')}</Box>
            </Box>
          </Mint>
        ) : (
          <Mint key={item.id}>
            <img className="img1" src={item.img} alt="" />
            {item.badge && <img className="img2" src={item.badge} alt="" />}
            <Box className="center">
              <Typography className="title">{item.name}</Typography>
              <Typography className="desc">{item.desc}</Typography>
            </Box>
            <Box className="pNumber">
              <Typography className="number">
                <em>{item.amount}</em>
                {t('Amount of casting')}
                <em className="mr" />
                <em>{item.remainCount}</em>
                {t('Amount of remaining')}
              </Typography>
            </Box>
            <Box className="mint">
              <Box className="time">
                {formatUTCTime(item.startTime, 'YYYY-MM-DD HH:mm')}&nbsp; UTC&nbsp;-&nbsp;
                {formatUTCTime(item.endTime, 'YYYY-MM-DD HH:mm')} &nbsp;UTC
              </Box>
              <Box className="end">{t('End')}</Box>
            </Box>
          </Mint>
        )
      })}
      <MintModal {...modalAttr} clonse={clonse}></MintModal>
    </Container>
  ) : (
    <Box minHeight={'calc(100vh - 305px)'} textAlign="center" pt="100px">
      <Spinner />
    </Box>
  )
}
